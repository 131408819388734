import { setLoading } from "../../slices/authSlice";
import { setUser } from '../../slices/profileSlice';
// import toast from "react-hot-toast";
import {toast} from 'react-toastify'
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";
import { message } from "../../components/messages/Messages";
import { setCard } from "../../slices/cardSlice";

const { PROFILE_API, PROFILE_PICTURE_DELETE_API, UPDATE_PASSWORD_API,GET_USER_API ,DASHBOARD_STATS_API} = endpoints;



export function getUserDetails(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("GET", GET_USER_API, "", {'Authorization': `Bearer ${token}` });


            if (response?.status === 200) {
                dispatch(setLoading(false));
                const userImage = response.data?.user?.avatar
                    ? response.data.user.avatar
                    : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
                dispatch(setUser({ ...response.data.user, avatar: userImage }))
                dispatch(setCard(response.data.card_details))
                sessionStorage.setItem("user", JSON.stringify({...response.data.user,avatar: userImage}))
                sessionStorage.setItem("card", JSON.stringify((response?.data?.card_details)))
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                dispatch(setLoading(false))
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

export function uploadProfile(token, formData, onClose) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", PROFILE_API, formData, {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                dispatch(getUserDetails(token))
                onClose();
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 401) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 403) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
                toast.error(message.errorMessage)
            }
        }
    }
}



export function deleteProfilePicture(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("DELETE", PROFILE_PICTURE_DELETE_API, "", { 'Authorization': `Bearer ${token}` });


            if (response?.status === 200) {
                dispatch(setLoading(false))
                dispatch(getUserDetails(token))
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 403) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
                toast.error(message.errorMessage)
            }
        }
    }

}

export function updatePassword(token, payload, onClose) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", UPDATE_PASSWORD_API, payload, { 'Authorization': `Bearer ${token}` });


            if (response?.status === 200) {
                dispatch(setLoading(false));
                onClose();
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else if (error?.response?.status === 400) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
                toast.error(message.errorMessage)
            }
        }
    }

}


export function dashboardViewDownloadCount(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("GET", DASHBOARD_STATS_API, "", {'Authorization': `Bearer ${token}` } );

            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }

        } catch (error) {
            if (error?.response?.status === 422) {  
                dispatch(setLoading(false))
                toast.error(error.response.error)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

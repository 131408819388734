import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CreditContent from "./common/creditSection";
import Chart from "../recuiterDashboard/Chart/Chart";
import Loader from '../loader/Loader'
import { getUserDetails } from "../../services/operations/ProfileAPI";


const DashBoard=()=>{

    const token =useSelector((state)=>state.auth.token)
    const dispatch=useDispatch()
    const loading=useSelector((state)=>state.auth.loading);

    useEffect(()=>{
        dispatch(getUserDetails(token,1))
    },[])

    const fetchResumes=()=>{
    }

    return(
        <>
        {loading && <Loader/>}
        <CreditContent fetchResumes={fetchResumes}/>
        <Chart />
        </>
    )
}

export default DashBoard;
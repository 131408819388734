import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../../styles/globalStyle.css'
import { useForm } from "react-hook-form";
import { getUserDetails } from "../../../services/operations/ProfileAPI";
import Chart from "../Chart/Chart";
import SideBar from "../common/SideBar"
import { createPlan, getPlan} from "../../../services/operations/AdminPageAPI";

const Setting = () => {
    const { register, setValue, handleSubmit, formState: { errors }, reset } = useForm({});

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.profile.user)
    const [planId,setPlanId]=useState(null);
    const [creditId,setCreditId]=useState(null);



    const onSubmit = async(data) => {
        const { subscription, amount, tax, tax_type, demoprice, demo_duration, credits_on_uploads
            , credit_deduction_on_viewing_downloading ,daily_credits} = data;
        console.log(data);
        const payload = {
            subscription: subscription,
            amount: amount,
            tax: tax,
            tax_type: tax_type,
            demoprice: demoprice,
            demo_duration: demo_duration,
            credits_on_uploads: credits_on_uploads,
            credit_deduction_on_viewing_downloading: credit_deduction_on_viewing_downloading,
            daily_credits:daily_credits,
            credit_id:creditId,
            plan_id:planId
        }

        const response=await dispatch(createPlan(token,payload))
    }

    const getPlans=async()=>{
        const response=await dispatch(getPlan(token))
        if(response?.data?.plan){
            const result=response.data.plan[0];
            setValue('subscription', result?.subscription);
            setValue('amount',result?.amount)
            setValue('tax',result?.tax)
            setValue('tax_type',result?.tax_type)
            setValue('demoprice',result?.demoprice)
            setValue('demo_duration',result?.demo_duration)
            setPlanId(result?.id)
        }
        if(response?.data?.creditSetting){
            const result=response.data.creditSetting[0];
            setValue('credit_deduction_on_viewing_downloading', result?.credit_deduction_on_viewing_downloading);
            setValue('credits_on_uploads',result?.credits_on_uploads)
            setValue('daily_credits',result?.daily_credits)
            setCreditId(result?.id)


        }
    }

    useEffect(()=>{
        getPlans()
    },[])





    return (
        <div className="admin-dashboard-container">
            {/* Sidebar component */}
            <SideBar user={user}  />
            <div className="admin-dashboard-content">
                <div className="admin-dashboard-heading">
                    <h5 style={{ fontSize: "24px", fontWeight: "600" }}>Settings</h5>
                    <div className="integration-content">
                        <form className='integration-form' onSubmit={handleSubmit(onSubmit)}>
                            <h4>Plans</h4>
                            <div className="first-setting-section"> 
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="subscription" className="form-label">
                                        Plan Type
                                    </label>
                                    <select
                                        id="subscription"
                                        style={{'maxWidth':"300px"}}
                                        className={`form-input ${errors.subscription ? 'input-error' : ''}`}
                                        {...register('subscription')}
                                    >
                                        <option value="" disabled selected>Select Plan Type</option>
                                        <option value="monthly">Monthly</option>
                                        <option value="yearly">Yearly</option>
                                    </select>
                                    {errors.subscription && <p className="error-message">{errors.subscription.message}</p>}
                                </div>
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="amount" className="form-label">
                                        Amount($)
                                    </label>
                                    <input
                                        type="number"
                                        id="amount"
                                        style={{'maxWidth':"300px"}}
                                        placeholder="Enter amount"
                                        step="any"
                                        className={`form-input ${errors.amount ? 'input-error' : ''}`}
                                        {...register('amount')}
                                    />
                                    {errors.amount && <p className="error-message">{errors.amount.message}</p>}
                                </div>
                            </div>
                            <h4>Taxes</h4>
                            <div className="first-setting-section">
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="tax_type" className="form-label">
                                        Tax Type
                                    </label>
                                    <input
                                        type="text"
                                        style={{'maxWidth':"300px"}}
                                        id="tax_type"
                                        placeholder=''
                                        className={`form-input ${errors.taxType ? 'input-error' : ''}`}
                                        {...register('tax_type')}
                                    />
                                    {errors.tax_type && <p className="error-message">{errors.tax_type.message}</p>}
                                </div>
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="tax" className="form-label">
                                        Tax(%)
                                    </label>
                                    <input
                                        type="text"
                                        id="tax"
                                        style={{'maxWidth':"300px"}}
                                        placeholder=""
                                        className={`form-input ${errors.tax ? 'input-error' : ''}`}
                                        {...register('tax')}
                                    />
                                    {errors.tax && <p className="error-message">{errors.tax.message}</p>}
                                </div>
                            </div>
                            <h4>Demo</h4>
                            <div className="first-setting-section">
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="demoprice" className="form-label">
                                        Demo Price
                                    </label>
                                    <input
                                        type="text"
                                        style={{'maxWidth':"300px"}}
                                        id="demoprice"
                                        placeholder=''
                                        className={`form-input ${errors.demoprice ? 'input-error' : ''}`}
                                        {...register('demoprice')}
                                    />
                                    {errors.demoprice && <p className="error-message">{errors.demoprice.message}</p>}
                                </div>
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="demo_duration" className="form-label">
                                        Demo Duration
                                    </label>
                                    <input
                                        type="text"
                                        id="demo_duration"
                                        style={{'maxWidth':"300px"}}
                                        placeholder=""
                                        className={`form-input ${errors.demo_duration ? 'input-error' : ''}`}
                                        {...register('demo_duration')}
                                    />
                                    {errors.tax && <p className="error-message">{errors.demo_duration.message}</p>}
                                </div>
                            </div>
                            <h4>Credits</h4>
                            <div className="first-setting-section">
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="credit_deduction_on_viewing_downloading" className="form-label">
                                        Credit deduction on viewing,downloads
                                    </label>
                                    <input
                                        type="text"
                                        style={{'maxWidth':"300px"}}
                                        id="credit_deduction_on_viewing_downloading"
                                        placeholder=""
                                        className={`form-input ${errors.credit_deduction_on_viewing_downloading ? 'input-error' : ''}`}
                                        {...register('credit_deduction_on_viewing_downloading')}
                                    />
                                    {errors.credit_deduction_on_viewing_downloading && <p className="error-message">{errors.credit_deduction_on_viewing_downloading.message}</p>}
                                </div>
                                <div className="integration-form-group" style={{width:"300px"}}>
                                    <label htmlFor="credits_on_uploads" className="form-label">
                                        Credit on uploads/update
                                    </label>
                                    <input
                                        type="text"
                                        id="credits_on_uploads"
                                        style={{'maxWidth':"300px"}}
                                        placeholder=""
                                        className={`form-input ${errors.credits_on_uploads ? 'input-error' : ''}`}
                                        {...register('credits_on_uploads')}
                                    />
                                    {errors.credits_on_uploads && <p className="error-message">{errors.credits_on_uploads.message}</p>}
                                </div>
                                
                            </div>
                            <div className="first-setting-section">
                            <div className="integration-form-group" style={{width:"100%"}}>
                                    <label htmlFor="daily_credits" className="form-label">
                                        Credit on daily basis
                                    </label>
                                    <input
                                        type="text"
                                        id="daily_credits"
                                        style={{'width':"100%"}}
                                        placeholder=""
                                        className={`form-input ${errors.daily_credits ? 'input-error' : ''}`}
                                        {...register('daily_credits')}
                                    />
                                    {errors.daily_credits && <p className="error-message">{errors.daily_credits.message}</p>}
                                </div>
                            </div>
                            <button type="submit" className="submit-button" style={{ maxWidth: "700px", fontSize: "16px" }}>
                                Save Changes
                            </button>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Setting;

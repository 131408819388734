import React, { useState ,useEffect,useRef} from 'react';
import {NavLink, Link,useNavigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Account from '../../../assets/Profile.png'
import logoutIcon from '../../../assets/Logout.png'
import acIcon from '../../../assets/user-02.png'
import { logout } from '../../../services/operations/authAPI';
import AccountSetting from '../../recuiterDashboard/accountSetting/accountSetting';
import Loader from '../../loader/Loader';
import logoNav from '../../../assets/logoNav.png'

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const token=useSelector((state)=>state.auth.token)
    const user=useSelector((state)=>state.profile.user)
    const loading=useSelector((state)=>state.auth.loading)

    
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleOpenDropdown = (e) => {
        setShowDropdown(prev => !prev);
    };


    useEffect(() => {
        const handleClickOutside = (event) => {
         
          if (
            dropdownRef.current && 
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target) 
          ) {
            setShowDropdown(false); 
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [dropdownRef, buttonRef]);
    

    

    const handleLogout=()=>{
        dispatch(logout(token,navigate))
       
    }

    const handleOpenAccount = () => {
        setShowAccountModal(true);
        setShowDropdown(false);
      };
    
      const handleCloseAccountModal = () => {
        setShowAccountModal(false);
      };
    

    return (
        <>
            <nav className="navbar" style={{borderBottom: "1px solid #E6E6E6"}}>
                {loading && <Loader/>}
            <div className="navbar-left" style={{marginLeft:"10px"}}>
                    <h1 className="navbar-heading"><Link to='/admin-dashboard'><img src={logoNav} width={35} height={35}/></Link></h1>
                </div>
                <div className="navbar-right" style={{marginRight:"10px"}}>
                    <img 
                        src={user.avatar}
                        alt="Logo" 
                        className="navbar-logo"
                        loading='lazy'
                        onClick={handleOpenDropdown}
                        ref={buttonRef}
                    />
                    {showDropdown && (
                        <div className="modal-dropdown"  ref={dropdownRef} style={{right:"16px"}}>
                            <div className="dropdown-profile">
                                <div className="profile-left">
                                    <img src={user.avatar} alt="Profile" loading='lazy'className="profile-logo"/>
                                </div>
                                <div className="profile-right">
                                    <p className="profile-name">{user.name}</p>
                                    <p className="profile-email">{user.email}</p>
                                </div>
                            </div>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item" onClick={handleOpenAccount}>
                                    <img src={acIcon} alt="Account" className="menu-icon" loading='lazy'/>Account Settings
                                </li>
                                <li className="dropdown-item" onClick={handleLogout}>
                                    <img src={logoutIcon} alt="Logout" className="menu-icon"/>Logout
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </nav>
            {showAccountModal && <AccountSetting onClose={handleCloseAccountModal} isOpen={showAccountModal} />}    
        </>
    );
};

export default Header;

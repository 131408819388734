import { useState } from 'react'
import closeIcon from '../../../assets/Close.png'
import showIcon from '../../../assets/showIcon.png'
import hideIcon from '../../../assets/hideIcon.png'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { updatePassword } from '../../../services/operations/ProfileAPI'
import { message } from '../../messages/Messages'


const schema = yup.object().shape({
  newPassword: yup.string()
    .required(message.newPasswordRequiredMessage)
    .min(8, message.passwordMinMessage)
    .matches(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w])[A-Za-z\d\W_]{8,}$/,
      message.passwordMatchMessage
    ),
  confirmPassword: yup.string()
    .oneOf([yup.ref('newPassword'), null], message.matchMessage)
    .required(message.confirmPasswordRequiredMessage),
});

const ChangePassword = ({ isOpen, onClose }) => {

  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch()

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { register, handleSubmit, watch, formState: { errors }, trigger } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  const newPassword = watch('newPassword');
  const confirmPassword = watch('confirmPassword');

  const handleNewPasswordChange = (e) => {
    trigger('confirmPassword');
  };

  const submitPasswordForm = async (data) => {
    const payload = {
     // old_password: data.oldPassword,
      new_password: data.newPassword,
      new_password_confirmation: data.confirmPassword
    }
    dispatch(updatePassword(token, payload, onClose))
  }

  // const toggleOldPasswordVisibility = () => {
  //   setShowOldPassword((pre) => !pre)
  // }

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((pre) => !pre)
  }

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((pre) => !pre)
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ width: "450px", textAlign: "start" }}>
        <div className='account-heading'>
          <h2 style={{ fontWeight: "400", fontSize: "19px" }}>Update Password</h2>
          <img
            src={closeIcon}
            alt="Close"
            className="close-icon"
            onClick={onClose}
          />
        </div>
        <form onSubmit={handleSubmit(submitPasswordForm)} className="login-form">
          {/* <div className="form-group">
            <label htmlFor="oldPassword" className="form-label">
              Current Password
            </label>
            <div className="password-input-wrapper">
              <input
                type={showOldPassword ? 'text' : 'password'}
                id="oldPassword"
                maxLength={20}
                className={`form-input ${errors.oldPassword ? 'input-error' : ''}`}
                {...register('oldPassword')}
              />
              <span className="password-toggle-icon" onClick={toggleOldPasswordVisibility}>
                {showOldPassword ? <img src={hideIcon} alt='hide' /> : <img src={showIcon} alt='show' />}
              </span>
            </div>
            {errors.oldPassword && <p className="error-message">{errors.oldPassword.message}</p>}
          </div> */}
          <div className="form-group">
            <label htmlFor="newPassword" className="form-label">
              New Password
            </label>
            <div className="password-input-wrapper">
              <input
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                maxLength={20}
                className={`form-input ${errors.newPassword ? 'input-error' : ''}`}
                {...register('newPassword', { onChange: handleNewPasswordChange })}
              />
              <span className="password-toggle-icon" onClick={toggleNewPasswordVisibility}>
                {showNewPassword ? <img src={hideIcon} alt='hide' /> : <img src={showIcon} alt='show' />}
              </span>
            </div>
            {errors.newPassword && <p className="error-message">{errors.newPassword.message}</p>}
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <div className="password-input-wrapper">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                className={`form-input ${errors.confirmPassword ? 'input-error' : ''}`}
                {...register('confirmPassword')}
              />
              <span className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <img src={hideIcon} alt='hide' /> : <img src={showIcon} alt='show' />}
              </span>
            </div>
            {/* {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>} */}
            {errors.confirmPassword ? (
              <p className="error-message">{errors.confirmPassword.message}</p>
            ) : (
              newPassword && confirmPassword && newPassword === confirmPassword && (
                <p className="success-message" style={{ color: 'green', fontSize: "small" }}>Password matched</p>
              )
            )}
          </div>
          <button type="submit" className="submit-button">
            Update Password
          </button>
        </form>


      </div>
    </div>
  )
}

export default ChangePassword;
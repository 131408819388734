import React from 'react';

const NotAuthorized = () => {
  return (
    
    <div className='error-page'>
    <div className='error-emoji'>😕</div>
    <p className='error-text'>Oops!It seems that you want to access unauthorized link</p>
    <a href="/" className='error-link'>Go Back Home</a>
  </div>
  );
};

export default NotAuthorized;

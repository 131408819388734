import { setLoading } from "../../slices/authSlice";
// import toast from "react-hot-toast";
import { toast } from 'react-toastify'
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";
const BASE_URL = process.env.REACT_APP_BASE_URL

const { GET_RECRUITER_LIST_API, GET_CANDIDATE_LIST_API, CREATE_PLAN, GET_PLAN, CHANGE_ROLE_API, STRIPE_CONFIG_API, NEW_USER_CREATE, ADMIN_DASHBOARD_STATS_API, GOOGLE_AD_CREATE, GOOGLE_AD_GET } = endpoints;


export function addStripeConfig(token, payload) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", STRIPE_CONFIG_API, payload, { 'Authorization': `Bearer ${token}` });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                toast.success(response?.data?.message);
            }

        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                const errors = error.response.data.errors;
                Object.keys(errors).forEach((key) => {
                    const fieldErrors = errors[key];
                    fieldErrors.forEach((errorMessage) => {
                        toast.error(`${errorMessage}`);
                    });
                });
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}


export function getRecruiterList(token, search, sort_by, sort_direction) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("GET", GET_RECRUITER_LIST_API, "", { 'Authorization': `Bearer ${token}` }, { search: search, sort_by: sort_by, sort_direction: sort_direction });



            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response;
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

export function updateRescruiterStatus(token, id, status) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const apiUrl = `${BASE_URL}api/users/${id}/status`;
            const response = await apiConnector("PUT", apiUrl, { status }, { 'Authorization': `Bearer ${token}` });



            if (response?.status === 200) {
                dispatch(setLoading(false));
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 403) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

export function updateRoleStatus(token, payload) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", CHANGE_ROLE_API, payload, { 'Authorization': `Bearer ${token}` });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 400) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 403) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}


export function getCandidateList(token, page) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", GET_CANDIDATE_LIST_API, "", { 'Authorization': `Bearer ${token}` }, { page: page });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response;
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}


export function createPlan(token, payload) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", CREATE_PLAN, payload, { 'Authorization': `Bearer ${token}` });

            if (response?.status === 201) {
                dispatch(setLoading(false));
                toast.success(response.data.message);
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response;
            }
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                const errors = error.response.data.errors;
                Object.keys(errors).forEach((key) => {
                    const fieldErrors = errors[key];
                    fieldErrors.forEach((errorMessage) => {
                        toast.error(`${errorMessage}`);
                    });
                });
            }
            if (error?.response?.status === 500) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message);
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

export function getPlan(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET", GET_PLAN, "", { 'Authorization': `Bearer ${token}` });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response;
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

export function createNewUser(token, payload, onClose, fetchRecruiters) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", NEW_USER_CREATE, payload, { 'Authorization': `Bearer ${token}` });

            if (response?.status === 201) {
                dispatch(setLoading(false));
                onClose();
                fetchRecruiters()
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                const errors = error.response.data.errors;
                Object.keys(errors).forEach((key) => {
                    const fieldErrors = errors[key];
                    fieldErrors.forEach((errorMessage) => {
                        toast.error(`${errorMessage}`);
                    });
                });
            }
            if (error?.response?.status === 500) {
                dispatch(setLoading(false))
                onClose();
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}




export function adminDashboardStats(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("GET", ADMIN_DASHBOARD_STATS_API, "", { 'Authorization': `Bearer ${token}` });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }

        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.error)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}



export function googleAdCreate(payload, token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", GOOGLE_AD_CREATE, payload, { 'Authorization': `Bearer ${token}` });

            if (response?.status === 201) {
                dispatch(setLoading(false));
                return response;
            }

        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.error)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}


export function getGoogleAd(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("GET", GOOGLE_AD_GET, "", { 'Authorization': `Bearer ${token}` });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }

        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.error)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}



export function recruiterPaymentDetail(id,token) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const apiUrl = `${BASE_URL}api/get-payment-history/${id}`;
            const response = await apiConnector("GET", apiUrl, "", { 'Authorization': `Bearer ${token}` });
            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 422) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 403) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}
// import toast from "react-hot-toast";
import {toast} from 'react-toastify'
import { setLoading } from "../../slices/authSlice";
import { message } from "../../components/messages/Messages";
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";
import { getUserDetails } from './ProfileAPI';
const {
  VIEW_RESUME_API,
  GET_RESUMES_API,
  DOWNLOAD_API,
  GET_SKILL_LIST_API,
  MARK_AS_READ_API,
  GET_COMPANIES_LIST_API,
  GET_INDUSTRIES_LIST_API,
  UPDATE_COMPANY,
  UPDATE_SKILL,
  UPDATE_INDUSTRY,
  DELETE_COMPANY,
  DELETE_INDUSTRY,
  DELETE_SKILL,
  CREATE_NOTES,
  GET_NOTES ,
GET_LOCATION} = endpoints;





export function getResumes(token, page, payload) {
  return async (dispatch) => {
    //dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", GET_RESUMES_API, payload, { 'Authorization': `Bearer ${token}` }, { page: page })

      if (response?.status === 200) {
        //dispatch(setLoading(false)) 
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 422) {
          
        toast.error(error.response.data.error);
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}



export function viewResume(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", VIEW_RESUME_API, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 201 || response?.status === 200) {
        dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // if (error?.response?.data?.status === 400) {
      //   toast.error(error.response.data.message);
      // }
      // if (error?.response?.data?.status === 404) {
      //   toast.error(error.response.data.message);
      // }
      // if (error.status === 500) {
      //   toast.error(message.errorMessage);
      // }
    }
  }
}


export function downloadResume(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", DOWNLOAD_API, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 201 || response?.status === 200) {
        dispatch(setLoading(false))
        dispatch(getUserDetails(token))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 400) {
        dispatch(getUserDetails(token))
        toast.error(error.response.data.message);
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}

export function getLocations(token,location) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("GET", GET_LOCATION, "", { 'Authorization': `Bearer ${token}` },{location:location})

      if (response?.status === 200) {
        dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
    }
  }
}





export function getSkills(token,searchQuery) {
  return async (dispatch) => {
    //dispatch(setLoading(true))
    try {
      const response = await apiConnector("GET", GET_SKILL_LIST_API, "", { 'Authorization': `Bearer ${token}` },{search:searchQuery})

      if (response?.status === 200) {
        //dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}

export function getCompanies(token,searchQuery) {
  return async (dispatch) => {
    //dispatch(setLoading(true))
    try {
      const response = await apiConnector("GET", GET_COMPANIES_LIST_API, "", { 'Authorization': `Bearer ${token}` },{search:searchQuery})

      if (response?.status === 200) {
       // dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}

export function getIndustries(token,searchQuery) {
  return async (dispatch) => {
   // dispatch(setLoading(true))
    try {
      const response = await apiConnector("GET", GET_INDUSTRIES_LIST_API, "", { 'Authorization': `Bearer ${token}` },{search:searchQuery})

      if (response?.status === 200) {
        //dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}

export function markAsRead(token) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", MARK_AS_READ_API, "", { 'Authorization': `Bearer ${token}` })

      if (response?.status === 200) {
        dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}


export function createNote(token, payload) {
  return async (dispatch) => {
    //dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", CREATE_NOTES, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 201) {
        //dispatch(setLoading(false))
        toast.success(response.data.message)
      }
    } catch (error) {
     // dispatch(setLoading(false));
      if (error?.response?.status === 422) {

        toast.error(error.response.data.errors.note[0]);
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }

    }
  }
}


export function getNotes(token, id) {
  return async (dispatch) => {
   // dispatch(setLoading(true))
    try {
      const response = await apiConnector("GET", GET_NOTES, "", { 'Authorization': `Bearer ${token}` }, { resume_id: id })

      if (response?.status === 200) {
        //dispatch(setLoading(false))
        return response;
      }
    } catch (error) {
      //dispatch(setLoading(false));
      if (error?.response?.data?.status === 400) {
        return error.response;
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }

    }
  }
}


export function updateSkill(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", UPDATE_SKILL, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
      }
    } catch (error) {
      dispatch(setLoading(false));
      if(error?.response?.data?.status===404){
        toast.error(error.response.data.message)
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}


export function updateCompany(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", UPDATE_COMPANY, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
      }
    } catch (error) {
      dispatch(setLoading(false));
      if(error?.response?.data?.status===404){
        toast.error(error.response.data.message)
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}


export function updateIndustry(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", UPDATE_INDUSTRY, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
      }
    } catch (error) {
      dispatch(setLoading(false));
      if(error?.response?.data?.status===404){
        toast.error(error.response.data.message)
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}

export function deleteSkill(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("DELETE", DELETE_SKILL, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
      }
      if (response?.status === 404) {
        dispatch(setLoading(false))
        toast.error(response.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      if(error?.response?.data?.status===404){
        toast.error(error.response.data.message)
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}

export function deleteCompany(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("DELETE", DELETE_COMPANY, payload, { 'Authorization': `Bearer ${token}` })
      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
      }
      if(response?.status===404){
        dispatch(setLoading(false))
        toast.success(response.message)
      }
    } catch (error) {
      dispatch(setLoading(false));
      if(error?.response?.data?.status===404){
        toast.error(error.response.data.message)
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}
export function deleteIndustry(token, payload) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("DELETE", DELETE_INDUSTRY, payload, { 'Authorization': `Bearer ${token}` })

      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
      }
    } catch (error) {
      dispatch(setLoading(false));
      if(error?.response?.data?.status===404){
        toast.error(error.response.data.message)
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
    }
  }
}




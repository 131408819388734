import DownloadChart from "./DownloadChart";
import EarningChart from "./EarningChart";
import UserChart from "./UsersChart";


const Chart=({statsType})=>{

    return(
        <>
        <div className='admin-chart-container'>
                <div className="admin-upper-chart">
                    <div className='upper-left'>
                        <EarningChart statsType={statsType}/>
                    </div>
                    <div className="upper-right">
                        <DownloadChart statsType={statsType}/>
                    </div>
                </div>
                <div className='admin-lower-chart'>
                    <UserChart statsType={statsType}/>
                </div>
            </div>
        </>

    )
}

export default Chart;
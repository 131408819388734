import Footer from "./Footer";
import HomeNav from "./NavBar";



const UpcomingFeature=()=>{
    return(
        <>
        <HomeNav />  
         <section className="contact-section">
        <div className="contact-container">
        <h1 className="contact-heading">Coming Soon: Seamless Integrations with HCM & ATS</h1>
        <p className="features-text">At <strong>1800Hired</strong>, we’re committed to making hiring simpler, faster, and more efficient. That’s why we’re excited to introduce <strong>integrations with leading HCM and Applicant Tracking Systems (ATS)</strong>—allowing you to connect your favorite HR tools directly with our platform.</p>
        
        <h2 className="features-headings">What’s Coming?</h2>
        <ul>
            <li className="features-list">Oracle</li>
            <li className="features-list">PeopleSoft</li>
            <li className="features-list"> Workday</li>
            <li className="features-list">Jobvite</li>
            <li className="features-list">Lever</li>
            <li className="features-list">Greenhouse</li>
            <li className="features-list">Bullhorn</li>
        </ul>
        
        <h2 className="features-headings">Why This Matters?</h2>
        <p className="features-text">With these integrations, you can:</p>
        <ul>
            <li className="features-list"><strong>Sync hiring data effortlessly</strong> – Pull candidate and recruitment insights into 1800Hired without manual data entry.</li>
            <li className="features-list"><strong>Streamline your workflow</strong> – Manage applications, track hiring stages, and access key reports in one centralized platform.</li>
            <li className="features-list"><strong>Enhance productivity</strong> – Reduce redundant tasks and focus on making the best hires faster.</li>
        </ul>
        
        <div>
            <p className="features-text"><strong>Stay tuned for updates as we roll out these integrations. Get ready for a smarter and more connected hiring experience with 1800Hired!</strong></p>
        </div>
      </div>
    </section>
        <Footer/>
        </>

    )
}

export default UpcomingFeature;
import { lazy } from 'react';
import reset from '../../assets/Frame 194.png'
import CommonLayout from './common/commonLayout';
import ExternalButton from './common/externalButton';

const RegistrationConfirm = () => {
    return (
        <CommonLayout title='Logo'>
            <div className='header-section'>
                <img src={reset}  loading={lazy} alt='resetLink'/>
                    <h3>Registration Successful</h3>
                    <p>Please check your email for a verification link.</p>
          
            </div>
            <div className='login-form'></div>
            <ExternalButton Other='Log in' text='Already have an account' path='/login' />
            
        </CommonLayout>
    )
}

export default RegistrationConfirm;
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function OpenRoute({ children }) {
  const { token } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.profile.user);
  if (!token) {
    return children;
  }

  if (user && user.roles && user.roles.length > 0) {
    const userRole = user.roles[0].name;
    if (userRole === 'superadmin') {
      return <Navigate to="/admin-dashboard" />; 
    }
    if (userRole === 'recruiter') {
      return <Navigate to="/dashboard" />; 
    }
    if (userRole === 'marketing') {
      return <Navigate to="/marketing-dashboard" />; 
    }
  }
}

export default OpenRoute;

import React, { useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import '../../../styles/globalStyle.css';
import downIcon from '../../../assets/Polygon 2.png';
import upIcon from '../../../assets/Polygon 3.png';
import downloadInvoice from '../../../assets/download2.png'
import { message } from "../../messages/Messages";
import {toast} from 'react-toastify'
import { useSelector } from 'react-redux';

const BillingTable = ({ data,loadData, pagination, onPageChange }) => {


const loading=useSelector((state)=>state.auth.loading)
    const handleDownloadInvoice = (row) => {
        const invoiceUrl = row.invoice_url;
        if (!invoiceUrl) {
            toast.error(message.errorMessage)
            return; 
        }
        const link = document.createElement("a");
        link.href = invoiceUrl;
        link.target = "_blank"; 
        link.download = "invoice.pdf"; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const columns = useMemo(
        () => [
            {
                Header: 'Billing Date',
                accessor: 'payment_date',
                disableSortBy: true,
            },
            {
                Header: 'Total Amount',
                accessor: 'amount',
                disableSortBy: true,
            },
            {
                Header: 'Invoice',
                id: 'downloadInvoice',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <button onClick={() => handleDownloadInvoice(row.original)} style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                    >
                        <img src={downloadInvoice} alt='download-invoice'/>
                    </button>
                )
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: pagination.current_page - 1 },
            manualPagination: true,
            pageCount: pagination.total_pages,
        },
        useSortBy,
        usePagination
    );

    const renderPageNumbers = () => {
        const { current_page, total_pages } = pagination;
        const pageNumbers = []
        if (total_pages <= 5) {
            for (let i = 1; i <= total_pages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`page-btn ${i === current_page ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    className={`page-btn ${1 === current_page ? 'active' : ''}`}
                    onClick={() => onPageChange(1)}
                >
                    1
                </button>
            );

            if (current_page > 3) {
                pageNumbers.push(<span key="dots1">...</span>);
            }

            const startPage = Math.max(2, current_page - 1);
            const endPage = Math.min(total_pages - 1, current_page + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`page-btn ${i === current_page ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }

            if (current_page < total_pages - 2) {
                pageNumbers.push(<span key="dots2">...</span>);
            }

            pageNumbers.push(
                <button
                    key={total_pages}
                    className={`page-btn ${total_pages === current_page ? 'active' : ''}`}
                    onClick={() => onPageChange(total_pages)}
                >
                    {total_pages}
                </button>
            );
        }

        return pageNumbers;
    };



    return (
        <>
            <table {...getTableProps()} className='data-table'>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    <div className="header-content">
                                        {column.render('Header')}
                                        {!column.disableSortBy && (
                                            <div className="sort-icons">
                                                <img
                                                    src={downIcon}
                                                    alt="Sort Ascending"
                                                    className="sort-icon"
                                                    onClick={() => column.toggleSortBy(column.id, false)}
                                                />
                                                <img
                                                    src={upIcon}
                                                    alt="Sort Descending"
                                                    className="sort-icon"
                                                    onClick={() => column.toggleSortBy(column.id, true)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {/* <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                            >
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))} 
                            </tr>
                        );
                    })}
                </tbody> */} 
<tbody {...getTableBodyProps()}>
                    {loadData ? (
                        [...Array(4)].map((_, index) => (
                            <tr key={index}>
                                <td colSpan={columns.length} style={{ padding: '20px' }}>
                                    <div className="skeleton-row"></div>
                                </td>
                            </tr>
                        ))
                    ) : data?.length === 0 ? (
                        <tr>
                            <td colSpan={columns.length} className="no-data">
                                No data to display
                            </td>
                        </tr>
                    ) : (
                        page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{ cursor: 'pointer' }}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
            <div className='pagination-container'>
                <button
                    className={`page-btn ${pagination.current_page === 1 ? 'disabled' : ''}`}
                    onClick={() => onPageChange(pagination.current_page - 1)}
                    disabled={pagination.current_page === 1}
                >
                    Prev
                </button>

                {renderPageNumbers()}

                <button
                    className={`page-btn ${pagination.current_page === pagination.total_pages ? 'disabled' : ''}`}
                    onClick={() => onPageChange(pagination.current_page + 1)}
                    disabled={pagination.current_page === pagination.total_pages}
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default BillingTable;

import { setLoading } from "../../slices/authSlice";
// import toast from "react-hot-toast";
import {toast} from 'react-toastify'
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";

const {EMAIL_SEND_MARKETING} = endpoints;


export function emailSendMarketing(formData,token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST",EMAIL_SEND_MARKETING, formData,{
                'Authorization': `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            });

            if (response?.status === 200) {
                dispatch(setLoading(false));
                toast.success(response.data.message);
            }

        } catch (error) {
            if (error?.response?.status === 500) { 
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 400) { 
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from '../../loader/Loader'
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { linkedinLogin, linkedinRegister } from "../../../services/operations/authAPI";

const Callback = () => {
  const navigate = useNavigate(); 
  const dispatch=useDispatch();
 
  useEffect(() => {
    const handleLinkedInAuth = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        const type = queryParams.get("state");

        const baseUrl=window.location.origin
        if (!code) {
          return navigate("/login"); 
        }

        if (type === "register") {
          await dispatch(linkedinRegister(code, navigate, baseUrl));
        } else if (type === "login") {
          await dispatch(linkedinLogin(code, navigate, baseUrl));
        }
    };
    

    handleLinkedInAuth();
  }, [navigate]);

  return <Loader/>;
};

export default Callback;

import React, { useState } from "react";
import closeIcon from "../../../assets/Close.png";
import { useDispatch, useSelector } from "react-redux";
import { markAsRead } from "../../../services/operations/ResumesAPI";

const formatDate = (dateString) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${monthNames[date.getMonth()]} ${day}, ${year}`;
};

const NotificationBell = ({
  isOpen,
  notifications,
  setNotifications,
  onClose,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const [visibleCount, setVisibleCount] = useState(8);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 8); 
  };

  const handleMarkRead = async () => {
    await dispatch(markAsRead(token));
    setNotifications({}); 
  };

  const allNotifications = Object.entries(notifications).flatMap(([date, messages]) =>
    messages.map((message) => ({ date, message: message.message }))
  );
  const visibleNotifications = allNotifications.slice(0, visibleCount);

  const groupedVisibleNotifications = visibleNotifications.reduce((acc, { date, message }) => {
    if (!acc[date]) acc[date] = [];
    acc[date].push(message);
    return acc;
  }, {});

  return (
    <div className={`bell-modal ${isOpen ? "open" : ""}`}>
      <div className="bell-modal-header">
        <h2>Notifications ({allNotifications.length})</h2>
        <button className="close-button-bell" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </button>
      </div>
      <div className="bell-modal-content">
        {allNotifications.length > 0 && (
          <button className="btn-read-notification" onClick={handleMarkRead}>
            Mark All As Read
          </button>
        )}

        {Object.entries(groupedVisibleNotifications).map(([date, messages]) => (
          <div key={date} className="notification-group">
            <h5 className="notification-date">{formatDate(date)}</h5>
            {messages.map((message, index) => (
              <p className="notification-message-style" key={index}>
                {message}
              </p>
            ))}
          </div>
        ))}

        {allNotifications.length > visibleCount && (
          <button className="btn-load-more" onClick={handleLoadMore}>
            Load More...
          </button>
        )}
      </div>
    </div>
  );
};

export default NotificationBell;

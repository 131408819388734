import { useState } from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import visaIon from '../../../assets/visaIcon.png'
import masterIcon from '../../../assets/masterIcon.png'
import americanExpressIcon from '../../../assets/american-express.png'
import jcbIcon from '../../../assets/jcb.png'
import discoverIcon from '../../../assets/4373195_card_credit_discover_logo_logos_icon.png'
import unknownIcon from '../../../assets/unknown-card.png'
import { Subscribe } from "../../../services/operations/CreditAPI";
import { getUserDetails } from "../../../services/operations/ProfileAPI";
import { message } from "../../messages/Messages";
import { Autocomplete } from "@react-google-maps/api";


const PaymentForm = ({ selectedPlan,getPlan, token, onSuccess }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [cardBrand, setCardBrand] = useState("");

  const handleCardChange = (event) => {
    if (event.brand) {

      setCardBrand(event.brand);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      setLoading(false)
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
    });
    if (error) {
      toast.error(error.message);
      setLoading(false);
      return;
    }

    try {
      const payload = {
        plan_id: selectedPlan.id,
        payment_method: paymentMethod?.id,
        amount: parseFloat(selectedPlan.total_amount),
      }
      const paymentIntentResponse = await dispatch(Subscribe(payload, token))

      if (paymentIntentResponse?.data?.success === true) {
        toast.success(paymentIntentResponse.data.message);
        onSuccess();
        dispatch(getUserDetails(token))
        getPlan()

      }
      else{
        toast.error("Some error occurred during payment,please try after some time")
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      toast.error(message.PaymnetFailureMessage);
    }
  };

  const getCardBrandIcon = (brand) => {
    switch (brand) {
      case "visa":
        return <img src={visaIon}  style={{top:"10px"}}alt="Visa" className="card-brand-icon" />;
      case "mastercard":
        return <img src={masterIcon} style={{top:"10px"}} alt="masterCard" className="card-brand-icon" />;
      case "amex":
        return <img src={americanExpressIcon} width={40} height={40} alt="americanExpress" className="card-brand-icon" />;
      case "discover":
        return <img src={discoverIcon} style={{ top: "0px" }} width={40} height={40} alt="discover" className="card-brand-icon" />;
      case "jcb":
        return <img src={jcbIcon}  width={40} height={40} alt="jcb" className="card-brand-icon" />;
      default:
        return <img src={unknownIcon}  width={40} height={40} alt="unknown" className="card-brand-icon" />;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <p className="payment-form-title">Enter Card Details</p>

      <div className="payment-form-group">
        <label>Card Number</label>
        <div className="card-number-container">
          <CardNumberElement
            options={{
              placeholder: "XXXX XXXX XXXX XXXX",
              autocomplete: "off", 
              disableLink: true, 
              style: {
                base: {
                  color: "#424770",
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  iconColor: "#c4f0ff",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
              },
            }}
            className="card-input"
            onChange={handleCardChange}
          />
          <span className="card-brand-icon">
            {cardBrand && getCardBrandIcon(cardBrand)}
          </span>
        </div>
      </div>

      <div className="payment-form-group">
        <label>Expiry Date</label>
        <CardExpiryElement
          options={{
            style: {
              base: {
                color: "#424770",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
            },
          }}
          className="card-input"
        />
      </div>
      <div className="payment-form-group">
        <label>CVV</label>
        <CardCvcElement
          options={{
            style: {
              base: {
                color: "#424770",
                fontFamily: "'Roboto', sans-serif",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
            }
          }}
          className="card-input"
        />
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="payment-btn"
        disabled={!stripe || loading}
      >
        {loading ? "Payment Processing..." : "Continue with Payment"}
      </button>
    </form>
  );
};

export default PaymentForm;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../../styles/globalStyle.css'
import { useForm } from "react-hook-form";
import SideBar from "../common/SideBar"
import filter from '../../../assets/filter-lines.png'
import search from '../../../assets/Group 33509.png'
import DataTable from "./Tables";
import { getRecruiterList } from '../../../services/operations/AdminPageAPI';
import { getUserDetails } from '../../../services/operations/ProfileAPI';
import { getCompanies } from "../../../services/operations/ResumesAPI";
import debounce from "lodash.debounce";


const Companies = () => {

    const { register, setValue, formState: { errors }, reset } = useForm({});

    const [result, setResult] = useState([])
    const [paginatedcompany, setPaginatedCompany] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
     const [loadData, setLoadData] = useState(false);
    const [pagination, setPagination] = useState({ current_page: 1, per_page: 15, total_pages: 1, total: "" });

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.profile.user)
    const handlePageChange = (newPage) => {
        setPagination((prev) => ({ ...prev, current_page: newPage }));
    };

    const fetchCompanies = async () => {

        setLoadData(true);
        const response = await dispatch(getCompanies(token,searchQuery));
        if (response?.data?.companies) {
            setLoadData(false);
            const companyData = response.data.companies
            setResult(companyData);

            const total_pages = Math.ceil(companyData.length / pagination.per_page);
            setPagination((prev) => ({ ...prev, total_pages, total: companyData.length }));
        }
    };


    const handleSearch = debounce((query) => {
        setSearchQuery(query);
        setPagination((prev) => ({ ...prev, current_page: 1 }));
      }, 300);
    
      const handleSearchInputChange = (event) => {
        const query = event.target.value.trim();
        if (query !== searchQuery) {
          handleSearch(query);
        }
      }; 

      useEffect(() => {
        fetchCompanies();
    }, [searchQuery]);

    useEffect(() => {
        dispatch(getUserDetails(token));
        fetchCompanies();
    }, []);

    useEffect(() => {
        const startIndex = (pagination.current_page - 1) * pagination.per_page;
        const endIndex = startIndex + pagination.per_page;
        setPaginatedCompany(result.slice(startIndex, endIndex));
    }, [result, pagination]);

    return (
        <div className="admin-dashboard-container">
            <SideBar user={user} />
            <div className="admin-dashboard-content">
                <div className="admin-dashboard-heading">
                    <div className="recruiter-heading" style={{ marginBottom: "15px" }}>
                        <div className="resume-title">
                            <h5 style={{ fontSize: "24px", fontWeight: "600" }}>
                                Companies
                            </h5>
                        </div>
                        <div className="resume-controls">
                            <div className="search-container-recruiter">
                                <img src={search} alt="Search" className="search-icon" />
                                <input
                                    type="text"
                                    id="search"
                                    placeholder="Company Name"
                                    className="search-bar"
                                    style={{ borderRadius: "8px" }}
                                    onChange={handleSearchInputChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='table' style={{ margin: "0px" }}>
                        <DataTable companies={paginatedcompany}
                            pagination={pagination}
                            onPageChange={handlePageChange}
                            fetchCompanies={fetchCompanies}
                            loadData={loadData} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Companies;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/globalStyle.css";
import SideBar from "../common/SideBar";
import search from "../../../assets/Group 33509.png";
import DataTable from "./Tables";
import { getUserDetails } from "../../../services/operations/ProfileAPI";
import { getSkills } from "../../../services/operations/ResumesAPI";
import debounce from "lodash.debounce";
import Loader from "../../loader/Loader";

const Skills = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const loading=useSelector((state)=>state.auth.loading)
  const user = useSelector((state) => state.profile.user);

  const [result, setResult] = useState([]);
  const [paginatedSkills, setPaginatedSkills] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 15,
    total_pages: 1,
    total: 0,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);


  const fetchskills = async () => {

    setLoadData(true);
 
      const response = await dispatch(getSkills(token, searchQuery));
      if (response?.data?.skills) {
        setLoadData(false);
        const skillsData = response.data.skills
        setResult(skillsData);
        const total_pages = Math.ceil(skillsData.length / pagination.per_page);
        setPagination((prev) => ({ ...prev, total_pages, total: skillsData.length }));
    }
  };

  const handleSearch = debounce((query) => {
    setSearchQuery(query);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  }, 300);

  const handleSearchInputChange = (event) => {
    const query = event.target.value.trim();
    if (query !== searchQuery) {
      handleSearch(query);
    }
  }; 

  const handlePageChange = (newPage) => {
    setPagination((prev) => ({ ...prev, current_page: newPage }));
  };

  useEffect(() => {
    dispatch(getUserDetails(token));
  }, [dispatch, token]);

  useEffect(() => {
    fetchskills();
  }, [searchQuery]);

  useEffect(() => {
  
    const startIndex = (pagination.current_page - 1) * pagination.per_page;
    const endIndex = startIndex + pagination.per_page;

    setPaginatedSkills(result?.slice(startIndex, endIndex));
  }, [result, pagination]);

  return (
    <div className="admin-dashboard-container">
      <SideBar
        user={user}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <div
        className={`admin-dashboard-content ${
          isSidebarOpen ? "sidebar-open" : ""
        }`}
      >
        <div className="admin-dashboard-heading">
          <div className="recruiter-heading" style={{ marginBottom: "15px" }}>
            <div className="resume-title">
              <h5 style={{ fontSize: "24px", fontWeight: "600" }}>Skills</h5>
            </div>
            <div className="resume-controls">
              <div className="search-container-recruiter">
                <img src={search} alt="Search" className="search-icon" />
                <input
                  type="text"
                  id="search"
                  placeholder="Skill Name"
                  className="search-bar"
                  style={{ borderRadius: "8px" }}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="table" style={{ margin: "0px" }}>
            <DataTable
            loadData={loadData}
              skills={paginatedSkills}
              pagination={pagination}
              onPageChange={handlePageChange}
              fetchSkills={fetchskills}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;

import React, { useState,useEffect } from 'react';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CommonLayout from './common/commonLayout';
import '../../styles/globalStyle.css'
import ExternalButton from './common/externalButton';
import HeaderSection from './common/headerSection';
import hideIcon from '../../assets/hideIcon.png'
import showIcon from '../../assets/showIcon.png'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../services/operations/authAPI';
import Loader from '../loader/Loader';
import toast from 'react-hot-toast';
import { message } from '../messages/Messages';


const schema = yup.object().shape({
  email: yup.string().email(message.invalidEmailMessage).required('Email is required').matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, message.invalidEmailMessage),
  password: yup.string().required(message.passwordRequiredMessage),
});

const Login = () => {

  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { register, handleSubmit, setValue, formState: { errors },reset } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate=useNavigate()
  const dispatch=useDispatch()
  const loading = useSelector((state) => state.auth.loading)

  useEffect(() => {
    const rememberedEmail = Cookies.get('rememberedEmail');
    const rememberedPassword = Cookies.get('rememberedPassword');
    const rememberedCheckbox = Cookies.get('rememberMe') === 'true';
  
    if (rememberedEmail && rememberedPassword) {
      setValue('email', rememberedEmail);
      setValue('password', rememberedPassword);
      setRememberMe(rememberedCheckbox);
    }
  }, [setValue]);
  
  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const onSubmit = (data) => {
    const { email, password } = data;
  
    if (rememberMe) {
      Cookies.set('rememberedEmail', email, { expires: 2 });
      Cookies.set('rememberedPassword', password, { expires: 2 });
      Cookies.set('rememberMe', 'true', { expires: 2 });
    } else {
      Cookies.remove('rememberedEmail');
      Cookies.remove('rememberedPassword');
      Cookies.remove('rememberMe');
    }
      dispatch(login(email, password, navigate, reset));

    
  };

  return (
    <CommonLayout title="Login" pageType="login">
          {loading&& <Loader/>}
      <HeaderSection text='Welcome Back! ' text2='Experience hiring the AI way. Start with 1800Hired today!' />
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="text"
            id="email"
            className={`form-input ${errors.email ? 'input-error' : ''}`}
            {...register('email')}
          />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="password-input-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              className={`form-input ${errors.password ? 'input-error' : ''}`}
              {...register('password')}
            />
            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
              {showPassword ? <img src={hideIcon} alt='hide'/> : <img src={showIcon} alt='show'/>}
            </span>
          </div>
          {errors.password && <p className="error-message">{errors.password.message}</p>}
        </div>
        <div className="remember remember-me">
          <div className="remember-me-left">
            <input
              type="checkbox"
              id="rememberMe"
              className="form-checkbox"
              checked={rememberMe}
              onChange={() => setRememberMe(!rememberMe)}
            />
            <label htmlFor="rememberMe" className="form-label">
              Remember Me
            </label>
          </div>
          <div className="remember-me-right">
            <Link to="/forgetpassword" className="forgot-password-link">
              Forgot Password?
            </Link>
          </div>
        </div>
       <button type="submit" className="submit-button">
          Login
        </button>
      </form>

      <ExternalButton googleText="Sign in with Google" type='login' linkedinText="Sign in with Linkedin" Other="Register" text="Don't have an account" path="/register" />
    </CommonLayout>
  );
};

export default Login;

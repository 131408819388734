import React, { useState } from 'react';
import '../../../styles/globalStyle.css';
import FileUploadModal from '../upload/fileUploadModal';
import { useSelector } from 'react-redux';

const CreditContent = ({ fetchResumes }) => {

    const [isModalOpen, setModalOpen] = useState(false);
    const user = useSelector((state) => state.profile.user)

    const { user_credits } = user;

    const containerClass = user?.is_premium
        ? 'credit-container premium'
        : 'credit-container non-premium';

    const getContentText = () => {
        if (user?.is_premium === true) {
            return "Thank you for purchasing premium plan. Enjoy unlimited access to resumes.";
        }
       return `${(user?.user_credits?.credits) + (user?.user_credits?.daily_credit)} Credits remaining! Upload more resumes to get more credits`;
 };
    // const handleOpenModal = () => {
    //     setModalOpen(true);
    // };

    const handleCloseModal = () => {
        setModalOpen(false);
    };
    return (
        <div className={containerClass}>
            <div className="credit-content">
                <p className="credit-heading">
                    {getContentText()}
                    {/* {!user?.is_premium && <button className="upload-button" onClick={handleOpenModal}>Upload Now</button>} */}

                </p>
            </div>
            <div className="credit-icon">

            </div>
            {isModalOpen && <FileUploadModal fetchResumes={fetchResumes} onClose={handleCloseModal} isOpen={isModalOpen} />}
        </div>

    );
};

export default CreditContent;

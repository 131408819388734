import { useSelector } from "react-redux";
import Header from "./Header";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";

const AdminDashboardLayout = () => {
  const user=useSelector((state)=>state.profile.user)
  return (
    <>
      <Header/>
      {/* <SideBar user={user}/> */}
      <Outlet />
    </>
  );
};

export default AdminDashboardLayout;   
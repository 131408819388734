import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {configureStore} from "@reduxjs/toolkit"
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer'
import "@fontsource/open-sans"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';

const GOOGLE_ID=process.env.REACT_APP_GOOGLE_CLIENT_ID

const store = configureStore({
  reducer:rootReducer,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store = {store}>
  <GoogleOAuthProvider clientId={GOOGLE_ID}>
    <BrowserRouter>
        <App />
        <ToastContainer/>
      </BrowserRouter>
      </GoogleOAuthProvider>
  </Provider>
);


import React from 'react';
import '../../../styles/globalStyle.css';
import closeIcon from '../../../assets/Close.png';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../loader/Loader';
import errorIcon from '../../../assets/icons8-error-50.png'
import tick from '../../../assets/tick.png'
import { useNavigate } from 'react-router-dom';

const PopupLayout = ({ isOpen, onClose }) => {

    const loading = useSelector((state) => state.auth.loading);
    const navigate=useNavigate();


    const handleModalClose = () => {
        onClose();
    };


    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            {loading && <Loader />}
            <div className="modal-content" style={{ width: "60%" }}>
                <div className='upload-heading' style={{display:"flex",justifyContent:"flex-end"}}>
                    <img src={closeIcon} alt="Close" className="close-icon" onClick={handleModalClose} />
                </div>
                <div className='credit-plan-info'>
                    <p className='alert-heading'><img src={errorIcon} alt='error-icon'/>You have 0 credits remaining</p>
                    <h3 style={{color:"#CC0000"}}>You can still continue</h3>
                    <div className='upload-or-plan'>
                    <div className='buy-to-get'>
                        
                        <h3>Premium</h3>
                            <p style={{fontSize:"15px"}}><s>$9.99/month</s></p>
                            <p style={{fontSize:"12px"}}>$0.99 for first month</p>
                           <hr/>
                           <p className="pricing-same"><img src={tick} alt='tick'/>No  credit limit</p>
                           <p className="pricing-same"><img src={tick} alt='tick'/>Unlimited Resumes Access</p>
                           <p className="pricing-same"><img src={tick} alt='tick'/>Unlimited View/Downloads</p>
                           <p className="pricing-same"><img src={tick} alt='tick'/>Cancel anytime</p>
                           <button  className='no-credit-popup-btn'onClick={()=>navigate('/dashboard/credit')}>Subscribe Now</button>
                        </div>
                        <div className="or-line">
                            <div className="line"></div>
                            <p>OR</p>
                            <div className="line"></div>
                        </div>
                        <div className='upload-to-get'>
                            <p>Upload minimum 10 resume to get credits</p>
                        </div>
                        

                       
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PopupLayout;

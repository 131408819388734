
export const fetchGoogleUserInfo = async (accessToken) => {
  try {
    const response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch user information from Google");
    }

    const userInfo = await response.json();
    if (userInfo?.email) {
      sessionStorage.setItem("lastGoogleAccount", userInfo.email);
    }

    return userInfo;
  } catch (error) {
    console.error("Error fetching Google user info:", error);
  }
};

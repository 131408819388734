import React, { lazy } from 'react';
import Image from '../../../assets/logoRegister.png'
import Logo from '../../../assets/logo-transparent-png.png'
import { Link } from 'react-router-dom';
const commonLayout = ({ title, children, pageType }) => {
  return (
    <div className="layout-container">
      <div className="left-side">
          <div className='logo-section'>
            <Link to='/'><img src={Logo} loading={lazy} alt='Logo'/> </Link>
              
          </div>
          <div className='form-section'>
            {children}
          </div>
      </div>
      <div className="right-side">
        <img
          src={Image}
          alt="imagespace"
          width="688"
          height="1024"
          className="image-right"
          loading={lazy}
        />
      </div>
    </div>
  );
};

export default commonLayout;

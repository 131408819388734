import Header from "./Header";
import { Outlet } from "react-router-dom";

const MarketingDashboardLayout=()=>{
    return(
      <>
        <Header/>
        <Outlet/>

      </>
    )
}

export default MarketingDashboardLayout;

import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

function VerifyRoute({ children }) {
  const { signupData } = useSelector((state) => state.auth)

  if (signupData !== null) {
    return children
  }
  return <Navigate to="/login" />
}

export default VerifyRoute
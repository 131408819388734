import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../services/operations/ProfileAPI";  
import { adminDashboardStats } from "../../services/operations/AdminPageAPI";
import Chart from "./Chart/Chart";
import SideBar from "./common/SideBar";
import Loader from "../loader/Loader";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const loading=useSelector((state)=>state.auth.loading)
  const [chartData, setChartData] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const user=useSelector((state)=>state.profile.user)
  const [statsType, setStatsType] = useState("Month");


  const handleStatsChange = (e) => {
    setStatsType(e.target.value);
  };
  const getDashboardCount=async()=>{
          const response=await dispatch(adminDashboardStats(token))
          if (response?.data?.data) {
              setChartData(response.data.data);
          }
  
      }
  useEffect(()=>{
      getDashboardCount()
  },[])

  console.log(chartData);
  return (
    <div className="admin-dashboard-container">
      {/* Sidebar component */}
      <SideBar user={user} />
      <div className="admin-dashboard-content">
        <div className="admin-dashboard-heading">
            <p>Dashboard</p>
            <div className='admin-content'>
                <h5>Overview</h5>
                <p>Today's Signup: {chartData?.today_signups}</p>
                <p>Today's Uploads: {chartData?.today_uploads}</p>
                <select
              className="stats-select"
              value={statsType}
              onChange={handleStatsChange} 
            >
              <option value="Week">Weekly</option>
              <option value="Month">Monthly</option>
              <option value="Year">Yearly</option>
            </select>
            </div>
            <Chart statsType={statsType} />
        </div>
        
      </div>
    </div>
  );
};

export default AdminDashboard;

import React from 'react';
import '../../styles/globalStyle.css';

const Error = () => {
  return (
    <div className='error-page'>
      <div className='error-emoji'>😕</div>
      <p className='error-text'>Oops! Error - 404 Page Not Found</p>
      <a href="/" className='error-link'>Go Back Home</a>
    </div>
  );
};
 
export default Error;

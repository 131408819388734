import CreditContent from "../common/creditSection";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../../loader/Loader';
import coin from '../../../assets/Crypto Coins.png';
import '../../../styles/globalStyle.css';
import { getUserDetails } from "../../../services/operations/ProfileAPI";
import { getCreditPlan, getCreditBillingHistory } from "../../../services/operations/CreditAPI";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import PremiumPlan from "./PremiumPlan";

const stripePromise = loadStripe(process.env.REACT_APP_PAYMENT_KEY);

const Credit = () => {
    const loading = useSelector((state) => state.auth.loading);
    const user = useSelector((state) => state.profile.user);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const [plans, setPlans] = useState([]);
    const [billing, setBilling] = useState([]);
    const [loadData,setLoadData]=useState(false);
   // const [creditHistory, setCreditHistory] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 8,
        total_pages: "",
        total: ""
    });
    // const [creditPagination, setCreditPagination] = useState({
    //     current_page: 1,
    //     per_page: 4,
    //     total_pages: "",
    //     total: ""
    // });


    useEffect(() => {
        dispatch(getUserDetails(token));
        getPlan();
        getCreditBilling();
        //getCreditHistory();
    }, []);

    useEffect(()=>{
        getCreditBilling(pagination.current_page);
    },[pagination.current_page])

    // useEffect(()=>{
    //     getCreditHistory(creditPagination.current_page);
    // },[creditPagination.current_page])

    const getPlan = async () => {
        const response = await dispatch(getCreditPlan(token));
        if (response?.data) {
            setPlans(response.data.data);
            if (response.data.data.length > 0) {
                setSelectedPlan(response.data.data[0]);
            }
        }
    };

    const getCreditBilling = async (page=1) => {
        setLoadData(true);
        const response = await dispatch(getCreditBillingHistory(token,page));
        if (response?.data?.data) {
            setLoadData(false)
            setBilling(response.data.data);
            setPagination({
                current_page: response.data.pagination.current_page,
                per_page: response.data.pagination.per_page,
                total_pages: response.data.pagination.total_pages,
                total: response.data.pagination.total_records,
            });
        }
    };

    // const getCreditHistory = async (page = 1) => { 
    //     const response = await dispatch(getCreditHistoryBilling(token,page));
    //     if (response?.data?.data){
    //         setCreditHistory(response.data.data);
    //         setCreditPagination({
    //             current_page: response.data.pagination.current_page,
    //             per_page: response.data.pagination.per_page,
    //             total_pages: response.data.pagination.total_pages,
    //             total: response.data.pagination.total_records,
    //         });
    //     }
    // };


    const handlePageChange = (newPage) => {
        setPagination((prev) => ({ ...prev, current_page: newPage }));
    };

    // const handleCreditPageChange = (newPage) => {
    //     setCreditPagination((prev) => ({ ...prev, current_page: newPage }));
    // };


    return (
        <>
            {loading && <Loader />}
            <CreditContent />
            {!loading && (user?.is_premium===true ? (<PremiumPlan plans={plans} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} loadData={loadData} data={billing} pagination={pagination} 
            handlePageChange={handlePageChange}/>):(
                  <div className='credit-section'>
                 <div className="credits">
                     <div className='uppar'>
                     <h3>Credits</h3>
                         <p><span><img src={coin} alt='coin' style={{ marginRight: "4px" }} /></span>{`${(user?.user_credits?.credits) + (user?.user_credits?.daily_credit)}`} Credits Remaining</p>
                     </div>
                     <div className='lower'>
                         <div className="left-credit">
                             <div className='payment-plan'>
                                 {plans?.length > 0 && (
                                     plans.map((plan) => (
                                         <div
                                             key={plan.id}
                                             className={`plan-detail ${selectedPlan?.id === plan.id ? 'selected-plan' : ''}`}
                                             onClick={() => setSelectedPlan(plan)}
                                         >
                                             <div className='plan-time'>
                                                 <input
                                                     type="radio"
                                                     id={plan?.amount}
                                                     name="plan"
                                                     value={plan?.amount}
                                                     checked={selectedPlan?.id === plan.id}
                                                     onChange={() => setSelectedPlan(plan)}
                                                 />
                                                 <label htmlFor={plan.amount}>${plan.amount} for {plan.duration} days</label>
                                             </div>
                                             <hr />
                                             <div className='plan-info'>
                                                 <span><span style={{ fontSize: "24px", fontWeight: "600" }}>${plan.amount}</span></span>
                                                 <p>{selectedPlan?.description}</p> 
                                             </div>
                                         </div>
                                     ))
                                 )}
                             </div>
                                 {/* <div className='payment-history'>                               
                                 <p>Credit History</p>
                                 <div className='table-billing'>
                                     <CreditTable
                                     data={creditHistory} 
                                     pagination={creditPagination} 
                                     onPageChange={handleCreditPageChange}
                                      />
 
                                 </div> 
                             </div>                                 */}
                         </div>
                         <div className="right-section-credit">
                         <div className='right-credit-left'>
                             <div className='payment-heading'>
                                 <span> Your Payment Summary</span>
                                 {/* <p>starts on 2nd Sept, 2024</p> */}
                             </div>
                             <div className="payment-summary">
                                 <div className='payment-list'>
                                     <span>Price($)</span>
                                     {selectedPlan &&<p>{selectedPlan?.amount}</p>}
                                 </div>
                               
                                 {selectedPlan?.tax_percentage!=="0.00" && 
                                 <>
                                   <hr/>
                                     <div className='payment-list'>
                                     <span>Tax({selectedPlan?.tax_percentage}%)</span>
                                     {selectedPlan &&<p>{selectedPlan?.tax_amount}</p>}
                                 </div>
                                 </>
                                 }
                                 <hr />
                                 <div className='payment-list'>
                                     <span>Total($)</span>
                                     {selectedPlan &&<p>{selectedPlan?.total_amount}</p>}
                                     
                                 </div>
                             </div>
                             </div>
                             <div className="right-credit-right">
                             <Elements stripe={stripePromise}>
                                     <PaymentForm selectedPlan={selectedPlan} token={token} onSuccess={() => getCreditBilling()}  getPlan={getPlan}/>
                             </Elements>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
            ))}
           
        </>
    );
};

export default Credit;

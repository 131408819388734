import { Outlet } from "react-router-dom";
import NavBar from "./navBar";

const DashboardLayout = () => {
  return (
    <>
      <NavBar/>
      <Outlet />
    </>
  );
};

export default DashboardLayout;

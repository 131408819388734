import React, { useState } from 'react';

const faqItems = [
  {
    id: 1,
    question: "Access a Global Pool of Talent",
    answer: (
      <>
        <p>
          Need more options? Gain instant access to millions of global resumes. Our expansive database
          is your gateway to discovering top-tier talent across industries and locations. Use our AI search tools to
          identify candidates that align perfectly with your open roles and business goals
        </p>
      </>
    )
  },
  {
    id: 2,
    question: "Keep Track of Conversations with Candidate Notes",
    answer: (
      <>
        <p>
          1800Hired.com goes beyond just resume management—it helps you stay organized throughout the
          hiring process. With our built-in note-taking feature, you can easily document important
          details about each candidate, such as the conversations you've had, their preferences, or specific
          insights from interviews. This ensures that all your candidate interactions are stored in one convenient
          place, making it easier to follow up, evaluate, and make informed hiring decisions. No more lost details
          or scattered information—everything you need is right at your fingertips.
        </p>

      </>
    )
  },
  {
    id: 3,
    question: "Seamless Resume Downloads",
    answer: (
      <>
        <p>
          Once you’ve identified potential hires, downloading their resumes is as simple as a click. Save time, stay
          organized, and keep your pipeline full of qualified candidates ready to take your business to the next
          level
        </p>
      </>
    )
  },
];

const AboutHired = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="faq-section">
      <div className="faq-container">
        <h2 className="faq-title">More Information about us</h2>
        <div className="accordion">
          {faqItems.map((item, index) => (
            <div key={item.id} className="accordion-item">
              <h3 className="accordion-header">
                <button
                  type="button"
                  className={`accordion-button ${activeIndex === index ? "active" : ""}`}
                  onClick={() => toggleAccordion(index)}
                  aria-expanded={activeIndex === index}
                  aria-controls={`accordion-body-${item.id}`}
                >
                  <span>{item.question}</span>
                  <svg
                    className={`accordion-icon ${activeIndex === index ? "rotate" : ""}`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </h3>
              <div
                id={`accordion-body-${item.id}`}
                className={`accordion-body ${activeIndex === index ? "open" : "closed"}`}
              >
                <div className="accordion-content">
                  {item.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutHired

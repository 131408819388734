import React ,{useState}from 'react';
import { NavLink } from 'react-router-dom'; 
import '../../../styles/globalStyle.css';
import Expand from '../../../assets/expand.png';
import Home from '../../../assets/home.png'; 
import candidates from '../../../assets/users-01.png';
import recruiters from '../../../assets/user-circle.png';
import Integration from '../../../assets/intergation.png';
import Setting from '../../../assets/icons8-settings-24.png'
import companies from '../../../assets/icons8-company-24.png'
import Skills from '../../../assets/skill-development.png'
import Industry from '../../../assets/icons8-factory-15.png'
import adSense from '../../../assets/adsense.png'

const iconMapping = {
    "Dashboard": Home,
    "Recuriters": recruiters,
    "Candidates": candidates,
    "Skills":Skills,
    "Industries":Industry,
    "Companies":companies,
    "Integrations": Integration,
    "Settings":Setting,
    "AdSense":adSense
};

const SideBar = ({ user }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(
        localStorage.getItem('isSidebarOpen') === 'false' ? false : true
      );
    
      const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => {
          const newState = !prevState;
          localStorage.setItem('isSidebarOpen', newState); 
          return newState;
        });
      };

    return (
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
            <div className="sidebar-toggle" onClick={toggleSidebar}>
                <img src={Expand} alt="Toggle Sidebar"  width={22} height={22}/>
            </div>
            <div className="sidebar-content">
                <ul>
                    {user.roles[0]?.permissions?.map((permission, index) => (
                        <li key={index} className='sidebar-item'>
                            <NavLink
                                to={permission.path}
                                end
                                className={({ isActive }) => (isActive ? 'active-link' : '')}
                            >
                                <img 
                                    src={iconMapping[permission.displayName]} 
                                    alt={permission.displayName} 
                                    width={20}
                                    height={20}
                                   
                                />
                                {isSidebarOpen && <span style={{fontSize:"18px"}}>{permission.displayName}</span>}
                               
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SideBar;

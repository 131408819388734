import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Image from '../../assets/Frame 194.png';
import { lazy } from "react";
import Logo from '../../assets/logo-transparent-png.png';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../slices/authSlice";

const VerfiyEmail = () => {

  const signupData=useSelector((state)=>state.auth.signupData)
  const navigate=useNavigate();
  const location=useLocation();


 


  useEffect(() => {
    const isEmailVerified = localStorage.getItem('emailVerified');
    
    if (isEmailVerified) {
      navigate('/login');
      localStorage.removeItem('emailVerified');

    } else {
      localStorage.setItem('emailVerified', 'true');
    }
  }, [navigate]);

  return (
    <> 
        <div>
          <div className='logo-section' style={{ marginLeft: "50px" }}>
            <img src={Logo} alt='logoicon' loading={lazy} />
          </div>
          <div className='Verify-email'>
            <img src={Image} alt='logo' loading={lazy} />
            <h3>Verification Complete! </h3>
            <div className="link-to">
              <span className="link-text">
                Thanks for confirming your email. You can now <Link to='/login'>Log in </Link> to your account.
              </span>
            </div>
          </div>
        </div>
    </>
  );
};

export default VerfiyEmail;

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CommonLayout from './common/commonLayout';
import '../../styles/globalStyle.css'
import ExternalButton from './common/externalButton';
import HeaderSection from './common/headerSection';
import hideIcon from '../../assets/hideIcon.png'
import showIcon from '../../assets/showIcon.png'
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../services/operations/authAPI';
import Loader from '../loader/Loader';
import { message } from '../messages/Messages';


const schema = yup.object().shape({
  password: yup.string()
    .required(message.passwordRequiredMessage)
    .min(8, message.passwordMinMessage)
    .matches(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w])[A-Za-z\d\W_]{8,}$/,
     message.passwordMatchMessage
    ),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], message.matchMessage)
    .required(message.confirmPasswordRequiredMessage),
});

const UpdatePassword = () => {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { register, handleSubmit, watch, formState: { errors },trigger } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });


  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  const handleNewPasswordChange = (e) => {
    trigger('confirmPassword');
  };

  const loading = useSelector((state) => state.auth.loading)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const pathParts = location.pathname.split('/');
  const token = pathParts[2];

  const queryParams = new URLSearchParams(location.search);
  const extractedEmail = queryParams.get('email');

  const email = decodeURIComponent(extractedEmail);


  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(prevState => !prevState);
  };
  const onSubmit = (data) => {
    const { password, confirmPassword } = data;
    dispatch(resetPassword(email, password, confirmPassword, token, navigate))

  };

  return (
    <CommonLayout title="Loog" pageType="forgetPasswor">
      {loading && <Loader />}
      <HeaderSection text='Create New Password'
        text2='Your new password should be differently from your previously used passwords' />
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="password-input-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              maxLength={20}
              className={`form-input ${errors.password ? 'input-error' : ''}`}
              {...register('password', { onChange: handleNewPasswordChange })}
            />
            <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
              {showPassword ? <img src={hideIcon} alt='hide'/> : <img src={showIcon} alt='show'/>}
            </span>
          </div>
          {errors.password && <p className="error-message">{errors.password.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm Password
          </label>
          <div className="password-input-wrapper">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              className={`form-input ${errors.password ? 'input-error' : ''}`}
              {...register('confirmPassword')}
            />
            <span className="password-toggle-icon" onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <img src={hideIcon} alt='hide'/> : <img src={showIcon} alt='show'/>}
            </span>
          </div>
          {/* {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>} */}
          {errors.confirmPassword ? (
            <p className="error-message">{errors.confirmPassword.message}</p>
          ) : (
            password && confirmPassword && password === confirmPassword && (
              <p className="success-message" style={{ color: 'green', fontSize: "small" }}>Password matched</p>
            )
          )}
        </div>
        <button type="submit" className="submit-button">
          Continue
        </button>
      </form>
      <ExternalButton />
    </CommonLayout>)


}

export default UpdatePassword;
import { setLoading } from "../../slices/authSlice";
// import toast from "react-hot-toast";
import {toast} from 'react-toastify'
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";
import { getUserDetails } from "./ProfileAPI";

const { CREDTI_PLAN_API,CREDIT_BILLING_API ,PAYMENT_SUBSCRIBE,CREDIT_HISTORY_API,CANCEL_SUBSCRIPTION} = endpoints;


export function getCreditPlan(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET",CREDTI_PLAN_API , "", {'Authorization': `Bearer ${token}` });


            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response.message
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}


export function getCreditBillingHistory(token,page) {
    return async (dispatch) => {
       // dispatch(setLoading(true));
        try {
            const response = await apiConnector("GET",CREDIT_BILLING_API , "", {'Authorization': `Bearer ${token}` },{page:page});
            if (response?.status === 200) {
                //dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response.data;
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}

export function getCreditHistoryBilling(token,page) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("GET",CREDIT_HISTORY_API , "", {'Authorization': `Bearer ${token}` },{ page: page });


            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                return error.response.data;
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}


export function Subscribe(payload,token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", PAYMENT_SUBSCRIBE, payload, {'Authorization': `Bearer ${token}` });


            if (response?.status === 200) {
                dispatch(setLoading(false));
                return response;
            }
        } catch (error) {
            if (error?.response?.status === 500) {
                dispatch(setLoading(false))
                return error.response.data;
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}



export function unSubscribe(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const response = await apiConnector("POST", CANCEL_SUBSCRIPTION, "", {'Authorization': `Bearer ${token}` });


            if (response?.status === 200) {
                dispatch(setLoading(false));
                dispatch(getUserDetails(token));
                toast.success(response.data.message)
            }
        } catch (error) {
            if (error?.response?.status === 400) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 404) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 503) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            if (error?.response?.status === 500) {
                dispatch(setLoading(false))
                toast.error(error.response.data.message)
            }
            else {
                dispatch(setLoading(false))
            }
        }
    }
}
import CommonLayout from "./common/commonLayout";
import ExternalButton from './common/externalButton'
import reset from '../../assets/Frame 194.png'
import { useSelector } from "react-redux";

const ResetLink=()=>{


    return(
   <CommonLayout title='Logo'>
            <div className='reset'>
                <img  src={reset} alt='reset'/>
                <div>
                    <h3>Reset Link Sent</h3>
                    <p>A reset link has been sent to your registered email address. Please check your inbox and follow the instructions to reset your password.</p>
                </div>
            </div>
            <ExternalButton Other='Log in' text='Did not receive the mail' path='/login' />
        </CommonLayout>
    )
}

export default ResetLink;
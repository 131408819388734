const BASE_URL = process.env.REACT_APP_BASE_URL

// Auth endpoints
export const endpoints = {
  SIGNUP_API: BASE_URL + "api/register",
  LOGIN_API:BASE_URL+"api/login",
  EMAIL_RESEND:BASE_URL+"api/email/resend-verification",
  FORGET_PASSWORD:BASE_URL+"api/forgot-password",
  RESET_PASSWORD:BASE_URL+ "api/reset-password",
  LOGOUT_API:BASE_URL+"api/logout",
  UPLOAD_API:BASE_URL+"api/upload-resume",
  RESUMES_API:BASE_URL+"api/resumes-detail",
  PROFILE_API:BASE_URL+'api/update-profile',
  PROFILE_PICTURE_DELETE_API:BASE_URL+"api/delete-picture",
  UPDATE_PASSWORD_API:BASE_URL+"api/change-password",
  GET_USER_API:BASE_URL+"api/get-user",
  GET_RECRUITER_LIST_API:BASE_URL+"api/recruiters",
  GET_CANDIDATE_LIST_API:BASE_URL+"api/get-candidates",
  CREDTI_PLAN_API:BASE_URL+ "api/plan",
  CREDIT_BILLING_API:BASE_URL+"api/billing-history",
  CREDIT_HISTORY_API:BASE_URL+"api/credit-history",
  VIEW_RESUME_API:BASE_URL+"api/view-resume",
  GET_RESUMES_API:BASE_URL+"api/get-resumes",
  DOWNLOAD_API:BASE_URL+"api/download-resume",
  NOTIFICATION_LIST_API:BASE_URL+"api/notification",
  MARK_AS_READ_API:BASE_URL+"api/notifications-mark-as-read",
  PAYMENT_SUBSCRIBE:BASE_URL+"api/subscribe",
  CREATE_NOTES:BASE_URL+"api/add-notes",
  GET_NOTES:BASE_URL+"api/notes",
  GET_SKILL_LIST_API:BASE_URL+"api/get-skills",
  GET_COMPANIES_LIST_API:BASE_URL+"api/get-companies",
  GET_INDUSTRIES_LIST_API:BASE_URL+'api/get-industries',
  UPDATE_COMPANY:BASE_URL+"api/update-company-name",
  UPDATE_SKILL:BASE_URL+"api/update-skill-name",
  UPDATE_INDUSTRY:BASE_URL+"api/update-industry-name",
  DELETE_COMPANY:BASE_URL+"api/delete-company",
  DELETE_INDUSTRY:BASE_URL+"api/delete-industry",
  DELETE_SKILL:BASE_URL+"api/delete-skill",
  CREATE_PLAN:BASE_URL+"api/add-plan",
  GET_PLAN:BASE_URL+"api/plans",
  CHANGE_ROLE_API:BASE_URL+"api/change-role",
  STRIPE_CONFIG_API:BASE_URL+"api/add-stripeconfig",
  NEW_USER_CREATE:BASE_URL+"api/add-user",
  DASHBOARD_STATS_API:BASE_URL+"api/recruiter-dashboard-stats",
  ADMIN_DASHBOARD_STATS_API:BASE_URL+"api/dashboard-stats",
  GOOGLE_REGISTER:BASE_URL+"api/auth/social/google",
  GOOGLE_SIGNIN:BASE_URL+"api/auth/social/signin/google",
  LINKEDIN_REGISTER:BASE_URL+"api/auth/social/linkedin",
  LINKEDIN_SIGNIN:BASE_URL+"api/auth/social/signin/linkedin",
  GOOGLE_AD_CREATE:BASE_URL+"api/create-adsense",
  GOOGLE_AD_GET:BASE_URL+"api/get-adsense",
  GET_LOCATION:BASE_URL+"api/get-locations",
  CANCEL_SUBSCRIPTION:BASE_URL+"api/cancel-subscription",
  EMAIL_SEND_MARKETING:BASE_URL+"api/send-email",
  GET_UNIVERSAL_PLAN_API:BASE_URL+"api/get-plan",
  CONTACT_US:BASE_URL+"api/contact-us"
}

import '../../../styles/globalStyle.css'

const headerSection=({text,text2})=>{
    return(
        <div className='header-section'>
        <h3>{text}</h3>
        <p>{text2}</p>
      </div>
    )
}

export default headerSection;
import { useSelector } from "react-redux";
import CreditChart from "./creditChart";
import SearchChart from "./searchChart";
import SpentChart from "./spentChart";


const Chart = () => {
    const user=useSelector((state)=>state.profile.user)
    
    return (
        <div className='chart'>
            <div className='chart-container'>
                <div className='left-container'>
                    <SearchChart />
                </div>
                <div className='right-container'>
                    <div className='one-part' style={{
                        height: user?.is_premium===true ? "736px" : "524px"
                    }}>
                        <SpentChart />
                    </div>
                    {!user?.is_premium && (
                        <div className='second-part'>
                            <CreditChart />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Chart;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/globalStyle.css";
import { useForm } from "react-hook-form";
import SideBar from "../common/SideBar";
import filter from "../../../assets/filter-lines.png";
import search from "../../../assets/Group 33509.png";
import DataTable from "./Table";
import { getRecruiterList } from "../../../services/operations/AdminPageAPI";
import { getUserDetails } from "../../../services/operations/ProfileAPI";
import debounce from "lodash.debounce";
import CreateUser from "./CreateUser";

const Recruiters = () => {
    const { register } = useForm({});
    const [result, setResult] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
  const [showModal, setShowModal] = useState(false);
    const [sortConfig, setSortConfig] = useState({ sort_name: null, sort_order: null });
    const [premium,setPremium]=useState();
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 15,
        total_pages: 1,
        total_recruiters: 0,
    });




    const dispatch = useDispatch();
    
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.profile.user);

    const handleCloseModal = () => {
        setShowModal(false);
      };

      const handleOpenModal = () => {
        setShowModal(true);
      };


    const handleSort = (sort_name, sort_order) => {
        setSortConfig({ sort_name, sort_order });
    };
    const handlePageChange = (newPage) => {
        setPagination((prev) => ({ ...prev, current_page: newPage }));
    };


    useEffect(()=>{
        
      
    },[result])
    const fetchRecruiters = async () => {
        const response = await dispatch(getRecruiterList(token, searchQuery, sortConfig.sort_name, sortConfig.sort_order));
        if (response?.data?.data) {
            setResult(response.data.data);
        }
        if (response?.data?.pagination) {
            const totalPages = Math.ceil(
                response.data?.pagination?.total_recruiters / pagination.per_page
            );
            setPagination((prev) => ({
                ...prev,
                total_recruiters: response.data.pagination.total_recruiters,
                total_pages: totalPages,
            }));
        }
    };
    
    const currentPageData = result.slice(
        (pagination.current_page - 1) * pagination.per_page,
        pagination.current_page * pagination.per_page
    );

    const handleSearch = debounce((query) => {
        setSearchQuery(query);
        setPagination((prev) => ({ ...prev, current_page: 1 }));
    }, 300);

    const handleSearchInputChange = (event) => {
        const query = event.target.value.trim();
        handleSearch(query);
    };

    useEffect(() => {
        dispatch(getUserDetails(token));
    }, [dispatch, token]);

    useEffect(() => {
        fetchRecruiters();
    }, [searchQuery, sortConfig]);

    return (
        <div className="admin-dashboard-container">
            <SideBar
                user={user}
            />
            <div
                className="admin-dashboard-content"
            >
                <div className="admin-dashboard-heading">
                    <div className="recruiter-heading" style={{ marginBottom: "15px" }}>
                        <div className="resume-title">
                            <h5 style={{ fontSize: "24px", fontWeight: "600" }}>
                                Individual Users&nbsp;
                                {pagination?.total_recruiters ? (
                                    <span style={{ color: "#666666" }}>
                                        ({pagination.total_recruiters})
                                    </span>
                                ) : null}
                            </h5>
                        </div>
                        <div className="resume-controls" style={{gap:"15px"}}>
                        <button className="upload-btn" style={{width:"92px",height:"37px"}} onClick={handleOpenModal}>
                            <span>+ New User</span>
                        </button>
                            <div className="search-container-recruiter">
                                <img src={search} alt="Search" className="search-icon" />
                                <input
                                    type="text"
                                    id="search"
                                    placeholder="Recruiter's Name"
                                    className="search-bar"
                                    style={{ borderRadius: "8px" }}
                                    onChange={handleSearchInputChange} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className="table" style={{ margin: "0px" }}>
                        <DataTable
                            data={currentPageData}
                            premium={result}
                            pagination={pagination}
                            fetchRecruiters={fetchRecruiters}
                            onPageChange={handlePageChange}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                        />
                    </div>
                </div>
            </div>
            {showModal && <CreateUser onClose={handleCloseModal} isOpen={showModal} fetchRecruiters={fetchRecruiters}/>}
        </div>
    );
};

export default Recruiters;

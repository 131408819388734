import closeIcon from '../../../assets/Close.png';
import { useState, useEffect } from 'react';
import '../../../styles/globalStyle.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import hideIcon from '../../../assets/hideIcon.png';
import showIcon from '../../../assets/showIcon.png';
import { deleteProfilePicture, uploadProfile } from '../../../services/operations/ProfileAPI';
import ChangePassword from './changePassword';
import toast from 'react-hot-toast';
import { message } from '../../messages/Messages';

const AccountSetting = ({ isOpen, onClose }) => {
    const user = useSelector((state) => state.profile.user);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const MAX_FILE_SIZE = 2 * 1024 * 1024; 

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            Name: user.name,
            Email: user.email
        }
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewSource, setPreviewSource] = useState(null)
    const [isChanged, setIsChanged] = useState(true);
    const [isEmailVisible, setIsEmailVisible] = useState(false);
    const [isPassword, setIsPassword] = useState(false);

    const toggleEmailVisibility = () => {
        setIsEmailVisible(!isEmailVisible);
    };

    const maskEmail = (email) => {
        const [name, domain] = email.split('@');
        return `${name.slice(0, 2)}*****@${domain}`;
    };

    const handlePictureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                toast.error(message.profilePictureLimitMessage);
                return; 
            }
            setSelectedFile(file);
            previewFile(file);
        }
    };

    const previewFile = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            setPreviewSource(reader.result)
        }
    }
    useEffect(() => {
        if (selectedFile) {
            previewFile(selectedFile)
        }
    }, [selectedFile])


    const watchedName = watch("Name");

    useEffect(() => {
        if (watchedName !== user.name || selectedFile) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [watchedName, selectedFile, user.name]);

    const handleDeletePicture = () => {
        dispatch(deleteProfilePicture(token));
    };

    const handleOpenPasswordChange = () => {
        setIsPassword(true)
    }
    const handleClosePasswordChange = () => {
        setIsPassword(false);
    }

    const onSubmit = (data) => {
        const { Name } = data;
        const formData = new FormData()
        if (selectedFile) {
            formData.append("avatar", selectedFile);
        }
        
        formData.append('name', Name)
        dispatch(uploadProfile(token, formData, onClose))
    };

    return (
        <div className="account-overlay">
            <div className="account-content">
                <div className='account-heading'>
                    <h2>Account Settings</h2>
                    <img
                        src={closeIcon}
                        alt="Close"
                        className="close-icon"
                        onClick={onClose}
                    />
                </div>
                <form className="edit-user-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='profile-picture-heading'>Profile Picture</div>
                    <div className="image-section">
                        <img
                            src={previewSource || user?.avatar}
                            alt="preview"
                            className="profile-picture"
                        />

                        <div className="image-buttons">
                            <label className="change-picture-btn">
                                Change Picture
                                <input
                                    type="file"
                                    accept="image/png, image/gif, image/svg,image/jpeg"
                                    style={{ display: 'none' }}
                                    onChange={handlePictureChange}
                                />
                            </label>
                            <button
                                type="button"
                                className="delete-picture-btn"
                                onClick={handleDeletePicture}
                            >
                                Delete Picture
                            </button>
                        </div>
                    </div>
                    <div className="form-type">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            id="Name"
                            maxLength={20}
                            name="Name"
                            className="form-input-account"
                            {...register("Name", {
                                required: "Name is required",
                                pattern: {
                                    value: /^[A-Za-z\s]+$/,
                                    message: "Name must contain only alphabets and spaces"
                                }
                            })}
                        />
                         {errors.Name && <p className="error-message">{errors.Name.message}</p>}
                    </div>

                    <div className="form-type">
                        <label htmlFor="email">Email Id</label>
                        <input
                            type="text"
                            id="Email"
                            name="Email"
                            readOnly
                            className="form-input-account"
                            value={isEmailVisible ? user.email : maskEmail(user.email)}
                        />
                        <span className='password-show-email'>
                            <img
                                src={isEmailVisible ? hideIcon : showIcon}
                                alt={isEmailVisible ? 'Hide Email' : 'Show Email'}
                                className="toggle-icon"
                                onClick={toggleEmailVisibility}
                            />
                        </span>
                    </div>
                    <div className="form-type">
                        <label htmlFor="name">Password</label>
                    </div>
                    <button
                        type="button"
                        className="change-password-btn"
                        onClick={handleOpenPasswordChange}
                    >
                        Change Password
                    </button>
                    <button
                        type="submit"
                        className="save-button"
                        disabled={!isChanged}
                    >
                        Save Changes
                    </button>
                </form>
            </div>
            {isPassword && <ChangePassword onClose={handleClosePasswordChange} isOpen={handleOpenPasswordChange} />}
        </div>

    );
};

export default AccountSetting;

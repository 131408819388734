import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CommonLayout from './common/commonLayout';
import hideIcon from '../../assets/hideIcon.png'
import showIcon from '../../assets/showIcon.png'
import '../../styles/globalStyle.css';
import ExternalButton from './common/externalButton';
import HeaderSection from './common/headerSection';
import { useDispatch } from 'react-redux';
import { signUp } from '../../services/operations/authAPI';
import { useNavigate } from 'react-router-dom';
import { setSignupData } from '../../slices/authSlice';
import Loader from '../loader/Loader';
import { useSelector } from 'react-redux';
import { message } from '../messages/Messages';


const schema = yup.object().shape({
  name: yup.string().required(message.nameRequiredMessage).matches(/^[A-Za-z\s]+$/,message.nameMatchMessage),
  email: yup.string().email(message.invalidEmailMessage)
  .required(message.emailRequiredMessage)
  .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, message.invalidEmailMessage),
  password: yup.string()
  .required(message.passwordRequiredMessage)
  .min(8, message.passwordMinMessage)
  .matches(
    /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w])[A-Za-z\d\W_]{8,}$/,
    message.passwordMatchMessage
  ),



});

const Registration = () => {
  const { register, handleSubmit, formState: { errors },reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [showPassword, setShowPassword] = useState(false);
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.auth.loading)

  const onSubmit = (data,e) => {
    e.preventDefault();
    dispatch(setSignupData(data))
    const {name,email,password}=data;
   dispatch(signUp(name,email,password,navigate))
    reset();
  };

  return (
    <CommonLayout title="Logo" pageType="signup">
      {loading&& <Loader/>}
      <HeaderSection text='Welcome to 1800Hired.com'
        text2='Experience hiring the AI way. Start with 1800Hired today!' />
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <div className="form-group">
          <label htmlFor="name" className="form-label">
            Name
          </label>
          <input
            type="text"
            id="name"
            maxLength={30}
            placeholder='Enter name'
            className={`form-input ${errors.name ? 'input-error' : ''}`}
            {...register('name')}
          />
          {errors.name && <p className="error-message">{errors.name.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email Id
          </label>
          <input
            type="text"
            id="email"
            minLength={5}
            maxLength={60}
            placeholder='Enter your email'
            className={`form-input ${errors.email ? 'input-error' : ''}`}
            {...register('email')}
          />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="password-input-wrapper">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              maxLength={20}
              placeholder='Enter password'
              className={`form-input ${errors.password ? 'input-error' : ''}`}
              {...register('password')}
            />
            <span className="password-toggle-icon" onClick={()=>setShowPassword(!showPassword)}>
              {showPassword ? <img src={hideIcon} alt='hide'/> : <img src={showIcon} alt='show'/>}
            </span>
          </div>
          {errors.password && <p className="error-message">{errors.password.message}</p>}
        </div>
        <button type="submit" className="submit-button">
          Register
        </button>
      </form>
      <ExternalButton googleText='Register with Google' type='register' linkedinText='Register with Linkedin' Other='Log in' text='Already have an account' path='/login' />
    </CommonLayout>
  );
};

export default Registration;

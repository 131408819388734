import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import github from '../../../assets/github.png'
import linkedin from '../../../assets/linkedin.png'
import doenlaod from '../../../assets/download.png'
import closeIcon from '../../../assets/Close.png'
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../loader/Loader';
import { createNote, downloadResume, getNotes, viewResume } from '../../../services/operations/ResumesAPI';
import phone from "../../../assets/icons8-phone-20.png";
import locations from '../../../assets/icons8-location-20.png'
import email from '../../../assets/icons8-email-20.png'
import noteIcon from '../../../assets/add-note-svgrepo-com.svg'
import { saveAs } from 'file-saver';
import residential from '../../../assets/residential.png'
import SideBar from '../common/SideBar';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';

const CandidateDetailPage = () => {

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const location = useLocation();
  const [pdfUrl, setPdfUrl] = useState('');
  const [fomrattedPdf, setFormattedPdf] = useState('');
  const [notes, setNotes] = useState("");
  const [notesList, setNotesList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState(null);
  const [detail, setDetail] = useState([]);
  const [highlightSkill, setHighlightSkill] = useState([]);
  const [highlightCompany, setHighlightCompany] = useState([]);
  const [highlightIndustry, setHighlightIndustry] = useState([]);
  const [highlightLocation, setHighlightLocation] = useState([]);
  const [showAllSkills, setShowAllSkills] = useState(false);
  const [fileType, setFileType] = useState('');
  const [notesLoader, setNoteLoader] = useState(false);
  const [notesError, setNotesError] = useState("");
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [keywords, setKeywords] = useState([]);
  const [documentLoaded1, setDocumentLoaded1] = useState(false);
  const [documentLoaded2, setDocumentLoaded2] = useState(false);
  const dispatch = useDispatch()
  const queryParams = new URLSearchParams(location.search);
  const encodedData = queryParams.get('data');

  const loading = useSelector((state) => state.auth.loading)
  const token = useSelector((state) => state.auth.token)
  const user = useSelector((state) => state.profile.user)
  const { user_credits } = user;
  const modalRef = useRef(null);
  const iconRef = useRef(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const searchPluginInstance = searchPlugin({
    renderHighlights: (props) => (
      <>
        {props.highlightAreas.map((area, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 0, 0.412)',
              opacity: 1.5,
              left: `${area.left}%`,
              top: `${area.top}%`,
              height: `${area.height}%`,
              width: `${area.width}%`,
            }}
          />
        ))}
      </>
    ),
  });
  const searchPluginInstance2 = searchPlugin({
    renderHighlights: (props) => (
      <>
        {props.highlightAreas.map((area, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              backgroundColor: 'rgba(255, 255, 0, 0.412)',
              opacity: 1.5,
              left: `${area.left}%`,
              top: `${area.top}%`,
              height: `${area.height}%`,
              width: `${area.width}%`,
            }}
          />
        ))}
      </>
    ),
  });

  const { highlight :highlight1} = searchPluginInstance;
  const { highlight: highlight2 } = searchPluginInstance2;




  useEffect(() => {
    if (detail?.Name) {
      document.title = `${detail.Name} - Candidate Details`;
    }
    return () => {
      document.title = 'AI Powered Candidate Search Engine - ResumeLake';
    };
  }, [detail?.Name]);

  useEffect(() => {
    if (data && data.id) {
      fetchDetails();
      getNoteList()
    }
  }, [data]);


  useEffect(() => {
    const decodedData = JSON.parse(decodeURIComponent(atob(encodedData)));
    setData(decodedData);
    setHighlightSkill(decodedData?.highlightskill)
    setHighlightCompany(decodedData?.highlightCompany)
    setHighlightIndustry(decodedData?.highlightIndustry)
    setHighlightLocation(decodedData?.highlightLocation)
    setKeywords([
      ...(decodedData?.highlightskill || []),
      ...(decodedData?.highlightCompany || []),
      ...(decodedData?.highlightIndustry || []),
       ...(decodedData?.highlightLocation || [])
    ]);
  }, [encodedData]);


    useEffect(() => {
      if (documentLoaded1 && keywords) {
        highlight1(keywords);
      }
  }, [documentLoaded1, keywords]);

  useEffect(() => {
    if (documentLoaded2 && keywords) {
      highlight2(keywords);
    }
}, [documentLoaded2, keywords]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !iconRef.current.contains(event.target)) {
        setShowPopup(false); // Close modal when clicked outside
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchResumes = () => {
  }

  const fetchDetails = async () => {

    const payload = {
      resume_id: data.id
    }
    const response = await dispatch(viewResume(token, payload))
    const result = response?.data?.resume
    setDetail(result)
    setFormattedPdf(result?.formatted_resume)
    setPdfUrl(result?.upload_resume)
  }


  const handleAddNote = async () => {

    if (!notes.trim()) {
      setNotesError("This field is required.");
      return;
    }

    setNoteLoader(true)
    const payload = {
      resume_id: data.id,
      note: notes
    }
    await dispatch(createNote(token, payload))
    setNotes('');
    getNoteList()
    setNotesError("")
    setNoteLoader(false);
    setShowPopup(false);

  };


  const getNoteList = async () => {
    const response = await dispatch(getNotes(token, data?.id))
    const result = response?.data?.data;
    setNotesList(result)
  }

  const handleIconClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  const handleOwnDownload = async () => {
    const payload = {
      resume_id: data?.id,
    };

    try {
      const response = await dispatch(downloadResume(token, payload));

      if (response?.data?.originalUrl) {
        const byteCharacters = atob(response.data.originalUrl);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byteArray
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        // Create a download link
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${detail?.Name}-original.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        console.error("Formatted URL is missing in the response");
      }
    } catch (error) {
      console.error("Error during file download:", error);
    }

  }

  const handleDownload = async () => {
    const payload = {
      resume_id: data?.id,
    };

    try {
      const response = await dispatch(downloadResume(token, payload));

      if (response?.data?.formattedUrl) {
        const byteCharacters = atob(response.data.formattedUrl);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the byteArray
        const blob = new Blob([byteArray], { type: 'application/pdf' });


        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${detail?.Name}-formatted.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        console.error("Formatted URL is missing in the response");
      }
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };


  const allSkills = detail?.All_Skills?.split(",").map((skill) => skill.trim()) || [];
  const highlightedSkills = allSkills.filter((skill) =>
    highlightSkill?.some((highlightedSkill) =>
      skill.toLowerCase().includes(highlightedSkill.trim().toLowerCase())
    )
  );
  const remainingSkills = allSkills.filter((skill) => !highlightedSkills.includes(skill));
  const displayedSkills = showAllSkills ? allSkills : [...highlightedSkills, ...remainingSkills.slice(0, 10 - highlightedSkills.length)];
  

  return (
    <div className="admin-dashboard-container">
      <SideBar user={user} />
      <div className="admin-dashboard-content" style={{ padding: "0px" }}>
        <div className='notes-section'>
          <img src={noteIcon} alt='note' ref={iconRef} className='notes-icon' style={{ "cursor": "pointer" }} width={30} height={30}
            onClick={handleIconClick}
          />
        </div>
        {showPopup && (
          <div className='chat-container' ref={modalRef}>
            <div className='chat-content'>
              <h3>Add Notes</h3>
              <div className="form-type" style={{ width: "100%" }}>
                <textarea
                  className="chat-input"
                  style={{ width: "100%" }}
                  placeholder="Type your note here..."
                  value={notes}
                  disabled={notesLoader}
                  onChange={(e) => setNotes(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddNote();
                    }
                  }}
                />
                {notesError && <p className="error-message">{notesError}</p>}
              </div>
              <button className='note-save-btn' disabled={notesLoader} onClick={handleAddNote}>
                {notesLoader ? "Saving.." : "Save"}
              </button>
            </div>
          </div>
        )}


        <div className='candidate-profile'>
          <div className='detail-heading'>
            <p style={{ fontWeight: 700, fontSize: "22px" }}>{detail?.Name}</p>
            <p style={{ color: "grey", fontWeight: 500, fontSize: "17px" }}>{data?.designation}</p>
            <p>{data?.Current_Company}</p>
            <p style={{ fontSize: "15px", fontWeight: "normal" }}>{detail?.Profession_Summary}</p>
          </div>
          <div className='section'>
            <div className='left-section'>
              <div className='left-section-one'>
                <h4 class="section-heading">About Me</h4>
                <div className='detail-with'>
                  <p className='headings'>Name :</p>
                  <p className='desc'> {detail?.Name}</p>
                </div>
                <div className='detail-with'>
                  <p className='headings'>Years of Experience :</p>
                  <p className='desc'>{data?.experience} year(s)</p>
                </div>
                <div className='detail-with'>
                  <p className='headings'>Email Address :</p>
                  <p className='desc'>{detail?.Email}</p>
                </div>
                <div className="detail-with">
                <p className="headings">Location :</p>
                <p className={`desc ${highlightLocation?.some((highlightedLoc) =>
                  data?.location?.toLowerCase() === highlightedLoc.trim().toLowerCase()
                ) ? "highlight" : ""}`}>
                  {data?.location}
                </p>
              </div>
                <div className='detail-with'>
                  <p className='headings'>Phone Number :</p>
                  <p className='desc'>{detail?.Phone}</p>

                </div>
              </div>
              <div className="left-section-one">
                <h4 className="section-heading">Social Profile</h4>
                <div className="detail-with-right">
                  <p className="social-detail">
                    <img src={linkedin} alt='linkedin' className="detail-image" />
                    {detail?.LinkedIn && detail?.LinkedIn != "N/A" ? (
                      <a
                        href={
                          detail.LinkedIn.includes("linkedin.com")
                            ? detail.LinkedIn.startsWith("http")
                              ? detail.LinkedIn
                              : `https://${detail.LinkedIn}`
                            : null
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        {detail?.LinkedIn}
                      </a>
                    ) : (
                      <span>No LinkedIn profile</span>
                    )}
                  </p>
                  <p className="social-detail">
                    <img src={github} className="detail-image" alt='github' style={{ backgroundColor: "white" }} />

                    {detail?.Github && detail?.Github != "N/A" ? (
                      <a
                        href={
                          detail.Github.includes("linkedin.com")
                            ? detail.Github.startsWith("http")
                              ? detail.Github
                              : `https://${detail.Github}`
                            : null
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        {detail?.Github}
                      </a>
                    ) : (
                      <span>No Github profile</span>
                    )}
                  </p>
                </div>
              </div>
              <div className="left-section-one">
                <h4 class="section-heading">Skills</h4>
                <div className="skills-list">
                  {displayedSkills.map((skill, index) => {
                    const isHighlighted = highlightSkill?.some((highlightedSkill) =>
                      skill.toLowerCase().includes(highlightedSkill.trim().toLowerCase())
                    );
                    return (
                      <div key={index} className="list-skill">
                        <span className={`skill-item ${isHighlighted ? "highlight" : ""}`}>{skill}</span>
                      </div>
                    );
                  })}
                  {allSkills.length > 15 && (
                    <div className="see-more" onClick={() => setShowAllSkills((prev) => !prev)}>
                      {showAllSkills ? "Show Less" : `See all ${allSkills.length - displayedSkills.length} skills`}
                    </div>
                  )}
                </div>

              </div>
              {/* {detail?.Projects?.length > 0 &&
              <div className='left-section-one'>
                <h4>Projects</h4>
                {detail.Projects.map((project, index) => (
                  <div className='detail-with-project' key={index}>
                    <p className='headings'>{project?.project_name}</p>
                    <p><span className='headings'>Details:</span> {project?.project_details}</p>
                  </div>
                ))}
              </div>
            } */}
            </div>
            <div className='right-section'>
              {
                detail?.Work_Experience?.length > 0 &&
                <div className='right-section-one'>
                  <h4 class="section-heading">Work History</h4>
                  {detail?.Work_Experience?.map((experience, index) => {
                    const isHighlighted = highlightCompany?.some((highlightedCompany) =>
                      experience?.company?.toLowerCase().includes(highlightedCompany.trim().toLowerCase())
                    );
                    return (
                      <div className='work-industry' key={index}>
                        <div className='work-industry-company'>

                          <img src={residential} alt='residential' width={18} height={18} style={{ backgroundColor: "lightblue", borderRadius: "24px" }} />
                          <div>

                            <p
                              style={{ fontSize: "15px" }}
                              className={`headings ${isHighlighted ? "highlight" : ""}`}
                            >
                              {experience.company}
                            </p>
                            <p style={{ color: "greyBlack", fontSize: "15px" }}>{experience.designation}</p>
                            <p style={{ color: "grey", fontSize: "15px", marginTop: "3px" }}>{experience.duration}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                </div>
              }
              {
                detail?.industry_name !== "N/A" && (
                  <div className="right-section-one">
                    <h4 className="section-heading">Industry</h4>
                    <div className="detail-with-right-industry">
                      {detail?.industry_name?.split(", ").map((industry, index) => (
                        <span
                          key={index}
                          className={
                            highlightIndustry?.some((highlight) =>
                              industry.toLowerCase().includes(highlight.toLowerCase())
                            )
                              ? "highlight"
                              : ""
                          }
                        >
                          {industry}
                          {index < detail?.industry_name.split(", ").length - 1 && ", "}
                        </span>
                      ))}
                    </div>
                  </div>
                )
              }
              <div className='right-section-one'>
                <h4 class="section-heading">Your Captured Notes</h4>
                <div className="chat-box custom-scrollbar">
                  {notesList?.length > 0 ? (
                    notesList.map((note, index) => (

                      <div
                        key={index}
                        className="sticky-notes-grid"
                      >
                        <div key={index} className="sticky-note">
                          <p className="note-text-section">{note.note}</p>
                          <p className="note-text">{note.dateTime}</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p style={{ color: "gray" }}>No notes added yet.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="section">
            {fomrattedPdf && (
               <div className="pdf-section">
               <div className="pdf-scroll">
               <div
                 className="pdf-container custom-scrollbar"
               ><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
               <Viewer
                 fileUrl={fomrattedPdf}
                 onDocumentLoad={() => setDocumentLoaded1(true)}
                 plugins={[searchPluginInstance]}
                 theme="light"
                 defaultScale={1}
               />
             </Worker>
             </div>
                 </div>
               <button className="download-button" onClick={handleDownload}>
                 <span>
                   <img src={doenlaod} alt="download" /> Download
                 </span>
               </button>
             </div>
            )}
            {
              pdfUrl && (
                <div className="pdf-section">
                <div className="pdf-scroll">
                <div
                  className="pdf-container custom-scrollbar"
                ><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdfUrl}
                  plugins={[searchPluginInstance2]}
                  theme="light"
                  defaultScale={1}
                  onDocumentLoad={() => setDocumentLoaded2(true)}
                />
              </Worker>
              </div>
                  </div>
                <button className="download-button" onClick={handleOwnDownload}>
                  <span>
                    <img src={doenlaod} alt="download" /> Download
                  </span>
                </button>
              </div>
              )}
          </div>

        </div>
      </div>
    </div>

  );
};

export default CandidateDetailPage;

import coin from '../../../assets/Crypto Coins.png';
import { useSelector } from "react-redux";

const CreditChart = () => {
    const user = useSelector((state) => state.profile.user);
    const { user_credits } = user;

    const totalCredits = 10; 
    const remainingResumes = user_credits?.remaining_resumes;
    const uploaded = totalCredits - remainingResumes;
    const progress = totalCredits > 0 
        ? ((remainingResumes) / totalCredits) * 100 
        : 0;

    return (
        <div className="credit-chart">
            <div className="one">
                <div className="heading">
                    <h3>Credits</h3>
                    <p>
                        <span>
                            <img src={coin} alt="coin" style={{ marginRight: "4px" }} />
                        </span>
                        {`${(user?.user_credits?.credits) + (user?.user_credits?.daily_credit)} credits Remaining`}
                    </p>
                </div>
                <div className="heading-desc">
                    {
                    (uploaded < 5) ? <p className="heading-desc-first">You’re almost there!</p> : <p className="heading-desc-first">Let's contribute more!</p>}
                    <p className="heading-desc-second">
                        {`Upload ${totalCredits-remainingResumes} more resumes to get your next credit.` }
                    </p>
                </div>
            </div>
            <div className="second">
                <div className="progress-bar-container">
                    <div className="progress-bar">
                        <div
                            className="progress-bar-fill"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <p>{`${remainingResumes}/${totalCredits} resumes uploaded`}</p>
                </div>
            </div>
        </div>
    );
};

export default CreditChart;

import Footer from "./Footer";
import HomeNav from "./NavBar";



const ComingSoon=()=>{
    return(
        <>
        <HomeNav />  
         <section className="contact-section">
        <div className="contact-container">
        <h2 className="contact-heading">Coming Soon...</h2>
      </div>
    </section>
        <Footer/>
        </>

    )
}

export default ComingSoon;
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardViewDownloadCount } from '../../../services/operations/ProfileAPI';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SearchChart = () => {
    const [filter, setFilter] = useState('Month');
    const [chartData, setChartData] = useState(null);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);

    const getDashboardViewDownloadCount = async () => {
        const response = await dispatch(dashboardViewDownloadCount(token));
        if (response?.data?.data) {
            setChartData(response.data.data);
        }
    };

    useEffect(() => {
        getDashboardViewDownloadCount();
    }, []);

    const getMonthShortNames = () => [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];

    const mapMonthlyStats = (monthlyStats) => {
        const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthKeys = Object.keys(monthlyStats);

        const views = [];
        const downloads = [];

        shortMonths.forEach((shortMonth, index) => {
            const key = monthKeys.find((k) => k.startsWith(shortMonth) || k === `${index + 1}`.padStart(2, '0'));
            views.push(monthlyStats[key]?.view || 0);
            downloads.push(monthlyStats[key]?.download || 0);
        });

        return { views, downloads };
    };

    const getDataByFilter = (filter) => {
        if (!chartData) return null;

        let views = [];
        let downloads = [];
        let labels = [];

        switch (filter) {
            case 'Week': {
                const weeklyStats = chartData.weekly_stats;
                labels = Object.keys(weeklyStats);
                views = labels.map((date) => weeklyStats[date]?.view || 0);
                downloads = labels.map((date) => weeklyStats[date]?.download || 0);
                break;
            }
            case 'Month': {
                const { views: monthlyViews, downloads: monthlyDownloads } = mapMonthlyStats(chartData.monthly_stats);
                labels = getMonthShortNames();
                views = monthlyViews;
                downloads = monthlyDownloads;
                break;
            }
            case 'Year': {
                const yearlyStats = chartData.yearly_stats;
                labels = Object.keys(yearlyStats);
                views = labels.map((year) => yearlyStats[year]?.view || 0);
                downloads = labels.map((year) => yearlyStats[year]?.download || 0);
                break;
            }
            default:
                return null;
        }

        const maxValue = Math.max(...views, ...downloads);
        const yMax = maxValue <= 10
            ? 10
            : Math.ceil(maxValue / 100) * 100;

        return {
            labels,
            datasets: [
                {
                    label: 'Total views',
                    data: views,
                    backgroundColor: '#b572ab',
                    borderWidth: 0,
                    borderRadius: 4,
                    barThickness: 14,
                },
                {
                    label: 'Total Downloads',
                    data: downloads,
                    backgroundColor: '#6a5b7a',
                    borderWidth: 0,
                    borderRadius: 4,
                    barThickness: 14,
                },
            ],
            yMax,
        };
    };

    const options = (yMax) => ({
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 25,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                    },
                    title: function () {
                        return '';
                    },
                },
                backgroundColor: 'black',
                titleColor: 'white',
                displayColors: false,
                padding: 13,
                bodyColor: 'white',
                bodyFont: {
                    size: '14px',
                    weight: '300',
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#999999',
                    font: {
                        size: '12px',
                    },
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    stepSize: yMax / 10,
                    color: '#999999',
                    font: {
                        size: '12px',
                    },
                    drawTicks: false,
                },
                max: yMax,
                border: {
                    display: false,
                },
            },
        },
    });
    return (
        <div className="searchMetrics">
            <div className="searchTitle">
                <div className="searchgraphtitle">Views/Downloads</div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '10px',
                                height: '10px',
                                backgroundColor: '#b572ab',
                                marginRight: '5px',
                            }}
                        ></span>
                        <span style={{ fontWeight: '400', fontSize: '12px' }}>Total Views</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                            style={{
                                display: 'inline-block',
                                width: '10px',
                                height: '10px',
                                backgroundColor: '#6a5b7a',
                                marginRight: '5px',
                            }}
                        ></span>
                        <span style={{ fontWeight: '400', fontSize: '12px' }}>Total Downloads</span>
                    </div>
                </div>
            </div>
            <div className="searchFilter">
                {['Week', 'Month', 'Year'].map((tab) => (
                    <div
                        className="filterStyle"
                        key={tab}
                        onClick={() => setFilter(tab)}
                        style={{
                            backgroundColor: filter === tab ? '#E6E9F2' : 'white',
                        }}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            {chartData && (
                <Bar
                    data={getDataByFilter(filter)}
                    options={options(getDataByFilter(filter)?.yMax || 300)} 
                    width={'800px'}
                    height={'600px'}
                />
            )}
        </div>
    );
};

export default SearchChart;

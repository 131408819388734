import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ requiredRole ,children}) => {
  const { token } = useSelector((state) => state.auth);
  const user = useSelector((state) => state.profile.user);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (user && user.roles) {
    const userRole = user.roles[0].name;
    if (userRole === requiredRole) {
      return children;
    } else {
      return <Navigate to="/not-authorized" />;
    }
  }

};

export default PrivateRoute;

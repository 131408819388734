import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import BillingTable from "./billingTable";
import close from '../../../assets/Close.png'
import visaIon from '../../../assets/visaIcon.png'
import masterIcon from '../../../assets/masterIcon.png'
import americanExpressIcon from '../../../assets/american-express.png'
import jcbIcon from '../../../assets/jcb.png'
import discoverIcon from '../../../assets/4373195_card_credit_discover_logo_logos_icon.png'
import unknownIcon from '../../../assets/unknown-card.png'
import { unSubscribe } from "../../../services/operations/CreditAPI";
import Loader from "../../loader/Loader";

const PremiumPlan = ({ plans, loadData,selectedPlan, setSelectedPlan, data, pagination, handlePageChange }) => {

    const user = useSelector((state) => state.profile.user)
    const token=useSelector((state)=>state.auth.token)
    const loading=useSelector((state)=>state.auth.loading)
    const card=useSelector((state)=>state.card.card)
    const dispatch=useDispatch()


    const cancelSubscription=async()=>{
        await dispatch(unSubscribe(token))

    }


    const getCardBrandIcon = (brand) => {
        switch (brand) {
          case "visa":
            return <img src={visaIon} alt="Visa" className="card-icon" />;
          case "mastercard":
            return <img src={masterIcon} alt="masterCard" className="card-icon" />;
          case "amex":
            return <img src={americanExpressIcon} width={30} height={20} alt="americanExpress" className="card-icon" />;
          case "discover":
            return <img src={discoverIcon} style={{ top: "0px" }} width={20} height={20} alt="discover" className="card-icon" />;
          case "jcb":
            return <img src={jcbIcon} style={{ top: "-5px" }} width={50} height={40} alt="jcb" className="card-icon" />;
          default:
            return <img src={unknownIcon}  width={40} height={40} alt="unknown" className="card-brand-icon" />;;
        }
      };
    return (
        <>
            <div className='credit-section'>
                <div className="credits" >
                    <div className='uppar'>
                        <h3>Payments</h3>
                    </div>
                    <div className='lower'>
                        <div className="left-credit">
                            <div className='premium-plan'>
                                {plans?.length > 0 && (
                                    plans.map((plan) => (
                                        <>
                                            <div className='premium-desc'>
                                                <p>You are using <span className="premium-text">1.800.HIRED premium</span>,Your next payment date is {user?.subscription_end_date}.</p>
                                            </div>
                                            <div className='premium-cancel'>
                                                <span className='premium-cancel-button' onClick={cancelSubscription}>Cancel your subscription</span>
                                            </div>
                                        </>
                                    ))
                                )}
                            </div>

                            <div className='payment-history'>
                                <p>Billing History</p>
                                <div className='table-billing'>
                                    <BillingTable
                                        data={data}
                                        pagination={pagination}
                                        onPageChange={handlePageChange}
                                        loadData={loadData}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='right-section-credit'>
                        <div className='right-credit-left'>
                            <div className='payment-heading'>
                                <span>Your next payment summary</span>
                            </div>
                            <div className="payment-summary">
                                <div className='payment-list'>
                                    <span>Price($)</span>
                                    {selectedPlan && <p>{selectedPlan?.amount}</p>}
                                </div>
                                {
                                    selectedPlan?.tax_percentage!=="0.00" &&
                                     <div className='payment-list'>
                                     <span>Tax({selectedPlan?.tax_percentage}%)</span>
                                     {selectedPlan &&<p>{selectedPlan?.tax_amount}</p>}
                                 </div>
                                 }
                                <hr />
                                <div className='payment-list'>
                                    <span>Total($)</span>
                                    {selectedPlan && <p>{selectedPlan?.total_amount}</p>}

                                </div>
                            </div>
                            <hr />
                            </div>
                            <div className="right-credit-right">
                            <div className='saved-cards'>
                                <div className="card-add-new"><h4>Your Saved Cards</h4></div>
                                {
                                    card && (
                                        <div className="card-desc">
                                                <p className='card-desc-icon'> {getCardBrandIcon(card.brand)}<span>************{card.last4}</span></p>
                                                <p className="card-desc-default">Default</p>
                                                
                                            </div>
                                    )
                                } 
                            </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PremiumPlan;
import { setLoading,  setToken } from "../../slices/authSlice";
import { setUser } from '../../slices/profileSlice'
//import toast from "react-hot-toast";
import {toast} from 'react-toastify'
import { message } from "../../components/messages/Messages";
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";
import axios from "axios";
const { SIGNUP_API, LOGIN_API, EMAIL_RESEND, FORGET_PASSWORD, RESET_PASSWORD, LOGOUT_API,GOOGLE_REGISTER,GOOGLE_SIGNIN,GET_UNIVERSAL_PLAN_API,CONTACT_US,LINKEDIN_REGISTER,LINKEDIN_SIGNIN} = endpoints;



export function googleRegister(
  payload,
  navigate
) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", GOOGLE_REGISTER,payload)
      if (response?.status === 200) {
        dispatch(setToken(response.data.access_token))
        const userImage = response.data?.user?.avatar
        ? response.data.user.avatar
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
        dispatch(setUser({...response.data.user,avatar:userImage}))
        sessionStorage.setItem("token", JSON.stringify(response.data.access_token))
        sessionStorage.setItem("user", JSON.stringify({...response.data.user,avatar:userImage}))
        dispatch(setLoading(false))
        toast.success(message.loginSuccess)
        if(response?.data?.user?.roles[0]?.name==='superadmin'){
          navigate('/admin-dashboard')
        }
        if(response?.data?.user.roles[0]?.name==='recruiter'){
          navigate('/dashboard')
        }
        if(response?.data?.user?.roles[0]?.name==='marketing'){
          navigate('/marketing-dashboard')
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 400) {
        toast.error(error.response.data.message);
      }
      
      if (error?.response?.data?.status === 500) {
        toast.error(error.response.data.message);
      }
    }
  }
}


export function googleSignIn(
  payload,
  navigate
) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST",GOOGLE_SIGNIN ,payload)
      if (response?.status === 200) {
        dispatch(setToken(response.data.access_token))
        const userImage = response.data?.user?.avatar
        ? response.data.user.avatar
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
        dispatch(setUser({...response.data.user,avatar:userImage}))
        sessionStorage.setItem("token", JSON.stringify(response.data.access_token))
        sessionStorage.setItem("user", JSON.stringify({...response.data.user,avatar:userImage}))
        dispatch(setLoading(false))
        toast.success(message.loginSuccess)
        if(response?.data?.user?.roles[0]?.name==='superadmin'){
          navigate('/admin-dashboard')
        }
        if(response?.data?.user.roles[0]?.name==='recruiter'){
          navigate('/dashboard')
        }
        if(response?.data?.user?.roles[0]?.name==='marketing'){
          navigate('/marketing-dashboard')
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 404) {
        toast.error(error.response.data.message);
      }
      if (error?.response?.data?.status === 500) {
        toast.error(error.response.data.message);
      }
    }
  }
}

export function linkedinRegister(
  code,
  navigate,
  baseUrl
) {
  return async (dispatch) => {
    dispatch(setLoading(true))

    const redirectUri=`${baseUrl}/linkedin-callback`
    const payload = {
      code: code,
      redirect_uri: redirectUri
    };
    try {
      const response = await apiConnector("POST", LINKEDIN_REGISTER, payload)
      if (response?.status === 200) {
        dispatch(setToken(response.data.access_token))
        const userImage = response.data?.user?.avatar
          ? response.data.user.avatar
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
        dispatch(setUser({ ...response.data.user, avatar: userImage }))
        sessionStorage.setItem("token", JSON.stringify(response.data.access_token))
        sessionStorage.setItem("user", JSON.stringify({ ...response.data.user, avatar: userImage }))
        dispatch(setLoading(false))
        toast.success(message.loginSuccess)
        if (response?.data?.user?.roles[0]?.name === 'superadmin') {
          navigate('/admin-dashboard')
        }
        if (response?.data?.user.roles[0]?.name === 'recruiter') {
          navigate('/dashboard')
        }
        if (response?.data?.user?.roles[0]?.name === 'marketing') {
          navigate('/marketing-dashboard')
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 400) {
        toast.error(error.response.data.message);
      }
      if (error.response.data.status === 500) {
        toast.error(error.response.data.message)
        navigate("/register");
      }
    }
  }
}

export function linkedinLogin(
  code,
  navigate,
  baseUrl
) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    const redirectUri=`${baseUrl}/linkedin-callback`
    const payload = {
      code: code,
      redirect_uri: redirectUri
    };
    try {
      const response = await apiConnector("POST", LINKEDIN_SIGNIN, payload)
      if (response?.status === 200) {
        dispatch(setToken(response.data.access_token))
        const userImage = response.data?.user?.avatar
          ? response.data.user.avatar
          : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
        dispatch(setUser({ ...response.data.user, avatar: userImage }))
        sessionStorage.setItem("token", JSON.stringify(response.data.access_token))
        sessionStorage.setItem("user", JSON.stringify({ ...response.data.user, avatar: userImage }))
        dispatch(setLoading(false))
        toast.success(message.loginSuccess)
        if (response?.data?.user?.roles[0]?.name === 'superadmin') {
          navigate('/admin-dashboard')
        }
        if (response?.data?.user.roles[0]?.name === 'recruiter') {
          navigate('/dashboard')
        }
        if (response?.data?.user?.roles[0]?.name === 'marketing') {
          navigate('/marketing-dashboard')
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 400) {
        toast.error(error.response.data.message);
      }
      if (error.response.data.status === 500) {
        toast.error(error.response.data.message)
        navigate("/login");
      }
    }
  }
}
export function signUp(
  name,
  email,
  password,
  navigate
) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", SIGNUP_API, {
        name,
        email,
        password
      })
      if (response?.status === 201) {
        dispatch(setLoading(false))
        navigate("/success")
      }
    } catch (error) {
      dispatch(setLoading(false));
      if (error?.response?.data?.status === 422) {
        toast.error(error.response.data.error.email[0]);
      }
      if (error?.status === 500) {
        toast.error(message.errorMessage);
      }
      else{
        dispatch(setLoading(false))
        toast.error(message.errorMessage)
      }
    }
  }
}

export function login(email, password, navigate, reset) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", LOGIN_API, {
        email,
        password,
      })

      if (response?.status === 200) {
        dispatch(setToken(response.data.access_token))
        const userImage = response.data?.user?.avatar
        ? response.data.user.avatar
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.name} `
        dispatch(setUser({...response.data.user,avatar:userImage}))
        sessionStorage.setItem("token", JSON.stringify(response.data.access_token))
        sessionStorage.setItem("user", JSON.stringify({...response.data.user,avatar:userImage}))
        dispatch(setLoading(false))
        toast.success(message.loginSuccess)
        if(response?.data?.user?.roles[0]?.name==='superadmin'){
          navigate('/admin-dashboard')
        }
        if(response?.data?.user.roles[0]?.name==='recruiter'){
          navigate('/dashboard')
        }
        if(response?.data?.user?.roles[0]?.name==='marketing'){
          navigate('/marketing-dashboard')
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
        reset()
      }
      else if (error?.response?.status === 401) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else if (error?.response?.status === 429) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else {
        dispatch(setLoading(false))
        toast.error(message.errorMessage)
      }
    }
  }
}

export function logout(token,navigate) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", LOGOUT_API, '', { 'Authorization': `Bearer ${token}` })


      if (response?.status === 200) {
        dispatch(setLoading(false))
        dispatch(setToken(null))
        dispatch(setUser(null))
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("user")
        toast.success(response.data.message)
        
        navigate("/login")
      }
    } catch (error) {
       if (error?.response?.status === 401) {
        dispatch(setToken(null))
        dispatch(setUser(null))
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("user")
        dispatch(setLoading(false))
        navigate('/login')
      }
      else {
        dispatch(setLoading(false))
        toast.error(message.errorMessage)
      }
    }
}
}

export function emailResend(token, navigate) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", EMAIL_RESEND, '', { 'Authorization': `Bearer ${token}` })


      if (response?.status === 200) {
        toast.success(response.data.message)
        navigate('/register')
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else if (error?.response?.status === 400) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else {
        dispatch(setLoading(false))
        toast.error(message.errorMessage)
      }
    }
  }
}

export function forgetPassword(email, navigate) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", FORGET_PASSWORD, {
        email
      })


      if (response?.status === 200) {
        dispatch(setLoading(false))
        navigate('/reset')
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else if (error?.response?.status === 403) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else if (error?.response?.status === 500) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else {
        dispatch(setLoading(false))
        toast.error(message.errorMessage)
      }
    }
  }
}

export function resetPassword(email, password, password_confirmation, token, navigate) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", RESET_PASSWORD, {
        email, password, password_confirmation, token
      })


      if (response?.status === 200) {
        dispatch(setLoading(false))
        toast.success(response.data.message)
        navigate('/login')
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch(setLoading(false))
        toast.error(error.response.data.message)
      }
      else if (error?.response?.status === 422) {
        dispatch(setLoading(false))
        toast.error(error.response.data.errors.password[0])
      }
      else {
        dispatch(setLoading(false))
        toast.error(message.errorMessage)
      }
    }
  }
}

export function getuniversalPlans() {
  return async (dispatch) => {
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("GET", GET_UNIVERSAL_PLAN_API,"",{});


          if (response?.status === 200) {
              dispatch(setLoading(false));
              return response;
          }
      } catch (error) {
          if (error?.response?.status === 404) {
              dispatch(setLoading(false))
              return error.response.message
          }
          else {
              dispatch(setLoading(false))
          }
      }
  }
}


export function contactUs(payload) {
  return async (dispatch) => {
      dispatch(setLoading(true));
      try {
          const response = await apiConnector("POST",CONTACT_US ,payload,{});

          if (response?.status === 201) {
              dispatch(setLoading(false));
              toast.success(response.data.message)
          }
      } catch (error) {
          if (error?.response?.status === 404) {
              dispatch(setLoading(false))
              toast.error(error.response.data.message)
          }
          else {
              dispatch(setLoading(false))
          }
      }
  }
}
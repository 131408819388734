import Footer from "./Footer";
import { useState } from "react";
import HomeNav from "./NavBar";
import picture1 from '../../../assets/picture1.jpeg'
import picture2 from '../../../assets/picture2.jpeg'
import picture3 from '../../../assets/picture3.jpeg'
import picture4 from '../../../assets/picture4.jpeg'
import picture5 from '../../../assets/picture5.jpeg'
import picture6 from '../../../assets/picture6.jpeg'
import picture7 from '../../../assets/picture7.jpeg'
import linkedin from '../../../assets/icons8-linkedin-24.png'
import picture8 from '../../../assets/picture8.jpeg'
import picture9 from '../../../assets/picture9.jpeg'
import picture10 from '../../../assets/picture10.jpeg'



const teamMembers = [
  {
    id: 1, name: "Aryan Goel", info: `As a Lead Web Developer at ACM@UCR, Aryan manages the end-to-end development of modern websites for student organizations. He collaborates with club ambassadors to gather requirements, works with UI/UX designers to bring ideas to life,
    and translates them into functional solutions.Aryan is passionate about making a meaningful impact through technology. During his internships, he contributed to the
    development of an automation solution for data pipelines as part of the Smart Auto Mapper product. helping accelerate the Pfizer
    COVID-19 vaccine approval process. Through this experience, he gained expertise in data engineering, cloud technologies, and
    scalable CI/CD pipelines, working with tools like Docker, Kubernetes, and GitHub Actions. 
    Beyond his professional experience, Aryan explores Natural Language Processing (NLP) through personal projects. He
    developed a supervised machine learning model that classifies text into seven emotion categories. 
    Aryan is eager to continue learning and leveraging technology to create innovative solutions! Aryan Goel has experience in full-
    stack development, data engineering, and machine learning.`, desc: "Software Project Lead Engineer", img: picture3, linkedin: "https://www.linkedin.com/in/aryan-goel-2001/"
  },

  {
    id: 2, name: "Osanna Deng", info: `As a four-time hackathon winner and six-time participant, Osanna thrives in high-pressure, time-constrained environments,
where she challenges herself by exploring diverse computing mediums. Her passion of computing for innovation has driven her
to develop a variety of projects using languages such as HTML, CSS, Javascript, and Python, and frameworks including Django,
React Native, and Godot. 
Academically, Osanna delves into diverse subfields of computer science. Beyond her passion for studying data structures and
algorithms, she has experimented with niche topics such as parallel systems, artificial intelligence, and computer graphics, where
she fine-tunes her proficiency in C++. 
Driven by curiosity, Osanna strives to explore the breadth of computer science, and is eager to learn and improve. Osanna Deng
is a Computer Science Student at the University of California, Riverside, focusing in full-stack, web, and game development.`,
    desc: "Full Stack Software Engineer", img: picture1, linkedin: "https://www.linkedin.com/in/osannadeng/"
  },
  {
    id: 3, name: "Kevin Loritsch", info: `Kevin has been a lead designer working at ACM@UCR, managing communication between on campus-organizations and
designers to design websites that elevate the campus environment, while leading teams of students through hosting weekly
workshops teaching basic HTML, Tailwind, and REACT, and managing repositories, including handling issues and pull requests.
Kevin is also heavily motivated with making a meaningful impact through research, and has recently been working discovering
techniques to improve processing and understanding of medical free-text through AI and NLP techniques. They are also about to
begin a project investigating the campus testing center, finding ways to help students learn most effectively. Similarly, Kevin also
works as a Grader and Undergraduate Learning Assistant, aiming to improve learning in introductory computer science classes.
Kevin is excited to continue learning and evolving skills moving forward! Kevin Loritsch is a Computer Science Student at the
University of California, Riverside, focusing in full-stack development, natural language processing, and developing new teaching
strategies.`,
    desc: "Full Stack Software Engineer", img: picture2, linkedin: "https://www.linkedin.com/in/kevin-loritsch/"
  },
  {
    id: 4, name: "Nichole Godfrey", info: `Nichole has delved in research at UCR, working on utilizing generative AI for geospatial data tracking. Her work involves using
large language models to simplify SQL queries into plain text to improve accessibility. Additionally, she has worked with
regression and Bayesian inference to explore computational material sciences. 
She is passionate about using technology to spread a positive message. Nichole enjoys learning about the endless possibilities
with technology, and hopes to use it for innovation and real-world impact. With experience in frontend development, she hopes to
continue creating to help education and accessibility. 
Nichole is excited to continue learning and growing in the field of computer science, and hopes to use her skills to make a
meaningful impact! She is a Computer Science student at the University of California, Riverside, with a focus in generative AI
and frontend development.`, desc: "Software Engineer", img: picture4, linkedin: "https://www.linkedin.com/in/nichole-godfrey-084b66229/"
  },
  {
    id: 5, name: "Rachel Chan", info: `Rachel is a member of ACM Create, where she designs websites for campus organizations. She focuses on designing platforms
that are both effective and visually appealing. Additionally, Rachel is a member of ACM Spark, where she collaborates with other
students to develop static frontends using React and Tailwind CSS. 
She is interested in exploring the intersection between her passions in visual arts and technology, particularly in applying artistic
creativity to create unique user experiences. As a 3x hackathon winner, she has expanded her technical skills and gained
experience in game development with Godot and mobile app development with React Native and Expo Go. These experiences
have deepened her passion for building creative visuals in a digital medium. 
Rachel is eager to continue building her skillset to be applied in innovative projects! Rachel is a Computer Science Major student
at the University of California, Riverside, with experience in UI/UX design and frontend development.`, desc: "Software Engineer and UI/UX Designer", img: picture5, linkedin: "http://www.linkedin.com/in/rachel-chan0266"
  },
  {
    id: 6, name: "Arielle Haryanto", info: `As a lead web developer at ACM @ UCR, Arielle oversees communication between on campus-organizations and designers to
create websites that promote campus involvement. After creating static designs, Arielle then led teams of students through
hosting weekly workshops about React, Git/Github, Tailwind CSS, and project management. 
Arielle is driven to make a meaningful impact through technology, by helping non profit organizations through web development.
Specifically non profits that aim to provide accessible education and healthcare. Parallel to this, she also works as a Lab Leader/
Teaching Assistant for Math003 at UCR, to improve learning strategies, ensuring proper understanding for all students. 
Arielle is ecstatic to continue learning and using/building her skills to create accessible technology for everyone! She is a
Computer Science with Business Applications Major student at the University of California, Riverside. With a focus in full-stack
development, AI processing and UI/UX research/ principles.` , desc: "AI and Full Stack Software Engineer", img: picture6, linkedin: "https://www.linkedin.com/in/arielle-haryanto"
  },
  {
    id: 7, name: "Vidhi Tapde", info: `Vidhi is a member of ACM@UCR, where she has worked as a web developer, contributing to various projects and collaborating
with other students to build websites for campus-affiliated clubs. In addition, she serves as an ACM mentor to computer science
underclassmen, providing them with advice as they navigate through their undergraduate experience. 
Vidhi is determined to make a lasting impact with technology in the real world. For the past two summers, she has been working
as a Technical Intern at Northrop Grumman. During her internship, she enhanced product quality by designing and implementing
unit-tests for the program-specific codebase. Additionally, she wrote technical documentation to share her insights and provide a
simple way for engineers joining the team to set up their environments, write unit tests, and understand the program effectively. 
She is eager to expand her knowledge and delve deeper into the realm of computer science. Her passion for innovation and
hands-on experience has fueled her desire to improve the quality of life for others through technology. Vidhi Tapde is a Computer
Science student at the University of California, Riverside with experience in full-stack development and UI/UX design.`, desc: "Full Stack Software Engineer", img: picture7, linkedin: "https://www.linkedin.com/in/vidhitapde/"
  },
  {
    id: 8, name: "Madhavi Sharma", info: `Experienced Backend Developer with 3 years in the software industry, specializing in building and optimizing backend systems. Proficient in technologies like Node.js, PHP (Laravel), MongoDB, MySQL, and JavaScript. Skilled in developing scalable APIs, database management, and backend architecture. Adept at working in Agile environments, ensuring seamless collaboration and efficient development. Passionate about writing clean, maintainable code and improving system performance to deliver high-quality software solutions.`, desc: "Software Engineer", img: picture10, linkedin: "https://www.linkedin.com/in/madhavi-sharma-517431220/"
  },
 
  {
    id: 9, name: "Rati Srivastava", info: `Experienced Senior Test Engineer with 8+ years in the Software Industry, specializing in testing and automation for over 5 years. Proficient in developing and executing automated test scripts with tools like Selenium, Maven, TestNG, and Cucumber. Skilled in a wide range of testing methodologies, including Functional, Regression, UAT, Smoke, and Database Testing. Extensive experience working in Agile and Scrum environments with tools such as JIRA, Azure DevOps, and Postman. A Certified ScrumMaster (CSM) dedicated to enhancing release quality while fostering collaboration and efficiency within teams.`, desc: "Senior QA Engineer", img: picture9, linkedin: "https://www.linkedin.com/in/rati-srivastava-15046580/"
  },
  {
    id: 10, name: "Aman Kumar", info: `Experienced UI/UX developer specializing in building dynamic and responsive user interfaces with React.js. With 2+ years of experience, I excel in creating scalable folder structures, implementing client-side authentication using JSON Web Tokens (JWT), and managing state efficiently with Context API, Redux, and React Hooks. My expertise extends to advanced React concepts, ensuring maintainable and high-performance applications.
Committed to continuous learning, I stay updated with industry trends and best practices to deliver innovative solutions. I thrive in collaborative environments, contributing to meaningful discussions and driving projects forward with a problem-solving mindset.`, desc: "Software Engineer", img: picture8, linkedin: "https://www.linkedin.com/in/aman-kumar-8607031b7/"
  },
 

];



const AboutUs = () => {

  const [selectedMember, setSelectedMember] = useState(null);
  return (
    <>
      <HomeNav />
      <section className="contact-section">
        <h1>SDddfef</h1>
        <div className="contact-container">
          <h1 className="contact-heading" style={{ textAlign: "left", fontSize: "30px" }}>Leadership at 1800Hired</h1>
          <p className="features-text" style={{ fontSize: "18px", color: "rgba(0,0,0,0.7)", marginBottom: "50px", width: "50%" }}>
            1800Hired began its journey in 2024 with a vision to transform the hiring landscape. Frustrated by the inefficiencies in resume screening and candidate matching,
            we set out to leverage cutting-edge AI to make the process smarter and faster. Today, 1800Hired is a leader in HR technology, home to the world’s largest pool of resumes,
            empowering businesses with intelligent hiring solutions. Our mission is simple: to help companies find the right talent effortlessly, using advanced automation and data-driven insights.
          </p>
          <div className="team-grid">
            {teamMembers.map((member) => (
              <div
                key={member.id}
                className="team-card"
                onClick={() => setSelectedMember(member)}
              >
                <img src={member.img} alt={member.name} className="team-img" />
                <h3 className="team-name">{member.name} |
                  <img src={linkedin}
                    style={{
                      cursor: "pointer",
                      verticalAlign: "middle"
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(member.linkedin, "_self");
                    }}
                  />
                </h3>
                <p className="team-desc">{member.desc}</p>
              </div>
            ))}
          </div>
        </div>
        {selectedMember && (
          <div className="modal-overlay-about" onClick={() => setSelectedMember(null)}>
            <div className="modal-content-about" onClick={(e) => e.stopPropagation()}>
              <span className="close-btn-about" onClick={() => setSelectedMember(null)}>&times;</span>

              <p className="about-us-desc">{selectedMember.info}</p>
            </div>
          </div>
        )}
      </section>
      <Footer />
    </>

  )
}

export default AboutUs;
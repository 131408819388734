import {combineReducers} from "@reduxjs/toolkit";
import authReducer from '../slices/authSlice';
import profileReducer from '../slices/profileSlice'
import cardReducer from '../slices/cardSlice'

const rootReducer  = combineReducers({
    auth:authReducer,
    profile:profileReducer,
    card:cardReducer
})

export default rootReducer;
import {createSlice} from "@reduxjs/toolkit"

const initialState = {
    card: sessionStorage.getItem("card") ? JSON.parse(sessionStorage.getItem("card")) : null,
    loading: false,
};

const cardSlice = createSlice({
    name:"card",
    initialState: initialState,
    reducers: {
        setCard(state, value) {
            state.card = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
          },
    },
});

export const {setCard, setLoading} = cardSlice.actions;
export default cardSlice.reducer;
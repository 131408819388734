import { useSearchParams } from "react-router-dom";
import { useState ,useEffect} from "react";
import SideBar from "../common/SideBar";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { message } from "../../messages/Messages";
import { getGoogleAd, googleAdCreate } from "../../../services/operations/AdminPageAPI";


const schema = yup.object().shape({
    clientId: yup.string().required(message.requiredFied),
    viewPageScript: yup.string().required(message.requiredFied),
    resumePageScript: yup.string().required(message.requiredFied),
    smallBannerScript: yup.string().required(message.requiredFied),

});

const GoogleAdSense = () => {

    const { register, handleSubmit, setValue, formState: { errors }, reset } = useForm({ resolver: yupResolver(schema) });
    const [Visible, setVisible] = useState(false); 
    const [googleData,setGoogleData]=useState([]);
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.profile.user)


    const onSubmit = async(data) => {
        const {isVisible,viewPageScript,resumePageScript,smallBannerScript}=data;

        const payload = {
            is_visible: data.isVisible,
            client_id: data.clientId,
            small_banner_script: data.smallBannerScript,
            view_page_script: data.viewPageScript,
            resume_page_script: data.resumePageScript

        }
        await dispatch(googleAdCreate(payload,token))
    }

    const getGoogleAdData=async()=>{
        const data=await dispatch(getGoogleAd(token))
        setValue("clientId",data.data.data[0].client_id)
        setValue("resumePageScript",data.data.data[0].resume_page_script)
        setValue("viewPageScript",data.data.data[0].view_page_script)
        setValue("smallBannerScript",data.data.data[0].small_banner_script)

    }

    useEffect(()=>{
        getGoogleAdData()
    },[])


    return (
        <div className="admin-dashboard-container">
            <SideBar user={user} />
            <div className="admin-dashboard-content">
                <div className="admin-dashboard-heading">
                    <h5 style={{ fontSize: "24px", fontWeight: "600" }}>Basic Setup and Ads</h5>
                    <div className="integration-content">
                        <form className='integration-form' onSubmit={handleSubmit(onSubmit)}>
                            <div className="integration-form-group">
                                <div className="form-headers">
                                    <div className="form-labels">
                                        <label htmlFor="isVisible" className="form-label-primary">
                                            Allow Google Adsense
                                        </label>
                                        <label htmlFor="isVisible" className="form-label-secondary">
                                            Allow ads on app pages
                                        </label>
                                    </div>
                                    <div className="form-toggle">
                                        <input type="checkbox" id="isVisible"
                                        checked={Visible} 
                                        {...register("isVisible")}
                                         onChange={() => setVisible(!Visible)}/>
                                        <label htmlFor="isVisible" className="toggle-label"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="clientId" className="form-label">
                                    Client Id:
                                </label>
                                <input
                                    id="clientId"
                                    rows={8}
                                    type='text'
                                    column={6}
                                    className={`form-input ${errors.clientId ? 'input-error' : ''}`}
                                    {...register('clientId')}
                                    disabled={!Visible}
                                />
                                {errors.clientId && <p className="error-message">{errors.clientId.message}</p>}
                                <p style={{ fontSize: "13px", fontWeight: "400", width: "100%", color: "#999999" }}>Paste your Adsense Client Id e.g: ca-pub-XXXXXXXXXXXXXXX</p>
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="resumePageScript" className="form-label">
                                    List page banner
                                </label>
                                <textarea
                                    id="resumePageScript"
                                    rows={8}
                                    placeholder="paste the <ins></ins> tags here..."
                                    column={6}
                                    disabled={!Visible}
                                    className={`form-input ${errors.resumePageScript ? 'input-error' : ''}`}
                                    {...register('resumePageScript')}
                                />
                                {errors.resumePageScript && <p className="error-message">{errors.resumePageScript.message}</p>}
                                <p style={{ fontSize: "13px", fontWeight: "400", width: "100%", color: "#999999" }}>This banner will be showed above user files</p>
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="resumePageScript" className="form-label">
                                    Resume View Page
                                </label>
                                <textarea
                                    id="viewPageScript"
                                    placeholder="paste the <ins></ins> tags here..."
                                    rows={8}
                                    column={6}
                                    disabled={!Visible}
                                    className={`form-input ${errors.viewPageScript ? 'input-error' : ''}`}
                                    {...register('viewPageScript')}
                                />
                                {errors.viewPageScript && <p className="error-message">{errors.viewPageScript.message}</p>}
                                <p style={{ fontSize: "13px", fontWeight: "400", width: "100%", color: "#999999" }}>This banner will be showed below file download page</p>
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="smallBannerScript" className="form-label">
                                    Small Banner
                                </label>
                                <textarea
                                    id="smallBannerScript"
                                    placeholder="paste the <ins></ins> tags here..."
                                    className={`form-input ${errors.smallBannerScript ? 'input-error' : ''}`}
                                    {...register('smallBannerScript')}
                                    disabled={!Visible}
                                />
                                {errors.smallBannerScript && <p className="error-message">{errors.smallBannerScript.message}</p>}
                                <p style={{ fontSize: "13px", fontWeight: "400", width: "100%", color: "#999999" }}>This banner will be showed on the homepage</p>
                            </div>
                            <button type="submit" className="submit-button" style={{ widthidth: "395px", fontSize: "16px" }} disabled={!Visible}>
                                Save Changes
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoogleAdSense;
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getuniversalPlans } from "../../../services/operations/authAPI";
import Loader from "../../loader/Loader";
import NavLogo from '../../../assets/logo-transparent-png.png';
import heroImage from '../../../assets/home-page.png'
import featureImage2 from '../../../assets/mockrocket-capture 3.png'
import featureImage1 from '../../../assets/mockrocket-capture.png'
import '../../../styles/homeStyle.css'
import AboutHired from "./About1800Hired";
import HomeNav from "./NavBar";
import Footer from "./Footer";
import Recommended from '../../../assets/vecteezy_recommended-with-thumb-up-sign_36579847.jpg'




const LandingPage = () => {
  const [plans, setPlans] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.auth.loading);
  const user = useSelector((state) => state.profile.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRole = user?.roles[0]?.name;

  const getPlans = async () => {
    const response = await dispatch(getuniversalPlans());
    setPlans(response?.data?.data[0])
  };

  useEffect(() => {
    getPlans();
  }, []);



  return (
    <>
      {loading && <Loader />}
      <HomeNav />
      <section className="hero-section" id='whoweare'>
        <div className="hero-container">
          <div className="hero-content">
            <div className="hero-text">
              <h2 className="hero-heading">Welcome to 1800Hired.com</h2>
              <h3 className="hero-subheading">Revolutionizing Talent Discovery with AI</h3>
              <p className="hero-description">
                At 1800Hired.com, we empower businesses and recruiters to streamline their hiring processes with the
                power of cutting-edge AI. Whether you’re managing your existing resume database or
                searching for fresh talent, our intelligent platform has everything you need to find the right
                candidates—quickly and efficiently.
              </p>
            </div>
          </div>
          <div className="hero-image-container">
            <img src={heroImage} alt="hero image" className="hero-image" />
          </div>
        </div>
      </section>
      {/* <section className="our-partners">
    <div>
    </div>
    </section> */}
      <section className="feature-section" id='whatwedo'>
        <div className="feature-container">
          {/* Row 1 */}
          <div className="feature-row">
            <div className="feature-text">
              <h2 className="feature-heading">Smart Resume Management</h2>
              <p className="feature-paragraph">
                Organize, access, and search through your resume database like never before. Our AI-powered tools
                allow you to perform deep, intelligent searches based on
              </p>
              <ul className="feature-list" role="list">
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Location: Narrow down candidates by geography to focus on your target market
                  </span>
                </li>
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Skills: Pinpoint the exact expertise you’re looking for
                  </span>
                </li>
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Companies & Industry: Filter by previous employers or industries to match your criteria
                  </span>
                </li>
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Experience : Find candidates with the ideal years of experience for your needs
                  </span>
                </li>
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Keywords: Customize searches using specific terms to uncover hidden talent gems
                  </span>
                </li>
              </ul>
              <p className="feature-paragraph">
                Say goodbye to endless scrolling and manual sorting in excel sheets—our platform does the heavy lifting
                for you
              </p>
            </div>
            <img
              className="feature-image"
              src={featureImage1}
              alt="dashboard feature image"
            />
          </div>

          {/* Row 2 */}
          <div className="feature-row" id='whyus'>
            <img
              className="feature-image"
              src={featureImage2}
              alt="feature image 2"
            />
            <div className="feature-text">
              <h2 className="feature-heading">Why Choose 1800Hired.com</h2>
              <p className="feature-paragraph">
                1800Hired.com is not just a platform—it’s your hiring partner. Let us help you transform how you
                discover, evaluate, and connect with exceptional talent.
              </p>
              <ul className="feature-list" role="list">
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    AI-Driven Insights: Our advanced algorithms deliver the most relevant matches, saving you time
                    and effort
                  </span>
                </li>
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Enhanced Productivity: Streamline the hiring process by focusing on the right candidates
                    without manual guesswork
                  </span>
                </li>
                <li className="feature-list-item">
                  <svg className="feature-icon" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="feature-item-text">
                    Global Reach: Access resumes from around the world, helping you connect with talent across
                    borders
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="trusted-section">
        <div className="trusted-container">
          <div className="trusted-left">
            <p className="trusted-subtitle">Trusted Worldwide</p>
            <h2 className="trusted-title">Trusted by a number of recruiters</h2>
            <p className="trusted-description">
              1800Hired.com offers AI-powered matching to find top talent fast, boosts productivity by streamlining hiring,
              and connects you to a global talent pool. It’s your smart hiring partner—transforming how you discover and engage
              exceptional candidates worldwide
            </p>
          </div>
          <div className="trusted-grid">
            <div className="trusted-item">
              <svg class="trusted-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z" clip-rule="evenodd"></path></svg>
              <h3 className="trusted-item-title">80M+ Profiles</h3>
              <p className="trusted-item-text">80M+ profiles on 1800Hired.com.</p>
            </div>
            <div className="trusted-item">
              <svg class="trusted-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clip-rule="evenodd"></path></svg>
              <h3 className="trusted-item-title">20+ Countries</h3>
              <p className="trusted-item-text">Have used 1800Hired.com for hiring Candidates.</p>
            </div>
            <div className="trusted-item">
              <svg class="trusted-icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"></path>
              </svg>
              <h3 className="trusted-item-title">5,000+ Recruiters</h3>
              <p className="trusted-item-text">Trusted by over 5,000+ recruiters and increasing.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing-section" id="pricing">
        <div className="pricing-container">
          <div className="pricing-header">
            <h2>Designed for business teams like yours</h2>
            <p>
              Here at 1800Hired.com, we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.
            </p>
          </div>
          <div className="pricing-cards">
            <div className="pricing-card">
              <h3>Basic Recruiter License</h3>
              <p>Access Resumes based on
                Earned/& Daily Credits</p>
              <div className="price" >
                <span style={{ fontSize: "1.5rem" }}>Free for limited time</span>
                <span></span>
              </div>
              <div className="price-first">
                <span>$14.99</span>
                <span>/month</span>
              </div>
              <hr style={{ marginBottom: "1rem",border:"1px solid #F3F4F6"  }} />
              <ul className="pricing-list">
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>View/Download global
                    resumes on credit*</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Max storage - 248MB (for
                    your resumes)</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>No need to maintain Excel
                    sheet<sup>**</sup></span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>User personal email
                    address<sup>**</sup></span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>AI search on your/global
                    resume database</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Make unlimited notes and
                    refer anytime in future**</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>No yearly Contract, Month
                    to Month</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Cancel Anytime</span>
                </li>
              </ul>
              <a href="/register">Get started</a>
            </div>
            <div className="pricing-card" style={{position:"relative"}}>
            <img src={Recommended} alt="recommended" className="badge" />
              <h3>Premium Recruiter License</h3>
              <p>Unlimited access to resumes</p>
              <div className="price" style={{ margin: "2.75rem 0rem 1rem 0rem" }}>
                <span style={{ fontSize: "1.5rem" }}>${plans?.amount}</span>
                <span>/month</span>
              </div>
              <div className="price-first">
                <span>${plans?.demoprice}</span>
                <span>for first month</span>
              </div>
              <hr style={{ marginTop: "1rem",border:"1px solid #F3F4F6" }} />
              <div className="price-first">
                <span></span>
                <span></span>
              </div>
              <ul className="pricing-list">
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>View/Download
                    unlimited global
                    resumes</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Max storage –
                    Unlimited</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>No need to maintain Excel
                    sheet<sup>**</sup></span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>User personal email
                    address<sup>**</sup></span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>AI search on your/global
                    resume database</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Make unlimited notes and
                    refer anytime in future<sup>**</sup></span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>No yearly Contract, Month
                    to Month</span>
                </li>
                <li>
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Cancel Anytime</span>
                </li>
              </ul>
              <a href="/register">Get started</a>
            </div>
            <div className="pricing-card">
              <h3>Enterprise License</h3>
              <div className="price" style={{ margin: "0rem 0rem 0rem" }}>
                <span style={{ fontSize: "1.5rem" }}>Coming Soon</span>
              </div>
              <hr style={{ marginBottom: "28rem",border:"1px solid #F3F4F6" }} />
            </div>
          </div>
          <div className="pricing-desc" style={{ padding:"4rem 0rem 0rem 1.25rem"}}>
            <p><b style={{color:"black"}}>*</b>Earn Credits: Recruiters, upload and manage your resumes to earn rewards! Get 10 credits for every 10
              uploads.</p>
            <p><b style={{color:"black"}}>**</b>Effortless Organization: Forget folders and spreadsheets—make unlimited notes and access past
              discussions anytime.</p>
            <p><b style={{color:"black"}}>**</b>Flexible Sign-Up: No corporate email required! Use your personal email to register hassle-free.</p>
          </div>
        </div>
      </section>
      <AboutHired />
      <section className="free-trial-section">
        <div className="free-trial-container">
          <div className="free-trial-text-container">
            <h2 className="free-trial-heading">Start your free trial today</h2>
            <p className="free-trial-paragraph">
              Try 1800Hired.com Platform for 30 days. No credit card required.
            </p>
            <a href="/register" className="free-trial-button">
              Get Started
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default LandingPage;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../../styles/globalStyle.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getUserDetails } from "../../../services/operations/ProfileAPI";  // Assuming you have imported the sidebar
import Chart from "../Chart/Chart";
import SideBar from "../common/SideBar"
import { addStripeConfig } from "../../../services/operations/AdminPageAPI";
import { message } from "../../messages/Messages";


const schema = yup.object().shape({
    webhookUrl : yup.string().required(message.requiredFied),
    paymentDesc: yup.string().required(message.requiredFied),
    publishableKey: yup.string().required(message.requiredFied),
    secretKey: yup.string().required(message.requiredFied),
    webhookSecret: yup.string().required(message.requiredFied),

  });

const Integration = () => {
    const { register, handleSubmit,setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.profile.user)


    const onSubmit=(data)=>{

        const payload={
            webhook_url:data.webhookUrl,
            payment_description:data.paymentDesc,
            publishable_key:data.publishableKey,
            secret_key:data.secretKey,
            webhook_secret:data.webhookSecret
        }
         dispatch(addStripeConfig(token,payload))
    }


    return (
        <div className="admin-dashboard-container">
            {/* Sidebar component */}
            <SideBar user={user}  />
            <div className="admin-dashboard-content">
                <div className="admin-dashboard-heading">
                    <h5 style={{fontSize:"24px",fontWeight:"600"}}>Intergations</h5>
                    <div className="integration-content">
                        <form className='integration-form' onSubmit={handleSubmit(onSubmit)}>
                            <div className="integration-form-group">
                                <label htmlFor="webhookUrl" className="form-label">
                                    Your Webhook URL
                                </label>
                                <input
                                    type="text"
                                    id="webhookUrl"
                                    placeholder=''
                                    className={`form-input ${errors.email ? 'input-error' : ''}`}
                                    {...register('webhookUrl')}
                                />
                                {errors.webhookUrl && <p className="error-message">{errors.webhookUrl.message}</p>}
                                <p style={{fontSize:"13px",fontWeight:"400",width:"100%",color:"#999999"}}>Please copy your URL and paste it to the service webhook setup.</p>
                            </div>
                            
                            <div className="integration-form-group">
                                <label htmlFor="paymentDesc" className="form-label">
                                    Payment Description:
                                </label>
                                <textarea
                                    id="paymentDesc"
                                    rows={8}
                                    column={6}
                                    className={`form-input ${errors.paymentDesc ? 'input-error' : ''}`}
                                    {...register('paymentDesc')}
                                />
                                {errors.paymentDesc && <p className="error-message">{errors.paymentDesc.message}</p>}
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="publishableKey" className="form-label">
                                    Publishable Key
                                </label>
                                <input
                                    type="text"
                                    id="publishableKey"
                                    placeholder='Paste your publishable key'
                                    className={`form-input ${errors.publishableKey ? 'input-error' : ''}`}
                                    {...register('publishableKey')}
                                />
                                {errors.publishableKey && <p className="error-message">{errors.publishableKey.message}</p>}
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="secretKey" className="form-label">
                                    Secret Key
                                </label>
                                <input
                                    type="text"
                                    id="secretKey"
                                    placeholder='Paste your secret key'
                                    className={`form-input ${errors.secretKey ? 'input-error' : ''}`}
                                    {...register('secretKey')}
                                />
                                {errors.secretKey && <p className="error-message">{errors.secretKey.message}</p>}
                            </div>
                            <div className="integration-form-group">
                                <label htmlFor="webhookSecret" className="form-label">
                                    Webhook Secret
                                </label>
                                <input
                                    type="text"
                                    id="webhookSecret"
                                    placeholder='Paste your webhook secret'
                                    className={`form-input ${errors.webhookSecret ? 'input-error' : ''}`}
                                    {...register('webhookSecret')}
                                />
                                {errors.webhookSecret && <p className="error-message">{errors.webhookSecret.message}</p>}
                            </div>

                            <button type="submit" className="submit-button" style={{widthidth:"395px",fontSize:"16px"}}>
                                Store Credentials
                            </button>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Integration;

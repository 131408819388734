import React from "react";

const CustomPagination = ({ totalPages, currentPage, onPageChange, isLoading }) => {
  const rangeSize = 5;

  const getPageNumbers = () => {
    if (totalPages === 1) return [1]; 

    const pageNumbers = [];

    if (currentPage <= rangeSize) {
      for (let i = 1; i <= Math.min(rangeSize, totalPages); i++) {
        pageNumbers.push(i);
      }
      if (totalPages > rangeSize) {
        pageNumbers.push("...");
      }
    } else if (currentPage >= totalPages - rangeSize + 1) {
      if (totalPages > rangeSize) {
        pageNumbers.push("...");
      }
      for (let i = totalPages - rangeSize + 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      pageNumbers.push("...");
      for (let i = currentPage; i < currentPage + rangeSize; i++) {
        pageNumbers.push(i);
      }
      if (currentPage + rangeSize < totalPages) {
        pageNumbers.push("...");
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    if (page !== currentPage && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <div className="custom-pagination">
      <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || isLoading}>
        Prev
      </button>

      {getPageNumbers().map((page, index) =>
        typeof page === "number" ? (
          <button
            key={index}
            onClick={() => handlePageChange(page)}
            className={page === currentPage ? "active" : ""}
            disabled={isLoading}
          >
            {page}
          </button>
        ) : (
          <span key={index} className="dots">...</span>
        )
      )}

      <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || isLoading}>
        Next
      </button>
    </div>
  );
};

export default CustomPagination;

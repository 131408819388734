

export const message={
    loginSuccess:"Successfully Logged In",
    requiredFied:"This field is required",
    errorMessage:"Some error occured.Please try again",
    multipleuploadMessage:"Resumes uploaded! We're processing them and will notify you when ready",
    singleuploadMessage:"Resume uploaded successfully" ,
    profilePictureLimitMessage:'File size should not exceed 2MB',
    passwordRequiredMessage:"Password is required",
    passwordMinMessage:"Password must be at least 8 characters long",
    passwordMatchMessage:"Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
    newPasswordRequiredMessage:"New Password is required",
    confirmPasswordRequiredMessage:"Confirm Password is required",
    matchMessage:'Passwords should match',
    emailRequiredMessage:"Email is required",
    invalidEmailMessage:"Invalid email format",
    nameRequiredMessage:"Name is required",
    nameMatchMessage:"Name must contain only alphabets and spaces",
    uploadFileFormatMessage:'Unsupported file format',
    uploadFileLimitMessage:'The total file size exceeds 25MB. Please reduce the file size',
    PaymnetFailureMessage:"Payment Failed,Please try again after some time",



}
import React, { useState, useEffect, lazy } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CommonLayout from './common/commonLayout';
import '../../styles/globalStyle.css'
import ExternalButton from './common/externalButton';
import HeaderSection from './common/headerSection';
import Cross from '../../assets/x.png'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { emailResend } from '../../services/operations/authAPI';
import Loader from '../loader/Loader';
import { useLocation } from 'react-router-dom';


const VerificationExpire = () => {
    const loading = useSelector((state) => state.auth.loading);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

    const handleEmailResend = () => {
        dispatch(emailResend(token, navigate))
    }

    return(
            <CommonLayout title='Logo'>
                {loading && <Loader />}
                <div className='header-section Verify-email'>
                    <img src={Cross} style={{ "backgroundColor": "red", "borderRadius": "50%", "width": "100px", 'height': "100px" }} loading={lazy} alt='resetLink' />
                    <h3>Link Expired</h3>
                    <p>It appears that the link you attempted to access has expired. Please click the button below to request a new link:
                        <br /><br /><button onClick={handleEmailResend} className='submit-button'>Request new Link</button>
                    </p>

                </div>
                <div className='login-form'></div>
            </CommonLayout>
    )
}

export default VerificationExpire;

import React ,{useState,useEffect} from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { adminDashboardStats } from '../../../services/operations/AdminPageAPI';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EarningChart = ({statsType}) => {
    const [filter, setFilter] = useState(statsType);
    const [chartData, setChartData] = useState(null);
    const token=useSelector((state)=>state.auth.token)
    const dispatch=useDispatch()



    const getDashboardCount=async()=>{
        const response=await dispatch(adminDashboardStats(token))
        if (response?.data?.data) {
            setChartData(response.data.data);
        }

    }

    
    
    useEffect(() => {
        setFilter(statsType);
    }, [statsType]);
    useEffect(()=>{
        getDashboardCount()
    },[])


    const getMonthShortNames = () => [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
    ];

    const mapMonthlyStats = (monthlyStats) => {
        const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthKeys = Object.keys(monthlyStats);

        const earnings = [];
        let labels = [];

        shortMonths.forEach((shortMonth, index) => {
            const key = monthKeys.find((k) => k.startsWith(shortMonth) || k === `${index + 1}`.padStart(2, '0'));
            earnings.push(monthlyStats[key]?.earnings || 0);
        });

        return { earnings };
    };

    const getDataByFilter = (filter) => {
        if (!chartData) return null;

        let earnings = [];
        let labels = [];

        switch (filter) {
            case 'Week': {
                const weeklyStats = chartData.weekly_stats;
                labels = Object.keys(weeklyStats);
                earnings = labels.map((date) => weeklyStats[date]?.earnings || 0);
                break;
            }
            case 'Month': {
                const { earnings: monthlyEarnings } = mapMonthlyStats(chartData.monthly_stats);
                labels = getMonthShortNames();
                earnings = monthlyEarnings;
                break;
            }
            case 'Year': {
                const yearlyStats = chartData.yearly_stats;
                labels = Object.keys(yearlyStats);
                earnings = labels.map((year) => yearlyStats[year]?.earnings || 0);
                break;
            }
            default:
                return null;
        }

        const maxValue = Math.max(...earnings);
        const yMax = maxValue <= 10
            ? 10
            : Math.ceil(maxValue / 100) * 100;

        return {
            labels,
            datasets: [
                {
                    label:'',
                    data: earnings,
                    backgroundColor: '#0057FF',
                    borderWidth: 0,
                    borderRadius: 4,
                    barThickness: 22,
                },],
            yMax,
        };
    };


    // Chart options
    const options = (yMax) => ({
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: 25,
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.dataset.label} ${tooltipItem.raw}`;
                    },
                    title: function () {
                        return '';
                    },
                },
                backgroundColor: 'black',
                titleColor: 'white',
                displayColors: false,
                padding: 13,
                bodyColor: 'white',
                bodyFont: {
                    size: '14px',
                    weight: '300',
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#999999',
                    font: {
                        size: '12px',
                    },
                },
                border: {
                    display: false,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    stepSize: yMax / 10,
                    color: '#999999',
                    font: {
                        size: '12px',
                    },
                    drawTicks: false,
                },
                max: yMax,
                border: {
                    display: false,
                },
            },
        },
    });


    return (

<div className="searchMetrics" style={{ height: "373px" }}>
            <div className="searchTitle" style={{ margin: "25px 0px 25px 25px" }}>
                <div className="searchgraphtitle">Earnings ($)</div>
            </div>
            <div style={{ width: '100%', height: '375px' }}>
             {chartData && ( <Bar 
            data={getDataByFilter(filter)}
            options={options(getDataByFilter(filter)?.yMax || 300)} />)}
        </div>
        </div>
    );
}

export default EarningChart;



import { useDispatch,useSelector } from "react-redux";
import Footer from "./Footer";
import HomeNav from "./NavBar";
import { useForm } from "react-hook-form";
import { contactUs } from "../../../services/operations/authAPI";
import Loader from '../../loader/Loader'



const ContactUs = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch=useDispatch()
  const loading=useSelector((state)=>state.auth.loading)

  const onSubmit = async(data) => {
    const {name,email,phone,message}=data;
    const payload={
      name:name,
      email:email,
      phone_number:phone,
      message:message
    }
    await dispatch(contactUs(payload))
  };

    return (
        <>
        {loading && <Loader/>}
            <HomeNav />
            <section className="contact-section">
                <div className="contact-container">
                    <h2 className="contact-heading">Contact Us</h2>
                    <p className="contact-subheading">If you have any queries, fill the form below with all your details and
                        we'll get back to you</p>
                        <div className="contact-form-wrapper">
            <form className="contact-us-form" onSubmit={handleSubmit(onSubmit)}>
              <div className="contact-us-form-group">
                <label htmlFor="name1" className="contact-us-form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="name1"
                  placeholder="Enter your name"
                  className={`contat-us-form-input ${errors.name ? 'input-error' : ''}`}
                  {...register("name", { required: "Name is required" })}
                  
                />
                 {errors.name && <p className="error-message">{errors.name.message}</p>}
              </div>

              <div className="contact-us-form-group">
                <label htmlFor="email" className="contact-us-form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className={`contat-us-form-input ${errors.email ? 'input-error' : ''}`}
                  {...register("email", {
                    required: "Email is required",
                    pattern: { value: /^\S+@\S+\.\S+$/, message: "Invalid email format" },
                  })}
                />
                 {errors.email && <p className="error-message">{errors.email.message}</p>}
              </div>

              <div className="contact-us-form-group">
                <label htmlFor="phone" className="contact-us-form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phone"
                  placeholder="Enter the phone number"
                  className={`contat-us-form-input ${errors.phone ? 'input-error' : ''}`}
                  {...register("phone", {
                    required: "Phone number is required",
                    pattern: { value: /^[0-9]{10}$/, message: "Enter a valid 10-digit phone number" },
                  })}
                />
                {errors.phone && <p className="error-message">{errors.phone.message}</p>}
              </div>

              <div className="contact-us-form-group">
                <label htmlFor="message" className="contact-us-form-label">
                  Message
                </label>
                <textarea
                  id="message"
                  placeholder="Enter your message"
                  rows={5} 
                  style={{ minHeight: "150px" }} 
                  onInput={(e) => {
                    e.target.style.height = "auto"; 
                    e.target.style.height = `${e.target.scrollHeight}px`;
                  }}
                  {...register("message", { required: "Message is required" })}
                  className={`contat-us-form-input ${errors.message ? 'input-error' : ''}`}
                />
               {errors.message && <p className="error-message">{errors.message.message}</p>}
              </div>

              <button type="submit" className="contact-us-submit-button">
                Send
              </button>
            </form>
       </div>
                    </div>
            </section>
            <Footer />
        </>

    )
}

export default ContactUs;

// import toast from "react-hot-toast";
import {toast} from 'react-toastify'
import { apiConnector } from "../apiConnector";
import { endpoints } from "../apis";
import { message } from "../../components/messages/Messages";

const { UPLOAD_API ,NOTIFICATION_LIST_API} = endpoints;

export function upload(token, formData, setIsUploading,handleModalClose,setTotalProgress, setSelectionCompleted, setFiles) {
  return async (dispatch) => {
    // dispatch(setLoading(true)); 
    try {
      const response = await apiConnector("POST", UPLOAD_API, formData, {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      });
      
      if (response?.status === 200) {
        // dispatch(setLoading(false));
        setTotalProgress(100)
        setIsUploading(false);
        setSelectionCompleted(false);
        setFiles([]);
        handleModalClose();
        return response.data.message
      }
    } catch (error) {
     // dispatch(setLoading(false));
     setTotalProgress(0)
      if (error?.response && error?.response?.status === 500) {
        setIsUploading(false);
        setTotalProgress(0)
        setSelectionCompleted(false);
        setFiles([]);
        handleModalClose();
        toast.error(message.errorMessage);
      }
    }
  };
}



export function notificationList(token) {
  return async (dispatch) => {
    // dispatch(setLoading(true)); 
    try {
      const response = await apiConnector("GET", NOTIFICATION_LIST_API,"", { Authorization: `Bearer ${token}` } );
      
      if (response?.status === 200) {
        //dispatch(setLoading(false));
        return response;
      }
    } catch (error) {
    //dispatch(setLoading(false)); 
      if (error?.response && error?.response?.status === 500) {
        return error.response;
      }
    }
  };
}

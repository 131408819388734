
import Google from "../../../assets/google.png"
import Linkedin from '../../../assets/icons8-linkedin-24.png'
import { Link } from "react-router-dom"
import '../../../styles/globalStyle.css'
import { useGoogleLogin } from "@react-oauth/google"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { googleDetails, googleRegister, googleSignIn, login, signUp } from "../../../services/operations/authAPI"
import axios from "axios"
import { setSignupData } from "../../../slices/authSlice"
import { fetchGoogleUserInfo } from "../../../services/operations/googleFetchAPI"

const ExternalButton = ({ type, googleText, linkedinText, Other, text, path }) => {


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const lastUsedGoogleAccount =
  type === "login" ? sessionStorage.getItem("lastGoogleAccount") : null;

  const handleGoogleSignUp = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {

        const accessToken = tokenResponse.access_token;
        const payload = {
          access_token: accessToken
        }
        if (type === 'register') {
          await dispatch(googleRegister(payload, navigate))
        }
        if (type === 'login') {
          const userInfo = await fetchGoogleUserInfo(accessToken);
        
          if (userInfo?.email) {
            sessionStorage.setItem("lastGoogleAccount", userInfo.email); 
          }
          await dispatch(googleSignIn(payload, navigate))
        }
      } catch (error) {
        console.error("Google Sign Up Error:", error);
      }
    },
    onError: (error) => {
      console.error("Google Login Failed:", error);
    },
    prompt: "select_account" 
  });

  const handleLinkedInLogin = () => {

    const baseUrl = window.location.origin
    const params = new URLSearchParams({
      response_type: "code",
      client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
      redirect_uri: `${baseUrl}/linkedin-callback`,
      scope: "email openid profile",
      state: type, 
    })
    window.location.href = `https://www.linkedin.com/oauth/v2/authorization?${params}`
  };

  const handleLinkedInFailure = (error) => {
    console.error("LinkedIn login error:", error);
  };


  return (
    <div className='external-button'>
       {googleText ? (
        <button className="google-button" onClick={handleGoogleSignUp}>
          <img src={Google} alt="Google" />
          {type === "login" && lastUsedGoogleAccount
            ? `Sign in as ${lastUsedGoogleAccount}`
            : googleText}
        </button>
      ) : null}
           {linkedinText ? (
        <button className="linkedin-button" onClick={handleLinkedInLogin}>
          <img src={Linkedin} alt="LinkedIn" />
          {linkedinText}
        </button>
      ) : null}

      {text ?
        <div className="link-to">
          {text}?<span className="link-text"><Link to={path}>{Other}</Link></span>
        </div> : null}
    </div>
  )
}

export default ExternalButton;
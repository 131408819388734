import React, { useMemo, useRef } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import { useSelector } from "react-redux";
import '../../../styles/globalStyle.css';

const PaymentDetail = ({ data, pagination, onPageChange }) => {
    
    const { current_page, total_pages } = pagination;
    const token = useSelector((state) => state.auth.token);
    const dropdownRef = useRef(null);

    const columns = useMemo(() => [
        {
            Header: "Amount",
            accessor: "amount",
            disableSortBy: false,
        },
        
        {
            Header: "Paid At",
            accessor: "paid_at",
            disableSortBy: false,
        },
    ], []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: current_page - 1 },
            manualPagination: true,
            pageCount: total_pages,
        },
        useSortBy,
        usePagination
    );

    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= total_pages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`page-btn ${i === current_page ? "active" : ""}`}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </button>
            );
        }

        return (
            <div className="pagination-container">
                <button
                    className={`page-btn ${current_page === 1 ? "disabled" : ""}`}
                    onClick={() => onPageChange(current_page - 1)}
                    disabled={current_page === 1}
                >
                    Prev
                </button>
                {pageNumbers}
                <button
                    className={`page-btn ${current_page === total_pages ? "disabled" : ""}`}
                    onClick={() => onPageChange(current_page + 1)}
                    disabled={current_page === total_pages}
                >
                    Next
                </button>
            </div>
        );
    };

    return (
        <>
            {renderPagination()}
            <table {...getTableProps()} className="data-table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} key={column.id}>
                                    <div className="header-content">
                                        {column.render("Header")}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {data.length === 0 ? (
                        <tr>
                            <td colSpan={columns.length} style={{ textAlign: "center", padding: "20px" }}>
                                No data to display
                            </td>
                        </tr>
                    ) : (
                        page.map((row, index) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={index}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()} key={cell.column.id}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
            {renderPagination()}
        </>
    );
};

export default PaymentDetail;

import Footer from "./Footer";
import HomeNav from "./NavBar";



const PrivacyPolicy = () => {
    return (
        <>
            <HomeNav />
            <section className="contact-section">
                <div className="contact-container">
                    <h1 className="contact-heading">Privacy Policy</h1>
                    <p className="privacy-subheading">This privacy policy has been compiled to better serve those who are concerned with how their ‘Personal Information’ is being used online. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect, or otherwise handle your Personal Information in accordance with our website.</p>

                    <h2>What personal information do we collect from the people that visit our website or app?</h2>
                    <p className="privacy-subheading">When registering on our site or uploading an image, you may be asked to enter your name, email address, or other details.</p>

                    <h2>When do we collect information?</h2>
                    <p className="privacy-subheading">We collect information when you register for our site.</p>

                    <h2>How do we protect your information?</h2>
                    <p className="privacy-subheading">We do not share any of your information with any third party.</p>

                    <h2>Do we use ‘cookies’?</h2>
                    <p className="privacy-subheading">Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>

                    <h3>We use cookies to:</h3>
                    <ul>
                        <li>Understand and save users’ preferences for future visits.</li>
                    </ul>
                    <p className="privacy-subheading">You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since each browser is a little different, check your browser’s Help Menu to learn the correct way to modify your cookies.</p>

                    <h2>Google</h2>
                    <p className="privacy-subheading">Google’s advertising requirements can be summed up by <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en" target="_blank">Google’s Advertising Principles</a>. They are put in place to provide a positive experience for users.</p>
                    <p className="privacy-subheading">We use Google AdSense Advertising on our website.</p>
                    <p className="privacy-subheading">Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the <a href="https://policies.google.com/technologies/ads" target="_blank">Google Ad and Content Network privacy policy</a>.</p>

                    <h3>We have implemented the following:</h3>
                    <ul>
                        <li>Remarketing with Google AdSense</li>
                        <li>Demographics and Interests Reporting</li>
                    </ul>
                    <p className="privacy-subheading">We, along with third-party vendors such as Google, use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.</p>

                    <h2>Other Advertisements</h2>
                    <p className="privacy-subheading">We also use third-party advertisements that may use cookies to show relevant ad results.</p>
                </div>
            </section>
            <Footer />
        </>

    )
}

export default PrivacyPolicy;
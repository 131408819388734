import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CommonLayout from './common/commonLayout';
import '../../styles/globalStyle.css';
import ExternalButton from './common/externalButton';
import HeaderSection from './common/headerSection';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgetPassword } from '../../services/operations/authAPI';
import Loader from '../loader/Loader';
import { setSignupData } from '../../slices/authSlice';
import { message } from '../messages/Messages';

const schema = yup.object().shape({
  email: yup.string().email(message.invalidEmailMessage).required(message.emailRequiredMessage).matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, message.invalidEmailMessage),
});


const ForgetPassword = () => {

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector((state) => state.auth.loading)

  const onSubmit = (data) => {
    const { email } = data;
    dispatch(setSignupData(email))
    dispatch(forgetPassword(email, navigate))
  };


  return (
    <CommonLayout title="Loog" pageType="forgetPassword">
      {loading && <Loader />}
      <HeaderSection text='Request New Password'
        text2='To request new password, please enter the email address of your ResumeLake Account' />
      <form onSubmit={handleSubmit(onSubmit)} className="login-form">
        <div className="form-group">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="text"
            id="email"
            placeholder='Enter your email'
            className={`form-input ${errors.email ? 'input-error' : ''}`}
            {...register('email')}
          />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <button type="submit" className="submit-button">
          Continue
        </button>
      </form>
      <ExternalButton text='Already have an account? ' Other='Log in' path='/login' />
    </CommonLayout>
  )
}

export default ForgetPassword
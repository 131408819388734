import React from 'react';

const SkeletonLoader = () => {
  return (
    <div className="list-items skeleton">
      <div className="skeleton-card">
        <div className="skeleton-card-left">
          <div className="skeleton-title shimmer"></div>
          <div className="skeleton-subtitle shimmer"></div>
          <div className="skeleton-subtext shimmer"></div>
          <div className="skeleton-text shimmer"></div>
        </div>
        <div className="skeleton-card-right">
        <div className="skeleton-title shimmer"></div>
        <div className="skeleton-text shimmer"></div>
          <div className="skeleton-text shimmer"></div>
          <div className="skeleton-text shimmer"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;




import closeIcon from '../../../assets/Close.png';
import { useState, useEffect } from 'react';
import '../../../styles/globalStyle.css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import hideIcon from '../../../assets/hideIcon.png';
import showIcon from '../../../assets/showIcon.png';
import { deleteProfilePicture, uploadProfile } from '../../../services/operations/ProfileAPI';
import toast from 'react-hot-toast';
import { message } from '../../messages/Messages';
import { createNewUser } from '../../../services/operations/AdminPageAPI';

const CreateUser = ({ isOpen, onClose, fetchRecruiters }) => {
    const user = useSelector((state) => state.profile.user);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
    });

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewSource, setPreviewSource] = useState(null)
    const [showPassword, setShowPassword] = useState(false);
    const [isChanged, setIsChanged] = useState(true);



    const maskEmail = (email) => {
        const [name, domain] = email.split('@');
        return `${name.slice(0, 2)}*****@${domain}`;
    };



    const watchedName = watch("Name");

    useEffect(() => {
        if (watchedName !== user.name || selectedFile) {
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
    }, [watchedName, selectedFile, user.name]);

    const handleDeletePicture = () => {
        dispatch(deleteProfilePicture(token));
    };


    const onSubmit = (data) => {
        const { Name, Email, Role, Password } = data;
        const payload = {
            name: Name,
            email: Email,
            role: Role,
            password: Password
        }

        dispatch(createNewUser(token, payload, onClose, fetchRecruiters))

    };

    return (
        <div className="account-overlay">
            <div className="account-content">
                <div className='account-heading'>
                    <h2>Create User</h2>
                    <img
                        src={closeIcon}
                        alt="Close"
                        className="close-icon"
                        onClick={onClose}
                    />
                </div>
                <form className="edit-user-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='form-type-pattern'>
                        <div className="form-type">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="Name"
                                maxLength={20}
                                name="Name"
                                placeholder='Enter Name'
                                className={`form-input ${errors.Name ? 'input-error' : ''}`}
                                style={{ width: "260px" }}
                                {...register("Name", {
                                    required: "Name is required",
                                    pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message: "Password must contain only alphabets and spaces"
                                    }
                                })}
                            />
                            {errors.Name && <p className="error-message">{errors.Name.message}</p>}
                        </div>
                        <div className="form-type">
                            <label htmlFor="Email">Email Id</label>
                            <input
                                type="text"
                                id="Email"
                                name="Email"
                                placeholder='Enter Email'
                                className={`form-input ${errors.Email ? 'input-error' : ''}`}
                                style={{ width: "260px" }}
                                {...register("Email", {
                                    required: message.emailRequiredMessage,
                                    pattern: {
                                        value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                        message: message.invalidEmailMessage
                                    }
                                })}
                            />
                            {errors.Email && <p className="error-message">{errors.Email.message}</p>}
                        </div>
                    </div>
                    <div className='form-type-pattern'>
                        <div className="form-type">
                            <label htmlFor="Password" >
                                Password
                            </label>
                            <div style={{ position: "relative" }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    maxLength={20}
                                    placeholder='Enter password'
                                    className={`form-input ${errors.Password ? 'input-error' : ''}`}
                                    style={{ width: "260px" }}
                                    {...register("Password", {
                                        required: message.passwordRequiredMessage,
                                        pattern: {
                                            value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w])[A-Za-z\d\W_]{8,}$/,
                                            message: message.passwordMatchMessage
                                        }
                                    })}
                                />
                                <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <img src={hideIcon} alt='hide' /> : <img src={showIcon} alt='show' />}
                                </span>
                            </div>
                            {errors.Password && <p className="error-message">{errors.Password.message}</p>}
                        </div>
                        <div className="form-type">
                            <label htmlFor="Role">
                                Role
                            </label>
                            <select
                                id="Role"
                                className={`form-input ${errors.Role ? 'input-error' : ''}`}
                                style={{ 'width': "260px" }}
                                {...register('Role', {
                                    required: "Role is required", 
                                  })}
                            >
                                <option value="" disabled selected>Select Role</option>
                                <option value="marketing">Marketing</option>
                                <option value="recruiter">Recruiter</option>
                            </select>
                            {errors.Role && <p className="error-message">{errors.Role.message}</p>}
                        </div>
                    </div>
                    <button
                        type="submit"
                        style={{width:"98%",marginTop:"10px",marginLeft:"0px"}}
                        className="save-button"
                        disabled={!isChanged}
                    >
                        Add User
                    </button>
                </form>
            </div>

        </div>

    );
};

export default CreateUser;

import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import '../../../styles/globalStyle.css';
import downIcon from '../../../assets/Polygon 2.png';
import upIcon from '../../../assets/Polygon 3.png';
import premiumIcon from '../../../assets/guarantee.png'
import threeDotsIcon from '../../../assets/dots-vertical.png';
import suspend from '../../../assets/user-x-01.png'
import disable from '../../../assets/Frame 263.png'
import roleIcon from '../../../assets/icons8-roles-24.png'
import active from '../../../assets/active-user.png'
import { updateRescruiterStatus, updateRoleStatus } from '../../../services/operations/AdminPageAPI'


const DataTable = ({ data, premium, pagination, onPageChange, fetchRecruiters, sortConfig, onSort }) => {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const dropdownRef = useRef(null);
    const token = useSelector((state) => state.auth.token)
    const dispatch = useDispatch()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                disableSortBy: false,
                Cell: ({ row }) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        {row?.original?.name}
                        {row?.original?.is_premium &&
                            (<img src={premiumIcon} width={20} height={20} alt='premium' />)}
                    </div>
                ),
            },
            {
                Header: 'Role',
                accessor: 'role',
                disableSortBy: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                disableSortBy: false,
                Cell: ({ value }) => (
                    <div className={`status-tab ${value.toLowerCase()}`}>
                        {value}
                    </div>
                ),
            },
            {
                Header: 'Email',
                accessor: 'email',
                disableSortBy: true,
            },
            {
                Header: 'Last Payment Date',
                accessor: '',
                disableSortBy: true,
            },
            {
                Header: '',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <div className="actions">
                        {row.original.status !== "removed" && (
                            <>
                                <img
                                    src={threeDotsIcon}
                                    alt="Actions"
                                    className="three-dots-icon"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDropdownOpen(dropdownOpen === row.id ? null : row.id);

                                    }}
                                />
                                {dropdownOpen === row.id && row.original.status !== "removed" && (
                                    <div className="dropdown-menu-status" style={{ "left": "-200px" }} ref={dropdownRef}>
                                        <div className="dropdown-item-status" onClick={() => handleUserStatus(row.original, "removed")}>
                                            <img src={suspend} alt='suspend' width={20} height={20} />Remove User
                                        </div>
                                        <div className="dropdown-item-status" onClick={() => handleUserStatus(row.original, "suspended")}>
                                            <img src={disable} alt="disable" width={20} height={20} /> Suspend User
                                        </div>
                                        {row.original.status === "suspended" && (
                                            <div className="dropdown-item-status" onClick={() => handleUserStatus(row.original, "active")}>
                                                <img src={active} alt='active' width={20} height={20} />Make User Active
                                            </div>)}
                                        <div className="dropdown-item-status" onClick={() => handleRoleChange(row.original, "superadmin")}>
                                            <img src={roleIcon} alt="role" width={20} height={20} />Role To SuperAdmin
                                        </div>
                                        <div className="dropdown-item-status" onClick={() => handleRoleChange(row.original, "marketing")}>
                                            <img src={roleIcon} alt='role' width={24} height={24} />Role To Marketing User
                                        </div>
                                    </div>
                                )}
                            </>

                        )}
                    </div>
                ),
                disableSortBy: true,
            },
        ],
        [dropdownOpen]
    );

    const handleUserStatus = async (user, role) => {
        await dispatch(updateRescruiterStatus(token, user.id, role))
        fetchRecruiters();
        setDropdownOpen(null);
    };


    const handleRoleChange = async (user, role) => {
        const payload = {
            user_id: user.id,
            role_name: role
        }
        await dispatch(updateRoleStatus(token, payload))
        fetchRecruiters();
        setDropdownOpen(null);
    }

    const handleRowClick = (row) => {
        const id = row.id;
        const minimalData = {
            id: id,
            name: row.name,
            recentPayment: row.recent_payment
        };
        const encodedData = btoa(encodeURIComponent(JSON.stringify(minimalData)));
        const detailsURL = `/admin-dashboard/payment-details?data=${encodedData}`;
        window.open(detailsURL, '_blank');

    }


    const handleSortClick = (column, order) => {
        const sort_name = column.id;
        const sort_order = order;
        onSort(sort_name, sort_order);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: pagination.current_page - 1 },
            manualPagination: true,
            pageCount: pagination.total_pages,
        },
        useSortBy,
        usePagination
    );


    const renderPagination = (showPremium = true) => {
        const { current_page, total_pages } = pagination;
        const pageNumbers = [];

        if (total_pages <= 5) {
            for (let i = 1; i <= total_pages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`page-btn ${i === current_page ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    className={`page-btn ${1 === current_page ? 'active' : ''}`}
                    onClick={() => onPageChange(1)}
                >
                    1
                </button>
            );

            if (current_page > 3) {
                pageNumbers.push(<span key="dots1">...</span>);
            }

            const startPage = Math.max(2, current_page - 1);
            const endPage = Math.min(total_pages - 1, current_page + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`page-btn ${i === current_page ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }

            if (current_page < total_pages - 2) {
                pageNumbers.push(<span key="dots2">...</span>);
            }

            pageNumbers.push(
                <button
                    key={total_pages}
                    className={`page-btn ${total_pages === current_page ? 'active' : ''}`}
                    onClick={() => onPageChange(total_pages)}
                >
                    {total_pages}
                </button>
            );
        }

        return (
            <div className='pagination-container' 
            style={{ justifyContent: showPremium ? "space-between" : "flex-end" }}>
                {showPremium && (
                    <h5 style={{ fontSize: "16px", fontWeight: "600", marginLeft: "8px" }}>
                        Premium Users&nbsp;
                        {premium?.filter(user => user.is_premium)?.length > 0 ? (
                            <span style={{ color: "#666666" }}>
                                ({premium.filter(user => user.is_premium).length})
                            </span>
                        ) : null}
                    </h5>
                )}
                <div style={{ display: "flex", gap: "10pxS" }}>
                    <button
                        className={`page-btn ${current_page === 1 ? 'disabled' : ''}`}
                        onClick={() => onPageChange(current_page - 1)}
                        disabled={current_page === 1}
                    >
                        Prev
                    </button>

                    {pageNumbers}

                    <button
                        className={`page-btn ${current_page === total_pages ? 'disabled' : ''}`}
                        onClick={() => onPageChange(current_page + 1)}
                        disabled={current_page === total_pages}
                    >
                        Next
                    </button>
                </div>
            </div>
        );
    };
    return (
        <>
            {renderPagination(true)}
            <table {...getTableProps()} className="data-table">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    <div className="header-content">
                                        {column.render('Header')}
                                        {!column.disableSortBy && (
                                            <div className="sort-icons">
                                                <img
                                                    src={downIcon}
                                                    alt="Sort Ascending"
                                                    className="sort-icon"
                                                    onClick={() => handleSortClick(column, "asc")}
                                                />
                                                <img
                                                    src={upIcon}
                                                    alt="Sort Descending"
                                                    className="sort-icon"
                                                    onClick={() => handleSortClick(column, "desc")}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {data?.length === 0 ? (
                        <tr>
                            <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}>
                                No data to display
                            </td>
                        </tr>
                    ) : (
                        page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    style={{ cursor: row.original.is_premium ? 'pointer' : 'default' }}
                                    onClick={() => row.original.is_premium && handleRowClick(row.original)}
                                >
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
            {renderPagination(false)}
        </>
    );
};

export default DataTable;

import Footer from "./Footer";
import HomeNav from "./NavBar";



const TermOfService = () => {
    return (
        <>
            <HomeNav />
            <section className="contact-section">
                <div className="contact-container">

                    <h1 className="contact-heading">Website Terms and Conditions of Use</h1>

                    <h2>1. Agreement to Terms</h2>
                    <p className="privacy-subheading">By accessing this website, available at <a href="https://www.1800hired.com/" target="_blank">https://www.1800hired.com/</a>, you acknowledge and agree to comply with these Terms and Conditions. It is your responsibility to ensure adherence to applicable local laws. If you do not agree with any part of these terms, please refrain from using this site.</p>
                    
                    <h2>2. Disclaimer</h2>
                    <p className="privacy-subheading">1800HIRED.com serves as a global database of resumes intended for recruiters and businesses to find suitable candidates. While we strive to maintain accurate and current information, we do not guarantee the completeness or reliability of the content provided.</p>
                    
                    <h2>3. Limitations of Liability</h2>
                    <p className="privacy-subheading">Neither 1800HIRED.com nor its suppliers shall be held responsible for any damages arising from the use or inability to use the materials on this website, even if 1800HIRED.com or an authorized representative has been advised of potential damages. Certain jurisdictions do not allow limitations on implied warranties or liability for incidental damages; therefore, some of these restrictions may not apply to you.</p>
                    
                    <h2>4. Content Accuracy and Updates</h2>
                    <p className="privacy-subheading">Materials available on 1800HIRED.com may include technical errors, typographical mistakes, or inaccuracies. The company does not guarantee that all information provided is precise, complete, or up to date. We reserve the right to modify the content at any time without notice but do not commit to making regular updates.</p>
                    
                    <h2>5. Content Management and Removal</h2>
                    <p className="privacy-subheading">1800HIRED.com retains the right to delete any content, including resumes, job listings, or company profiles, that violate our policies or applicable laws.</p>
                    
                    <h2>6. Privacy Policy</h2>
                    <p className="privacy-subheading">By accessing this website, you agree to our <a href="/privacy-policy" target="_blank">Privacy Policy</a>, which details how we collect, use, and safeguard your personal information.</p>
                    
                    <h2>7. Modifications to Terms</h2>
                    <p className="privacy-subheading">1800HIRED.com may update these Terms and Conditions at any time without prior notification. By continuing to use this website, you agree to abide by the most recent version of these terms.</p>
                  </div>

            </section>
            <Footer />
        </>

    )
}

export default TermOfService;
import React, { useState, useEffect, useMemo, useRef } from 'react';
import '../../../styles/globalStyle.css';
import search from '../../../assets/Group 33509.png';
import upload from '../../../assets/download-cloud-01.png';
import closeIcon from '../../../assets/Close.png';
import FileUploadModal from '../../recuiterDashboard/upload/fileUploadModal';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../loader/Loader';
import { getResumes, searchResume, sortResume, getSkills, getCompanies, getIndustries } from '../../../services/operations/ResumesAPI';
import { getUserDetails } from '../../../services/operations/ProfileAPI';
import { viewResume } from '../../../services/operations/ResumesAPI';
import toast from 'react-hot-toast';
import Card from 'react-bootstrap/Card';
import { FaLocationDot } from "react-icons/fa6";
import { MdWork } from "react-icons/md";
import { RiArrowDropRightFill } from "react-icons/ri";
import SkeletonLoader from '../../recuiterDashboard/ResumeSection/Skeleton';
import SideBar from '../common/SideBar';
import CustomPagination from '../../recuiterDashboard/ResumeSection/Pagination';
import eyeIcon from '../../../assets/icons8-eye-30.png'
import { Tooltip } from "react-tooltip";
import debounce from "lodash.debounce";

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

const Candidates = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const [isToggleOn, setIsToggleOn] = useState(true);
    const [loadData, setLoadData] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const [experienceInput, setExperienceInput] = useState("");
    const [filteredExperience, setFilteredExperience] = useState([]);
    const [selectedExperience, setSelectedExperience] = useState([]);
    const [selectedExperienceIndex, setSelectedExperienceIndex] = useState(-1);
    const [showExperienceSuggestions, setShowExperienceSuggestions] = useState(false);
    const [autocompleteType, setAutocompleteType] = useState('(cities)');
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('')
    const [selectedIndustry, setSelectedIndustry] = useState('')
    const [cityInput, setCityInput] = useState('');
    const [industryInput, setIndustryInput] = useState('');
    const [Skill, setSkill] = useState([]);
    const [company, setCompany] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [skillInput, setSkillInput] = useState('');
    const [compnayInput, setCompnayInput] = useState('');
    const [selectedCityIndex, setSelectedCityIndex] = useState(-1);
    const [selectedCompnayIndex, setSelectedCompnayIndex] = useState(-1)
    const [selectedIndustryIndex, setSelectedIndustryIndex] = useState(-1)
    const debouncedCityInput = useDebounce(cityInput, 300);
    const locationSuggestionRef = useRef(null);
    const skillSuggestionRef = useRef(null);
    const companySuggestionRef = useRef(null);
    const industrySuggestionRef = useRef(null);
    const experienceSuggestionRef = useRef(null);
    const [selectedSkillIndex, setSelectedSkillIndex] = useState(-1);
    const debouncedSkillInput = useDebounce(skillInput, 300);
    const debouncedCompanyInput = useDebounce(compnayInput, 300);
    const debouncedIndustryInput = useDebounce(industryInput, 300);
    const [selectedFilters, setSelectedFilters] = useState({
        experience: [],
        location: [],
        skills: [],
        company_name: [],
        industry_name: []
    });
    const buttonRef = useRef();
    const user = useSelector((state) => state.profile.user);
    const [result, setResult] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 15,
        total_pages: 1,
        total: ""
    });

    const loading = useSelector((state) => state.auth.loading);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const experienceOptions = ['0-2', '2-5', '5-7', '7-9', '9-12', '12+'];
    const GOOGLE_API_KEY = "AIzaSyAWtfgHuHrdpV86KW-_NKlwle3AmLeM4LM";
    const autocompleteRef = useRef(null);
    const abortControllerRef = useRef(null);
    const autocompleteInstanceRef = useRef(null);


    const filteredSkills = useMemo(() => {
        if (debouncedSkillInput.length < 2) return [];
        return Skill.filter((skill) =>
            skill.toLowerCase().includes(debouncedSkillInput.toLowerCase())
        );
    }, [debouncedSkillInput]);


    const filteredCompany = useMemo(() => {
        if (debouncedCompanyInput.length < 2) return [];
        return company.filter((company) =>
            company.toLowerCase().includes(debouncedCompanyInput.toLowerCase())
        );
    }, [debouncedCompanyInput]);

    const filteredIndustry = useMemo(() => {
        if (debouncedIndustryInput.length < 2) return [];
        return industry.filter((company) =>
            company.toLowerCase().includes(debouncedIndustryInput.toLowerCase())
        );
    }, [debouncedIndustryInput]);

    useEffect(() => {
        if (!window.google || !window.google.maps) return;
        if (!autocompleteInstanceRef.current && autocompleteRef.current) {
            autocompleteInstanceRef.current = new window.google.maps.places.Autocomplete(
                autocompleteRef.current,
                {
                    types: [autocompleteType],
                    fields: ['address_components', 'formatted_address', 'place_id'],
                    suppressDefaultStyles: true,
                }
            );
            autocompleteInstanceRef.current.addListener("place_changed", () => {
                const place = autocompleteInstanceRef.current.getPlace();
                if (place && place.address_components) {
                    const cityWithState = getCityName(place);
                    if (!selectedLocation.includes(cityWithState)) {
                        setCityInput("");
                        setSelectedLocation((prev) => [...prev, cityWithState])
                        handleFilterChange("location", cityWithState);
                    } else {
                        setCityInput("");
                    }
                }
            });
        }
    }, []);

    useEffect(() => {
        if (autocompleteInstanceRef.current) {
            autocompleteInstanceRef.current.setOptions({
                types: [autocompleteType],
            });
        }
    }, [autocompleteType]);

    const handleInputChange = (e) => {
        const input = e.target.value;
        setCityInput(input);
        if (input.length > 3) {
            setAutocompleteType('(regions)');
        } else {
            setAutocompleteType('(cities)');
        }
    };

    const getCityName = (place) => {
        const cityComponent = place.address_components.find((component) =>
            component.types.includes('locality')
        );
        const stateComponent = place.address_components.find((component) =>
            component.types.includes('administrative_area_level_1')
        );
        const countryComponent = place.address_components.find((component) =>
            component.types.includes('country')
        );

        const city = cityComponent ? cityComponent.long_name : '';
        const stateShort = stateComponent ? stateComponent.short_name : '';
        const stateLong = stateComponent ? stateComponent.long_name : '';
        const country = countryComponent ? countryComponent.long_name : '';

        if (city && stateShort) {
            return `${city}, ${stateShort}`;
        } else if (stateLong) {
            return stateLong;
        } else if (country) {
            return country;
        } else {
            return place.formatted_address;
        }
    };

    useEffect(() => {
        getSkill()
        getCompany()
        getIndustry()
    }, [])

    useEffect(() => {
        dispatch(getUserDetails(token));
    }, [dispatch, token]);

    useEffect(() => {
        // setPagination((prev) => ({ ...prev, current_page: 1 }))
        filterResumes(
            1,
            selectedFilters,
            searchText,
            sortField,
            sortOrder
        );
    }, [selectedFilters, isToggleOn, searchText, sortField, sortOrder]);

    useEffect(() => {
        filterResumes(pagination.current_page);
    }, [pagination.current_page])

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                locationSuggestionRef.current &&
                !locationSuggestionRef.current.contains(event.target)
            ) {
                setCityInput('');
            }
            if (
                experienceSuggestionRef.current &&
                !experienceSuggestionRef.current.contains(event.target)
            ) {
                setShowExperienceSuggestions(false);
                setExperienceInput('');
            }
            if (
                companySuggestionRef.current &&
                !companySuggestionRef.current.contains(event.target)
            ) {
                setCompnayInput('');
            }
            if (
                industrySuggestionRef.current &&
                !industrySuggestionRef.current.contains(event.target)
            ) {
                setIndustryInput('');
            }
            if (
                skillSuggestionRef.current &&
                !skillSuggestionRef.current.contains(event.target)
            ) {
                setSkillInput('');
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleCityCheckbox = (cityName, mainText) => {
        setSelectedLocation((prev) =>
            prev.includes(mainText)
                ? prev.filter((selectedCity) => selectedCity !== mainText)
                : [...prev, mainText]
        );
        handleFilterChange("location", mainText);
    };

    const fetchResumes = () => { }


    const debouncedFilterResumes = debounce((page, filters, text) => {
        filterResumes(page, filters, text);
    }, 300);

    const filterResumes = async (page = 1, filters = selectedFilters, text = searchText) => {
        setLoadData(true);
        const payload = {
            ...filters,
            text,
            global_search: isToggleOn,
            sort_field: sortField,
            sort_order: sortOrder,
        };

        const response = await dispatch(getResumes(token, page, payload));
        if (response?.data?.resumes) {
            setLoadData(false);
            setResult(response.data.resumes);
            setPagination({
                current_page: response.data.pagination.current_page,
                per_page: response.data.pagination.per_page,
                total_pages: response.data.pagination.total_pages,
                total: response.data.pagination.total_resumes,
            });

        }
    };

    const getSkill = async () => {
        const result = await dispatch(getSkills(token))
        if (result?.data?.skills) {
            setSkill(result.data.skills)
        }
    }

    const getCompany = async () => {
        const result = await dispatch(getCompanies(token))
        if (result?.data?.companies) {
            setCompany(result.data.companies)
        }
    }

    const getIndustry = async () => {
        const result = await dispatch(getIndustries(token))
        if (result?.data?.industries) {
            setIndustry(result.data.industries)
        }
    }


    const handleExperienceChange = (e) => {
        const input = e.target.value;
        if (input && !/^\d*\.?\d+$/.test(input)) return;

        setExperienceInput(input);

        if (!input) {
            setFilteredExperience(experienceOptions);
            setShowExperienceSuggestions(true);
            setSelectedExperienceIndex(-1);
            return;
        }

        const numericValue = parseFloat(input);

        if (!isNaN(numericValue)) {
            const matchedRanges = experienceOptions.filter((range) => {
                const [min, max] = range.includes("+")
                    ? [12, Infinity]
                    : range.split("-").map(Number);
                return numericValue >= min && numericValue <= max;
            });

            setFilteredExperience(matchedRanges.length > 0 ? matchedRanges : ["12+"]);
        } else {
            setFilteredExperience(experienceOptions);
        }

        setShowExperienceSuggestions(true);
        setSelectedExperienceIndex(-1);
    };


    const handleSkillsChange = (e) => {
        setSkillInput(e.target.value);
        setSelectedSkillIndex(-1)
    };

    const handleCompnayChange = (e) => {
        setCompnayInput(e.target.value);
        setSelectedCompnayIndex(-1)
    };
    const handleIndustryChange = (e) => {
        setIndustryInput(e.target.value);
        setSelectedIndustryIndex(-1)
    };

    const handleSkillSelect = (skill) => {
        setSelectedSkills((prev) =>
            prev.includes(skill)
                ? prev.filter((selectedSkill) => selectedSkill !== skill)
                : [...prev, skill]
        );
        handleFilterChange('skills', skill);
    };

    const handleCompnaySelect = (company) => {
        setSelectedCompany(company);
        handleFilterChange('compnany_name', company);
        setCompnayInput('');
        setSelectedCompnayIndex(-1);
    };

    const handleIndustrySelect = (industry) => {
        setSelectedIndustry(industry);
        handleFilterChange('industry_name', industry);
        setIndustryInput('');
        setSelectedIndustryIndex(-1);
    };

    const handleExperienceCheckbox = (experience) => {
        setSelectedExperience((prev) =>
            prev.includes(experience)
                ? prev.filter((item) => item !== experience)
                : [...prev, experience]
        );
        handleFilterChange("experience", experience);
    };

    const handleSkillCheckbox = (skill) => {
        setSelectedSkills((prev) =>
            prev.includes(skill)
                ? prev.filter((selectedSkill) => selectedSkill !== skill)
                : [...prev, skill]
        );
        handleFilterChange('skills', skill);
    };

    const handleCompanyCheckbox = (company) => {
        setSelectedCompany((prev) =>
            prev.includes(company)
                ? prev.filter((selectedCompany) => selectedCompany !== company)
                : [...prev, company]
        );
        handleFilterChange('company_name', company);
    };

    const handleIndustrycheckbox = (industry) => {
        setSelectedIndustry((prev) =>
            prev.includes(industry)
                ? prev.filter((selectedIndustry) => selectedIndustry !== industry)
                : [...prev, industry]
        );
        handleFilterChange('industry_name', industry);
    };

    const handleClear = () => {
        setSearchText("");
    };

    const handleToggle = () => {
        setIsToggleOn((prevState) => !prevState);
    };

    const handleSort = ({ sortField, sortOrder }) => {
        setSortField(sortField);
        setSortOrder(sortOrder);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };



    const handleFilterChange = (type, value) => {
        if (type === "location") {

            setSelectedLocation((prev) => {
                if (prev.includes(value)) {
                    return prev;
                }
                return [...prev, value];
            });

            setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                location: prevFilters.location?.includes(value)
                    ? prevFilters.location
                    : [...(prevFilters.location || []), value],
            }));

            return;
        }
        setSelectedFilters((prevFilters) => {
            const currentFilters = prevFilters[type] || [];
            const isSelectedBefore = currentFilters.includes(value);

            const updatedFilters = isSelectedBefore
                ? currentFilters.filter((item) => item !== value)
                : [...currentFilters, value];

            return {
                ...prevFilters,
                [type]: updatedFilters,
            };
        });
    };


    const handleRemoveFilter = (type, value) => {
        setSelectedFilters((prevFilters) => {
            const updatedFilters = {
                ...prevFilters,
                [type]: prevFilters[type].filter((item) => item !== value),
            };
            if (type === 'experience') {
                setSelectedExperience((prev) => prev.filter((experience) => experience !== value));
            }
            if (type === 'skills') {
                setSelectedSkills((prev) => prev.filter((skill) => skill !== value));
            }
            if (type === 'location') {
                setSelectedLocation((prev) => prev.filter((city) => city !== value));
            }
            if (type === 'company_name') {
                setSelectedCompany((prev) => prev.filter((company) => company !== value));
            }
            if (type === 'industry_name') {
                setSelectedIndustry((prev) => prev.filter((industry) => industry !== value));
            }

            const areAllFiltersEmpty = Object.values(updatedFilters).every(
                (filter) => filter.length === 0
            );

            // if (areAllFiltersEmpty) {
            //     filterResumes();
            // }

            if (areAllFiltersEmpty) {
                debouncedFilterResumes(1, {}, '');
            } else {
                debouncedFilterResumes(1, updatedFilters, searchText);
            }
            return updatedFilters;
        });
    };



    const handlePageChange = (newPage) => {
        if (!loadData && newPage !== pagination.current_page) {
            setPagination((prev) => ({
                ...prev,
                current_page: newPage,
            }));
        }
    };

    const handleResetFilters = () => {
        setSelectedFilters({
            experience: [],
            location: [],
            skills: [],
        });
        setSelectedExperience('');
        setSelectedLocation([]);
        setSelectedCompany('');
        setSelectedSkills('');
        setSelectedIndustry('')
        setCityInput('');
        setExperienceInput('')
        setSkillInput('');
        setIndustryInput('');
        setCompnayInput('')
        setSearchText('');
        debouncedFilterResumes(1, {}, '');
        //  filterResumes()
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const highlightText = (text, keyword) => {
        if (!keyword) return text;

        const escapedSearchText = searchText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

        const regex = new RegExp(`(${escapedSearchText})`, 'gi');

        return text?.split(regex).map((part, index) =>
            regex.test(part) ? (
                <span key={index} className="highlight">{part}</span>
            ) : (
                part
            )
        );
    };

    const handleExperienceKeyDown = (e) => {

        if (filteredExperience.length === 0) return;

        if (e.key === 'ArrowDown') {
            setSelectedExperienceIndex((prevIndex) =>
                prevIndex < filteredExperience.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            setSelectedExperienceIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredExperience.length - 1
            );
        } else if (e.key === 'Enter' && selectedExperienceIndex >= 0) {
            const selectedExperience = filteredExperience[selectedExperienceIndex];
            handleExperienceCheckbox(selectedExperience);
        }
        setTimeout(() => {
            const activeElement = document.querySelector(
                ".skill-suggestions .highlighted"
            );
            if (activeElement) {
                activeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }, 0);
    };

    const handleSkillKeyDown = (e) => {
        if (filteredSkills.length === 0) return;

        if (e.key === 'ArrowDown') {
            setSelectedSkillIndex((prevIndex) =>
                prevIndex < filteredSkills.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            setSelectedSkillIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredSkills.length - 1
            );
        } else if (e.key === 'Enter' && selectedSkillIndex >= 0) {
            const selectedSkill = filteredSkills[selectedSkillIndex];
            handleSkillCheckbox(selectedSkill);
        }
        setTimeout(() => {
            const activeElement = document.querySelector(
                ".skill-suggestions .highlighted"
            );
            if (activeElement) {
                activeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }, 0);
    };

    const handleCompanyKeyDown = (e) => {
        if (filteredCompany.length === 0) return;
        if (e.key === 'ArrowDown') {
            setSelectedCompnayIndex((prevIndex) =>
                prevIndex < filteredCompany.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            setSelectedCompnayIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredCompany.length - 1
            );
        } else if (e.key === 'Enter' && selectedCompnayIndex >= 0) {
            const selectedCompany = filteredCompany[selectedCompnayIndex];
            handleCompanyCheckbox(selectedCompany)
        }
        setTimeout(() => {
            const activeElement = document.querySelector(
                ".skill-suggestions .highlighted"
            );
            if (activeElement) {
                activeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }, 0);
    };

    const handleIndustryKeyDown = (e) => {
        if (filteredIndustry.length === 0) return;
        if (e.key === 'ArrowDown') {
            setSelectedIndustryIndex((prevIndex) =>
                prevIndex < filteredIndustry.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            setSelectedIndustryIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredIndustry.length - 1
            );
        } else if (e.key === 'Enter' && selectedIndustryIndex >= 0) {
            const selectedIndustry = filteredIndustry[selectedIndustryIndex];
            handleIndustrycheckbox(selectedIndustry)
        }
        setTimeout(() => {
            const activeElement = document.querySelector(
                ".skill-suggestions .highlighted"
            );
            if (activeElement) {
                activeElement.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                });
            }
        }, 0);
    };

    const handleRowClick = async (id, experience, location, designation) => {
        const minimalData = {
            id: id,
            location: location,
            experience: experience,
            designation: designation,
            highlightskill: selectedFilters.skills,
            highlightCompany: selectedFilters.company_name,
            highlightIndustry: selectedFilters.industry_name,
            highlightLocation:selectedFilters.location
        };
        const payload = {
            resume_id: id
        }
        const ressult = await dispatch(viewResume(token, payload))
        if (ressult?.status === 200 || ressult?.status === 201) {
            const encodedData = btoa(encodeURIComponent(JSON.stringify(minimalData)));
            const detailsURL = `/admin-dashboard/row-details?data=${encodedData}`;
            await dispatch(getUserDetails(token))
            window.open(detailsURL, '_blank');

        }
        else {
            await dispatch(getUserDetails(token))
        }
    }

    return (
        <div className="admin-dashboard-container" style={{ padding: "0px", backgroundColor: "white" }}>
            <SideBar user={user} />
            <div className="admin-dashboard-content" style={{ padding: "0px", marginLeft: "0px" }}>
                <div className="resume-header">
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "10px", whiteSpace: "nowrap" }}>
                        <div className='filters'>
                            <div className="search-container">
                                <img src={search} alt="Search" className="search-icon" />
                                <input
                                    type="text"
                                    id="search"
                                    placeholder="Job title or keyword"
                                    className="search-bar"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                />
                                {searchText && (
                                    <img src={closeIcon} className='cross-icon-search-bar' alt='closeIcon' onClick={handleClear} />
                                )}
                            </div>
                            <div className="filter-dropdown">
                                <input
                                    id="experience"
                                    placeholder="Experience"
                                    className="location-input"
                                    autoComplete="off"
                                    value={experienceInput}
                                    onChange={handleExperienceChange}
                                    onKeyDown={handleExperienceKeyDown}
                                />
                                {showExperienceSuggestions && filteredExperience.length > 0 && (
                                    <div ref={experienceSuggestionRef} className="skill-suggestions">
                                        {filteredExperience.map((experience, index) => (
                                            <div
                                                key={index}
                                                className={`skill-suggestion ${index === selectedExperienceIndex ? "highlighted" : ""
                                                    }`}
                                                onMouseEnter={() => setSelectedExperienceIndex(index)}
                                                onClick={(e) => {
                                                    if (e.target.type !== "checkbox") handleExperienceCheckbox(experience);
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-list"
                                                    checked={selectedExperience.includes(experience)}
                                                    onChange={() => handleExperienceCheckbox(experience)}
                                                />
                                                <label className="skill-label">{experience}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {/* <div className="filter-dropdown">
                            <input
                                id="location"
                                placeholder="Location"
                                className="location-input"
                                autoComplete="off"
                                value={cityInput}
                                onChange={handleCityInputChange}
                                onKeyDown={handleCityKeyDown}
                            />
                            {filteredCities.length > 0 && (
                                <div ref={locationSuggestionRef} className="skill-suggestions">
                                    {filteredCities.map((city, index) => (
                                        <div
                                            key={index}
                                            className={`skill-suggestion ${index === selectedCityIndex ? 'highlighted' : ''}`}
                                            onMouseEnter={() => setSelectedCityIndex(index)}
                                            onClick={(e) => {
                                                if (e.target.type !== 'checkbox') handleCityCheckbox(city.name);
                                            }}

                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedLocation.includes(city.name)}
                                                onChange={() => handleCityCheckbox(city.name)}
                                            />
                                            <label
                                                className="skill-label">
                                                {city.name}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div> */}
                            {/* 
<div className="filter-dropdown">
            <input
                id="location"
                placeholder="Location"
                className="location-input"
                autoComplete="off"
                value={cityInput}
                onChange={handleCityInputChange}
                onKeyDown={handleCityKeyDown}
            />
            {filteredCities.length > 0 && (
    <div ref={locationSuggestionRef} className="skill-suggestions">
        {filteredCities.map((city, index) => (
            <div
                key={city.placeId}
                className={`skill-suggestion ${
                    index === selectedCityIndex ? "highlighted" : ""
                }`}
                onMouseEnter={() => setSelectedCityIndex(index)}
                onClick={() => handleCityCheckbox(city.name, city.mainText)}
            >
                <input
                    type="checkbox"
                    checked={selectedLocation.includes(city.mainText)} 
                    onChange={() => handleCityCheckbox(city.name, city.mainText)}
                />
                <label className="skill-label">
                    <span className="main-text">{city.mainText}</span>,{" "}
                    <span className="secondary-text">{city.secondaryText}</span>
                </label>
            </div>
        ))}
    </div>
)}

            

        </div> */}

                            <div className="filter-dropdown">
                                <input
                                    ref={autocompleteRef}
                                    placeholder="Location"
                                    className="location-input"
                                    value={cityInput}
                                    onChange={handleInputChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="filter-dropdown">
                                <input
                                    id="skills"
                                    placeholder="Skill"
                                    className="location-input"
                                    autoComplete="off"
                                    value={skillInput}
                                    onChange={handleSkillsChange}
                                    onKeyDown={handleSkillKeyDown}
                                />
                                {filteredSkills.length > 0 && (
                                    <div ref={skillSuggestionRef} className="skill-suggestions">
                                        {filteredSkills.map((skill, index) => (
                                            <div
                                                key={index}
                                                className={`skill-suggestion ${index === selectedSkillIndex ? 'highlighted' : ''}`}
                                                onMouseEnter={() => setSelectedSkillIndex(index)}
                                                onClick={(e) => {
                                                    if (e.target.type !== 'checkbox') handleSkillCheckbox(skill);
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className='checkbox-list'
                                                    checked={selectedSkills.includes(skill)}
                                                    onChange={() => handleSkillCheckbox(skill)}
                                                />
                                                <label className='skill-label'>{skill}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="filter-dropdown">
                                <input
                                    id="company_name"
                                    placeholder="Company"
                                    className="location-input"
                                    autoComplete="off"
                                    value={compnayInput}
                                    onChange={handleCompnayChange}
                                    onKeyDown={handleCompanyKeyDown}
                                />
                                {filteredCompany.length > 0 && (
                                    <div ref={companySuggestionRef} className="skill-suggestions">
                                        {filteredCompany.map((company, index) => (
                                            <div
                                                key={index}
                                                className={`skill-suggestion ${index === selectedCompnayIndex ? 'highlighted' : ''}`}
                                                onMouseEnter={() => setSelectedCompnayIndex(index)}
                                                onClick={(e) => {
                                                    if (e.target.type !== 'checkbox') handleCompanyCheckbox(company);
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className='checkbox-list'
                                                    checked={selectedCompany.includes(company)}
                                                    onChange={() => handleCompanyCheckbox(company)}
                                                />
                                                <label className='skill-label'>{company}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="filter-dropdown">
                                <input
                                    id="industry_name"
                                    placeholder="Industry"
                                    className="location-input"
                                    autoComplete="off"
                                    value={industryInput}
                                    onChange={handleIndustryChange}
                                    onKeyDown={handleIndustryKeyDown}
                                />
                                {filteredIndustry.length > 0 && (
                                    <div ref={industrySuggestionRef} className="skill-suggestions">
                                        {filteredIndustry.map((industry, index) => (
                                            <div
                                                key={index}
                                                className={`skill-suggestion ${index === selectedIndustryIndex ? 'highlighted' : ''}`}
                                                onMouseEnter={() => setSelectedIndustryIndex(index)}
                                                onClick={(e) => {
                                                    if (e.target.type !== 'checkbox') handleIndustrycheckbox(industry);
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className='checkbox-list'
                                                    checked={selectedIndustry.includes(industry)}
                                                    onChange={() => handleIndustrycheckbox(industry)}
                                                />
                                                <label className='skill-label'>{industry}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="resume-controls">
                            <span>{isToggleOn ? <p className='toggle-switch' onClick={handleToggle}>Show My Uploads</p> : <p className='toggle-switch' onClick={handleToggle}>Show All Resumes</p>}</span>
                            <button className="upload-btn" onClick={handleOpenModal}>
                                <img src={upload} alt="Upload" />
                                <span>Upload</span>
                            </button>
                        </div>
                    </div>
                    {selectedFilters !== null &&
                        <div className='tag'>
                            <div className="selected-filters">
                                {Object.entries(selectedFilters).map(([type, values]) => (
                                    values.map((value, index) => (
                                        <div key={index} className="filter-tab">
                                            {value}
                                            <span onClick={() => handleRemoveFilter(type, value)}>✖</span>
                                        </div>
                                    ))
                                ))}

                            </div>
                            {Object.values(selectedFilters).some(arr => arr.length > 0) && (
                                <button className="reset-tab" onClick={handleResetFilters}>
                                    Reset filters
                                </button>
                            )}
                        </div>
                    }
                </div>
                <div className="resume">
                    {
                        false &&
                        <div className='resume-ad'>
                        </div>
                    }
                    <div className="resume-container">

                        <div className="resume-title">
                            <div className='resume-count'>
                                {isToggleOn ? (
                                    <>
                                        All Resumes{" "}
                                        {pagination?.total && <span style={{ color: "#666666" }}>({pagination.total})</span>}
                                    </>
                                ) : (
                                    <>
                                        My Resumes{" "}
                                        {pagination?.total && <span style={{ color: "#666666" }}>({pagination.total})</span>}
                                    </>
                                )}
                                &nbsp;
                            </div>
                            {result?.length > 0 &&
                                <CustomPagination
                                    totalPages={pagination.total_pages}
                                    currentPage={pagination.current_page}
                                    onPageChange={handlePageChange}
                                    isLoading={loadData} />}
                        </div>
                        <div class="list-container">
                            {loadData ? (
                                <div className="skeleton-container fade-in">
                                    {Array.from({ length: 3 }).map((_, index) => (
                                        <SkeletonLoader key={index} />
                                    ))}
                                </div>
                            ) : result && result.length > 0 ?
                                (result.map((resumedata, index) => {
                                    var allSkills = resumedata.skills
                                    const skillsFilter = selectedFilters.skills;
                                    var matchedSkills = [];
                                    var remainingSkills = [];
                                    var newSkills = []; 
                                    var isMatched = false;
                                    if (skillsFilter.length > 0) {
                                        skillsFilter.forEach(searchedItems => {
                                            allSkills.forEach(SkillItems => {
                                                var skillset = SkillItems.toLowerCase();
                                                if (skillset.indexOf(searchedItems.toLowerCase()) >= 0) {
                                                    matchedSkills.push(SkillItems);
                                                    isMatched = true;
                                                    allSkills = allSkills.filter(item => item !== SkillItems);
                                                }
                                                /*else {
                                                    remainingSkills.push(SkillItems);
                                                }*/
                                            });
                                        });
                                        newSkills = matchedSkills.concat(allSkills || []);
                                    }
                                    else {
                                        isMatched = false;
                                        newSkills = allSkills || []; 
                                    }
                                    matchedSkills = [];
                                    remainingSkills = [];

                                    return (
                                        <div class="list-items">
                                            <Card>
                                                <Card.Body class="card-left">
                                                    <Card.Title onClick={() => handleRowClick(resumedata.id, resumedata.candidate_experience, resumedata.location, resumedata.designation)} >{highlightText(resumedata.candidate_name, searchText)}<span style={{ marginLeft: "15px", position: "relative", top: "3px" }}>{resumedata?.is_view === true && (
                                                        <>
                                                            <img
                                                                src={eyeIcon}
                                                                alt='viewed'
                                                                id={`view-tooltip-${resumedata.id}`}
                                                                height={20}
                                                                width={20}
                                                            />
                                                            <Tooltip
                                                                anchorId={`view-tooltip-${resumedata.id}`}
                                                                content="Viewed"
                                                                place="top"
                                                                className="custom-tooltip"
                                                            />
                                                        </>
                                                    )}</span></Card.Title>
                                                    <Card.Subtitle className="designation">
                                                        {
                                                            (() => {
                                                                const isLocationHighlighted = selectedFilters?.location?.some(filter => resumedata.location?.toLowerCase().includes(filter.toLowerCase()));
                                                                return (
                                                                    <span className="designation-container">
                                                                        {highlightText(resumedata.designation, searchText)}
                                                                        <FaLocationDot />
                                                                        <span className={`location ${isLocationHighlighted ? 'highlight' : ''}`}>
                                                                            {highlightText(resumedata.location, searchText)}
                                                                        </span>
                                                                    </span>
                                                                );
                                                            })()
                                                        }
                                                    </Card.Subtitle>
                                                    <Card.Text class="experience"> Experience: {resumedata.candidate_experience} Year(s)</Card.Text>
                                                    <Card.Text class="skills">
                                                        Skills:
                                                        {
                                                            (() => {
                                                                const visibleSkills = Array.isArray(newSkills) ? newSkills.slice(0, 15) : [];
                                                                const remainingSkillsCount = newSkills.length - 15;

                                                                return (
                                                                    <>
                                                                        {visibleSkills.map((skill, index) => {
                                                                            const isHighlighted = skillsFilter.some(
                                                                                (filterSkill) =>
                                                                                    skill.toLowerCase().includes(filterSkill.toLowerCase())
                                                                            );
                                                                            return (
                                                                                <span
                                                                                    key={index}
                                                                                    className={`skill-badge ${isHighlighted ? 'highlight' : ''}`}
                                                                                >
                                                                                    {highlightText(skill, searchText)}
                                                                                </span>
                                                                            );
                                                                        })}
                                                                        {remainingSkillsCount > 0 && (
                                                                            <span className="skill-badge">+{remainingSkillsCount}</span>
                                                                        )}
                                                                    </>
                                                                );
                                                            })()}
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Body class="card-right">
                                                    <Card.Subtitle className="work-history-heading"><div class="work-icon"><MdWork /></div>
                                                        <div class="work-history-card">Work History</div></Card.Subtitle>
                                                    <Card.Text class='work-history'>
                                                        {
                                                            resumedata.work_experience.map((work) => {
                                                                const isHighlighted = selectedFilters?.company_name?.some(filter =>
                                                                    work?.company?.toLowerCase().includes(filter.toLowerCase())
                                                                );
                                                                return (
                                                                    <div key={index} className="work-item">
                                                                        <span className="work-badge"><b className={`company ${isHighlighted ? 'highlight' : ''}`}><RiArrowDropRightFill />
                                                                            {highlightText(work.company, searchText)}</b> - {work.designation} - From {work.duration} </span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    )
                                }))
                                : (
                                    <div className="no-list-items">
                                        There is no data to display
                                    </div>
                                )
                            }
                            {result?.length > 0 &&
                                <CustomPagination
                                    totalPages={pagination.total_pages}
                                    currentPage={pagination.current_page}
                                    onPageChange={handlePageChange}
                                    isLoading={loadData}
                                />}
                        </div>
                    </div>
                </div>
                <FileUploadModal fetchResumes={filterResumes} isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>



    );
};

export default Candidates;

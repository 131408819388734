import React, { useState } from 'react';
import '../../../styles/globalStyle.css';
import closeIcon from '../../../assets/Close.png';
import uploadIcon from '../../../assets/Rectangle 22.png';
import shareIcon from '../../../assets/Share.png';
// import toast from 'react-hot-toast';
import {toast} from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { upload } from '../../../services/operations/uploadAPI';
import Loader from '../../loader/Loader';

const FileUploadModal = ({ isOpen, onClose, fetchResumes }) => {
    const [files, setFiles] = useState([]);
    const [totalProgress, setTotalProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [selectionCompleted, setSelectionCompleted] = useState(false)
    const [uploadedFilesCount, setUploadedFilesCount] = useState(0);
    //const MAX_FILE_SIZE = 25 * 1024 * 1024;
    const SUPPORTED_FORMATS = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const MAX_FILES_PER_REQUEST =20;
    const MAX_NO_OF_FILES=200;
    const token = useSelector((state) => state.auth.token);
    const user=useSelector((state)=>state.profile.user)
    const loading = useSelector((state) => state.auth.loading);
    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        handleFiles(selectedFiles);
        
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        handleFiles(droppedFiles);

    };
    const handleFiles =  async (fileList) => {
        const invalidFiles = fileList.filter((file) => !SUPPORTED_FORMATS.includes(file.type));
        const validFiles = fileList.filter((file) => SUPPORTED_FORMATS.includes(file.type));

        setFiles(validFiles);
        setSelectionCompleted(true);
        setTotalProgress(0);
        setUploadedFilesCount(0);

        await handleUpload(validFiles)

        if (invalidFiles.length > 0) {
            toast.error(`${invalidFiles.length} file(s) were invalid and not uploaded.`);
          }
        
    };


    const formatFileSize = (size) => {
        if (size < 1024) return size + ' bytes';
        if (size < 1024 * 1024) return (size / 1024).toFixed(2) + ' KB';
        return (size / (1024 * 1024)).toFixed(2) + ' MB';
    };

    const handleUpload = async (fileList) => {

    const fileCount=fileList.length;

    if (fileCount > MAX_NO_OF_FILES) {
        toast.error(`You tried uploding ${fileCount} files, but maximum ${MAX_NO_OF_FILES} files allowed per request.`);
        handleCancel();
        handleModalClose();
        return false;
    }
    setIsUploading(true);
    setTotalProgress(0);
    const fileChunks = [];
    for (let i = 0; i < fileList.length; i += MAX_FILES_PER_REQUEST) {
        fileChunks.push(fileList.slice(i, i + MAX_FILES_PER_REQUEST));
    }

    let overallProgress = 0; 
    let messageShown = false;
    const currentDateTime = new Date().toISOString();

    for (let i = 0; i < fileChunks.length; i++) {
        const chunk = fileChunks[i];
        const formData = new FormData();

        chunk.forEach((file) => {
            formData.append('files[]', file);
        });

        const userId = user.id 
        
        formData.append('userId', userId); 
        formData.append('uploadDateTime',btoa(JSON.stringify(currentDateTime)));
        formData.append('fileCount',fileCount)

        const interval = setInterval(() => {
            setTotalProgress((prev) => {
                if (prev >= 90) {
                    clearInterval(interval);
                    return prev;
                }
                return prev + 10;
            });
        }, 200);


       const result= await dispatch(upload(
            token,
            formData,
            (chunkProgress) => {
                const chunkWeight = 100 / fileChunks.length;
                overallProgress = Math.min(
                    overallProgress + (chunkProgress / 100) * chunkWeight,
                    100
                );
                setTotalProgress(Math.round(overallProgress));
            },
            setIsUploading,
            setSelectionCompleted,
            setFiles,
            handleModalClose
        ));
        clearInterval(interval);
        if (result  && !messageShown) {
            messageShown = true;
            toast.success(result,
                {style: {
                  maxWidth: "550px",
                  width: "auto", 
                  whiteSpace: "normal", 
                  wordWrap: "break-word", 
              },});
        }
    }
};
    const handleCancel = () => {
        setFiles([]);
        setTotalProgress(0);
        setIsUploading(false);
        setSelectionCompleted(false);
    };

    const handleModalClose = () => {
        handleCancel();
        onClose();
    };


    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            {loading && <Loader />}
            <div className="modal-content" style={{ width: "860px"}}>
                <div className='upload-heading'>
                    <h6>Upload Resume</h6>
                    <img src={closeIcon} alt="Close" className="close-icon" onClick={handleModalClose} />
                </div>

                {!files.length ? (
                    <div
                        className="drag-drop-area"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                        onClick={(e) => {
                            e.stopPropagation(); 
                            document.getElementById("fileInput").click();
                          }} 
                    >
                        <div className="icon-container">
                            <img
                                src={uploadIcon}
                                alt="Upload"
                                className="upload-icon"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    document.getElementById("fileInput").click();
                                  }}
                                style={{ cursor: 'pointer' }}
                            />
                            <img
                                src={shareIcon}
                                alt="Share"
                                className="share-icon"
                                onClick={() => document.getElementById('fileInput').click()}
                                style={{ cursor: 'pointer', position: 'relative', top: '20px', right: '10px' }}
                            />
                            <input
                                id="fileInput"
                                type="file"
                                onChange={handleFileChange}
                                multiple
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                ) : files.length === 1 ? (
                    <div className="file-info">
                        <div className="file-details">
                            <div className="file-size">
                                <p>{files[0].name}</p>
                                <span>{formatFileSize(files[0].size)}</span>
                            </div>
                            {/* <img src={closeIcon} alt='close' style={{ width: "24px", height: "24px" }} onClick={handleCancel} /> */}
                        </div>
                        <div className="progress-bar-containers">
                            <div
                                className="progress-bars"
                            >
                                <div style={{
                                    width: `${totalProgress}%`,
                                    backgroundColor: "#002DCC",
                                    transition: "width 0.25s ease-in-out",
                                    height: "8px"
                                }}></div>
                            </div>
                            <span>{totalProgress}%</span>
                        </div>
                    </div>
                ) : (
                    <div className="file-info">
                        <div className="file-details">
                            <div className='file-size'>
                                <p>{`Uploading ${files.length} files`}</p>


                            </div>
                            {/* <img src={closeIcon} alt='close' style={{ width: "24px", height: "24px" }} onClick={handleCancel} /> */}
                        </div>
                        <div className="progress-bar-containers"> 
                            <div className="progress-bars">
                                <div
                                    style={{
                                        width: `${totalProgress}%`,
                                        backgroundColor: "#002DCC",
                                        transition: "width 0.25s ease-in-out",
                                        height: "8px",
                                    }}
                                ></div>
                            </div>
                            <span>{totalProgress}%</span>
                        </div>
                    </div>
                )}

                <div className='upload-detail'>
                    <p>Supported formats: .pdf, .Doc, .Docx (Upload a maximum of 200 resumes in one batch)</p>
                </div>
                <div className="button-group">
                    {/* <button onClick={handleCancel} disabled={isUploading} className='cancel'>
                        Cancel
                    </button> */}
                    {/* <button
                        className="upld-btn"
                        onClick={handleUpload}
                        disabled={!selectionCompleted || isUploading}
                    >
                        Upload
                    </button> */}
                </div>
            </div>
        </div>
    );
};

export default FileUploadModal;

import upload from '../../assets/upload.png';
import { useState } from 'react';
import download from '../../assets/download-cloud-01.png'
import { useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { emailSendMarketing } from '../../services/operations/MarketingPageAPI';

const MarketingDashboard = () => {
  const { formState: { errors }, reset } = useForm();
  const [editorContent, setEditorContent] = useState('');
  const [subject, setSubject] = useState('');
  const [csvFileName, setCsvFileName] = useState('');
  const [csvFile, setCsvFile] = useState(null);
  const [isResetEnabled, setIsResetEnabled] = useState(false);
  const [isButtonEnabeld,setIsButtonEnabled]=useState(false)
  const token=useSelector((state)=>state.auth.token)
  const dispatch=useDispatch()

  const toolbarOptions = [
    [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image'],
    ['clean'],
  ];


  const handleReset = () => {
    reset(); 
    setEditorContent('');
    setCsvFileName('');
    setCsvFile(null);
    setSubject('');
    setIsButtonEnabled(false); 
  };
  
  const checkButtonState = (editorContent, csvFile, subject) => {
    if (editorContent.trim() || csvFile || subject.trim()) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  };
  
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    checkButtonState(editorContent, csvFile, e.target.value);
  };
  
  const handleChange = (content) => {
    setEditorContent(content);
    checkButtonState(content, csvFile, subject);
  };
  
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCsvFileName(file.name);
      setCsvFile(file);
      checkButtonState(editorContent, file, subject);
    }
  };
  
  const handleFileRemove = () => {
    setCsvFileName('');
    setCsvFile(null);
    checkButtonState(editorContent, null, subject);
  };
  

  const handleSendMessage = async() => {
    if (!csvFile) {
      toast.error('Please upload a CSV file!');
      return;
    }

    const formData=new FormData();

    formData.append("subject",subject)
    formData.append("content",editorContent)
    if(csvFile){

      formData.append("csv_file",csvFile)
    }
    await dispatch(emailSendMarketing(formData,token))
  };

  const handleSampleDownload=()=>{
    const fileUrl = "./valid_emails.csv";

  const link = document.createElement("a");
  link.href = fileUrl;
  link.target = "_blank"; 
  link.download ="sample-csv.csv"; 
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  }

  return (
    <div className="marketing-dashboard">
      <div className="marketing-dashbaord-heading">
        <h3>Enter the message you want to send</h3>
      
        <div className="email-box">
        <h5 className='sample-download' onClick={handleSampleDownload}><img src={download} alt='donwload'/>Download sample CSV</h5>
          <div className="box">
            <div className="marketing-subject-csv">
              <div className="marketing-form-group">
                <label htmlFor="sendTo" className="form-label" style={{ fontWeight: "700" }}>
                  Subject
                </label>
                <input
                  type="text"
                  id="sendTo"
                  placeholder="Enter subject"
                  onChange={handleSubjectChange}
                  value={subject}
                  style={{ width: "100%" }}
                  className={`form-input ${errors.sendTo ? 'input-error' : ''}`}
                />
              </div>
              <div style={{ width: "20%", marginTop: "25px" }}>
                <button
                  type="button"
                  onClick={() => document.getElementById('csv-upload').click()}
                  className="csv-btn"
                  disabled={!!csvFile} 
                >
                  <img src={upload} alt="upload" /> Upload CSV
                </button>
                <input
                  type="file"
                  id="csv-upload"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
              </div>
            </div>
            <div className="csv-detail">
              {csvFileName && (
                <div className="upload-csv">
                  <p>{csvFileName}</p>
                  <button
                    type="button"
                    onClick={handleFileRemove}
                    style={{
                      background: "none",
                      border: "none",
                      color: "#0002DC",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                  >
                    ✖
                  </button>
                </div>
              )}
            </div>
            <div className="text-desc">
              <h4>Enter the Message Below</h4>
              <ReactQuill
                value={editorContent}
                onChange={handleChange}
                modules={{ toolbar: toolbarOptions }}
                placeholder="Type Your message here"
                style={{ height: "214px", marginBottom: "40px", borderRadius: "4px" }}
              />
            </div>
            <div className="btn-marketing">
              <button
                className="reset-btn"
                onClick={handleReset}
                disabled={!isButtonEnabeld} 
              >
                Reset All
              </button>
              <button
                onClick={handleSendMessage}
                className="send-btn"
                disabled={!isButtonEnabeld} 
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingDashboard;

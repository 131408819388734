import { useDispatch, useSelector } from "react-redux";
import SideBar from "../common/SideBar";
import { recruiterPaymentDetail } from "../../../services/operations/AdminPageAPI";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaymentDetail from "./PaymentTable";

const RecruiterPaymentDetail = () => {
    const [data, setData] = useState(null);
    const [result, setResult] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: 12,
        total_pages: 1,
        total: 0,
    });

    const user = useSelector((state) => state.profile.user);
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encodedData = queryParams.get("data");

    useEffect(() => {
        if (encodedData) {
            try {
                const decodedData = JSON.parse(decodeURIComponent(atob(encodedData)));
                setData(decodedData);
            } catch (error) {
                console.error("Error decoding data:", error);
            }
        }
    }, [encodedData]);

    useEffect(() => {
        if (data?.name) {
            document.title = `${data.name} - Payment Details`;
        }
        return () => {
            document.title = 'AI Powered Candidate Search Engine - ResumeLake';
        };
    }, [data?.name]);

    useEffect(() => {
        if (data?.id) {
            getRecruiterPaymentDetail();
        }
    }, [data]);

    const getRecruiterPaymentDetail = async () => {
        if (!data?.id){
            return;
        }
        const response = await dispatch(recruiterPaymentDetail(data.id, token));
        if (response?.data?.data) {
            const detail = response.data.data;
            setResult(detail);
            const total_pages = Math.ceil(detail?.length / pagination.per_page);
            setPagination((prev) => ({ ...prev, total_pages, total: detail?.length }));
        }
    };

    useEffect(() => {
        const startIndex = (pagination.current_page - 1) * pagination.per_page;
        const endIndex = startIndex + pagination.per_page;
        setPaginatedData(result.slice(startIndex, endIndex));
    }, [result, pagination.current_page]);

    const handlePageChange = (newPage) => {
        setPagination((prev) => ({ ...prev, current_page: newPage }));
    };

    return (
        <div className="admin-dashboard-container">
            <SideBar user={user} />
            <div className="admin-dashboard-content" style={{ padding: "10px 25px 15px 25px" }}>
                <h4 style={{ margin: "10px 0px 10px 0px", fontWeight: "400", fontSize: "18px" }}>Name:<span style={{ fontWeight: "500" }}>{data?.name}</span></h4>

                <h4 style={{ margin: "10px 0px 10px 0px", fontWeight: "400", fontSize: "18px" }}>Last Payment Date:<span style={{ fontWeight: "500" }}>{data?.recentPayment}</span></h4>
                <PaymentDetail
                    data={paginatedData}
                    pagination={pagination}
                    onPageChange={handlePageChange}
                    getRecruiterPaymentDetail={getRecruiterPaymentDetail}
                />
            </div>
        </div>
    );
};

export default RecruiterPaymentDetail;

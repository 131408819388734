import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useDispatch, useSelector } from "react-redux";
import '../../../styles/globalStyle.css';
import downIcon from '../../../assets/Polygon 2.png';
import upIcon from '../../../assets/Polygon 3.png';
import threeDotsIcon from '../../../assets/dots-vertical.png';
import editIcon from '../../../assets/edit.png'
import deleteIcon from '../../../assets/delete.png'
import { deleteCompany, updateCompany } from '../../../services/operations/ResumesAPI';
import Loader from '../../loader/Loader';
import closeIcon from '../../../assets/Close.png'

const DataTable = ({ companies, pagination, loadData, onPageChange, fetchCompanies }) => {
    const { current_page, total_pages } = pagination;
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const dropdownRef = useRef(null);

    const token = useSelector((state) => state.auth.token);
    const loading = useSelector((state) => state.auth.loading)
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEdit = async () => {
        if (selectedCompany) {
            const payload = {
                new_name: selectedCompany.name,
                old_name: selectedCompany.old_name,
            };
            await dispatch(updateCompany(token, payload));
            fetchCompanies();
            setIsModalOpen(false);
            setDropdownOpen(null);
        }
    };

    const handleDelete = async (row) => {
        const payload = {
            name: row.original.name
        }
        await dispatch(deleteCompany(token, payload))
        fetchCompanies();
        setDropdownOpen(null);
    }

    const data = useMemo(
        () =>
            companies.map((company, index) => ({
                id: index,
                name: company,
                actions: null,
            })),
        [companies]
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Companies',
                accessor: 'name',
                disableSortBy: true,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({ row }) => (
                    <div className="actions">
                        <img
                            src={threeDotsIcon}
                            alt="Actions"
                            className="three-dots-icon"
                            onClick={(e) => {
                                e.stopPropagation();
                                setDropdownOpen(dropdownOpen === row.id ? null : row.id);
                            }}
                        />
                        {dropdownOpen === row.id && (
                            <div className="dropdown-menu-status" ref={dropdownRef}>
                                <div className="dropdown-item-status"
                                    onClick={() => {
                                        setSelectedCompany({
                                            name: row.original.name,
                                            old_name: row.original.name,
                                        });
                                        setIsModalOpen(true);
                                    }}
                                >
                                    <img src={editIcon} alt='edit' width={20} height={20} />Edit Company
                                </div>
                                <div className="dropdown-item-status"
                                    onClick={() => handleDelete(row)}>
                                    <img src={deleteIcon} alt='delete' width={20} height={20} />Remove Company
                                </div>
                            </div>
                        )}
                    </div>
                ),
                disableSortBy: true,
            },
        ],
        [dropdownOpen]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: pagination.current_page - 1 },
            manualPagination: true,
            pageCount: pagination.total_pages,
        },
        useSortBy,
        usePagination
    );

    const renderPagination = () => {
        const { current_page, total_pages } = pagination;
        const pageNumbers = [];

        if (total_pages <= 5) {
            for (let i = 1; i <= total_pages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`page-btn ${i === current_page ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    className={`page-btn ${1 === current_page ? 'active' : ''}`}
                    onClick={() => onPageChange(1)}
                >
                    1
                </button>
            );

            if (current_page > 3) {
                pageNumbers.push(<span key="dots1">...</span>);
            }

            const startPage = Math.max(2, current_page - 1);
            const endPage = Math.min(total_pages - 1, current_page + 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`page-btn ${i === current_page ? 'active' : ''}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                );
            }

            if (current_page < total_pages - 2) {
                pageNumbers.push(<span key="dots2">...</span>);
            }

            pageNumbers.push(
                <button
                    key={total_pages}
                    className={`page-btn ${total_pages === current_page ? 'active' : ''}`}
                    onClick={() => onPageChange(total_pages)}
                >
                    {total_pages}
                </button>
            );
        }

        return (
            <div className='pagination-container'>
                <button
                    className={`page-btn ${current_page === 1 ? 'disabled' : ''}`}
                    onClick={() => onPageChange(current_page - 1)}
                    disabled={current_page === 1}
                >
                    Prev
                </button>

                {pageNumbers}

                <button
                    className={`page-btn ${current_page === total_pages ? 'disabled' : ''}`}
                    onClick={() => onPageChange(current_page + 1)}
                    disabled={current_page === total_pages}
                >
                    Next
                </button>
            </div>
        );
    };

    return (
        <>
            {renderPagination()}

            <table {...getTableProps()} className='data-table'>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    <div className="header-content">
                                        {column.render('Header')}
                                        {!column.disableSortBy && (
                                            <div className="sort-icons">
                                                <img
                                                    src={downIcon}
                                                    alt="Sort Ascending"
                                                    className="sort-icon"
                                                    onClick={() => column.toggleSortBy(false)}
                                                />
                                                <img
                                                    src={upIcon}
                                                    alt="Sort Descending"
                                                    className="sort-icon"
                                                    onClick={() => column.toggleSortBy(true)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {loadData ? (
                        [...Array(15)].map((_, index) => (
                            <tr key={index}>
                                <td colSpan={columns.length} style={{ padding: '20px' }}>
                                    <div className="skeleton-row"></div>
                                </td>
                            </tr>
                        ))
                    ) : companies?.length === 0 ? (
                        <tr>
                            <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}>
                                No data to display
                            </td>
                        </tr>
                    ) : (
                        page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} style={{ cursor: 'pointer' }}>
                                    {row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    ))}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>

            {renderPagination()}

            {isModalOpen && (
                <div className="modal-overlay">
                    {loading && <Loader />}
                    <div className="modal-content" style={{ "width": "350px" }}>
                        <div className='upload-heading'>
                            <h6>Edit Company</h6>
                            <img src={closeIcon} alt="Close" className="close-icon" onClick={() => setIsModalOpen(false)} />
                        </div>
                        <div className="form-type">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                value={selectedCompany?.name}
                                className="form-input-account"
                                onChange={(e) =>
                                    setSelectedCompany((prev) => ({ ...prev, name: e.target.value }))
                                }
                            />
                        </div>
                        <div className="modal-actions">

                            {/* <button style={{"backgroundColor":"Grey"}} onClick={() => setIsModalOpen(false)}>Cancel</button> */}
                            <button onClick={handleEdit}>Save</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DataTable;
